export const wilaya = [
  { id: "1", code: "1", nom: "Adrar" },
  { id: "2", code: "2", nom: "Chlef" },
  { id: "3", code: "3", nom: "Laghouat" },
  { id: "4", code: "4", nom: "Oum El Bouaghi" },
  { id: "5", code: "5", nom: "Batna" },
  { id: "6", code: "6", nom: "Bejaia" },
  { id: "7", code: "7", nom: "Biskra" },
  { id: "8", code: "8", nom: "Bechar" },
  { id: "9", code: "9", nom: "Blida" },
  { id: "10", code: "10", nom: "Bouira" },
  { id: "11", code: "11", nom: "Tamanrasset" },
  { id: "12", code: "12", nom: "Tebessa" },
  { id: "13", code: "13", nom: "Tlemcen" },
  { id: "14", code: "14", nom: "Tiaret" },
  { id: "15", code: "15", nom: "Tizi Ouzou" },
  { id: "16", code: "16", nom: "Alger" },
  { id: "17", code: "17", nom: "Djelfa" },
  { id: "18", code: "18", nom: "Jijel" },
  { id: "19", code: "19", nom: "Setif" },
  { id: "20", code: "20", nom: "Saida" },
  { id: "21", code: "21", nom: "Skikda" },
  { id: "22", code: "22", nom: "Sidi Bel Abbes" },
  { id: "23", code: "23", nom: "Annaba" },
  { id: "24", code: "24", nom: "Guelma" },
  { id: "25", code: "25", nom: "Constantine" },
  { id: "26", code: "26", nom: "Medea" },
  { id: "27", code: "27", nom: "Mostaganem" },
  { id: "28", code: "28", nom: "M'Sila" },
  { id: "29", code: "29", nom: "Mascara" },
  { id: "30", code: "30", nom: "Ouargla" },
  { id: "31", code: "31", nom: "Oran" },
  { id: "32", code: "32", nom: "El Bayadh" },
  { id: "33", code: "33", nom: "Illizi" },
  { id: "34", code: "34", nom: "Bordj Bou Arreridj" },
  { id: "35", code: "35", nom: "Boumerdes" },
  { id: "36", code: "36", nom: "El Tarf" },
  { id: "37", code: "37", nom: "Tindouf" },
  { id: "38", code: "38", nom: "Tissemsilt" },
  { id: "39", code: "39", nom: "El Oued" },
  { id: "40", code: "40", nom: "Khenchela" },
  { id: "41", code: "41", nom: "Souk Ahras" },
  { id: "42", code: "42", nom: "Tipaza" },
  { id: "43", code: "43", nom: "Mila" },
  { id: "44", code: "44", nom: "Ain Defla" },
  { id: "45", code: "45", nom: "Naama" },
  { id: "46", code: "46", nom: "Ain Temouchent" },
  { id: "47", code: "47", nom: "Ghardaia" },
  { id: "48", code: "48", nom: "Relizane" },
];

export const commun = [
  { nom: "Adrar", wilaya: "Adrar", wilaya_id: "1", id: "0" },
  { nom: "Akabli", wilaya: "Adrar", wilaya_id: "1", id: "1" },
  { nom: "Aougrout", wilaya: "Adrar", wilaya_id: "1", id: "2" },
  { nom: "Aoulef", wilaya: "Adrar", wilaya_id: "1", id: "3" },
  { nom: "Bordj Badji Mokhtar", wilaya: "Adrar", wilaya_id: "1", id: "4" },
  { nom: "Bouda", wilaya: "Adrar", wilaya_id: "1", id: "5" },
  { nom: "Charouine", wilaya: "Adrar", wilaya_id: "1", id: "6" },
  { nom: "Deldoul", wilaya: "Adrar", wilaya_id: "1", id: "7" },
  { nom: "Fenoughil", wilaya: "Adrar", wilaya_id: "1", id: "8" },
  { nom: "In Zeghmir", wilaya: "Adrar", wilaya_id: "1", id: "9" },
  { nom: "Ksar Kaddour", wilaya: "Adrar", wilaya_id: "1", id: "10" },
  { nom: "Metarfa", wilaya: "Adrar", wilaya_id: "1", id: "11" },
  { nom: "Ouled Ahmed Timmi", wilaya: "Adrar", wilaya_id: "1", id: "12" },
  { nom: "Ouled Aïssa", wilaya: "Adrar", wilaya_id: "1", id: "13" },
  { nom: "Ouled Saïd", wilaya: "Adrar", wilaya_id: "1", id: "14" },
  { nom: "Reggane", wilaya: "Adrar", wilaya_id: "1", id: "15" },
  { nom: "Sali", wilaya: "Adrar", wilaya_id: "1", id: "16" },
  { nom: "Sbaa", wilaya: "Adrar", wilaya_id: "1", id: "17" },
  { nom: "Talmine", wilaya: "Adrar", wilaya_id: "1", id: "18" },
  { nom: "Tamantit", wilaya: "Adrar", wilaya_id: "1", id: "19" },
  { nom: "Tamest", wilaya: "Adrar", wilaya_id: "1", id: "20" },
  { nom: "Timekten", wilaya: "Adrar", wilaya_id: "1", id: "21" },
  { nom: "Timiaouine", wilaya: "Adrar", wilaya_id: "1", id: "22" },
  { nom: "Timimoun", wilaya: "Adrar", wilaya_id: "1", id: "23" },
  { nom: "Tinerkouk", wilaya: "Adrar", wilaya_id: "1", id: "24" },
  { nom: "Tit", wilaya: "Adrar", wilaya_id: "1", id: "25" },
  { nom: "Tsabit", wilaya: "Adrar", wilaya_id: "1", id: "26" },
  { nom: "Zaouiat Kounta", wilaya: "Adrar", wilaya_id: "1", id: "27" },
  { nom: "Ain Bouyahia", wilaya: "Ain Defla", wilaya_id: "44", id: "28" },
  { nom: "Arib", wilaya: "Ain Defla", wilaya_id: "44", id: "29" },
  { nom: "Aïn Benian", wilaya: "Ain Defla", wilaya_id: "44", id: "30" },
  { nom: "Aïn Defla", wilaya: "Ain Defla", wilaya_id: "44", id: "31" },
  { nom: "Aïn Lechiakh", wilaya: "Ain Defla", wilaya_id: "44", id: "32" },
  { nom: "Aïn Soltane", wilaya: "Ain Defla", wilaya_id: "44", id: "33" },
  { nom: "Aïn Torki", wilaya: "Ain Defla", wilaya_id: "44", id: "34" },
  { nom: "Barbouche", wilaya: "Ain Defla", wilaya_id: "44", id: "35" },
  { nom: "Bathia", wilaya: "Ain Defla", wilaya_id: "44", id: "36" },
  { nom: "Belaas", wilaya: "Ain Defla", wilaya_id: "44", id: "37" },
  { nom: "Ben Allal", wilaya: "Ain Defla", wilaya_id: "44", id: "38" },
  { nom: "Bir Ould Khlifa", wilaya: "Ain Defla", wilaya_id: "44", id: "39" },
  {
    nom: "Bordj El Emir Khaled",
    wilaya: "Ain Defla",
    wilaya_id: "44",
    id: "40",
  },
  { nom: "Boumedfaa", wilaya: "Ain Defla", wilaya_id: "44", id: "41" },
  { nom: "Bourached", wilaya: "Ain Defla", wilaya_id: "44", id: "42" },
  { nom: "Djelida", wilaya: "Ain Defla", wilaya_id: "44", id: "43" },
  { nom: "Djemaa Ouled Chikh", wilaya: "Ain Defla", wilaya_id: "44", id: "44" },
  { nom: "Djendel", wilaya: "Ain Defla", wilaya_id: "44", id: "45" },
  { nom: "El Abadia", wilaya: "Ain Defla", wilaya_id: "44", id: "46" },
  { nom: "El Amra", wilaya: "Ain Defla", wilaya_id: "44", id: "47" },
  { nom: "El Attaf", wilaya: "Ain Defla", wilaya_id: "44", id: "48" },
  { nom: "El Hassania", wilaya: "Ain Defla", wilaya_id: "44", id: "49" },
  { nom: "El Hoceinia", wilaya: "Ain Defla", wilaya_id: "44", id: "50" },
  { nom: "El Maine", wilaya: "Ain Defla", wilaya_id: "44", id: "51" },
  { nom: "Hammam Righa", wilaya: "Ain Defla", wilaya_id: "44", id: "52" },
  { nom: "Khemis", wilaya: "Ain Defla", wilaya_id: "44", id: "53" },
  { nom: "Mekhatria", wilaya: "Ain Defla", wilaya_id: "44", id: "54" },
  { nom: "Miliana", wilaya: "Ain Defla", wilaya_id: "44", id: "55" },
  { nom: "Oued Chorfa", wilaya: "Ain Defla", wilaya_id: "44", id: "56" },
  { nom: "Oued Djemaa", wilaya: "Ain Defla", wilaya_id: "44", id: "57" },
  { nom: "Rouina", wilaya: "Ain Defla", wilaya_id: "44", id: "58" },
  { nom: "Sidi Lakhdar", wilaya: "Ain Defla", wilaya_id: "44", id: "59" },
  { nom: "Tachta Zouggagha", wilaya: "Ain Defla", wilaya_id: "44", id: "60" },
  { nom: "Tarik Ibn Ziad", wilaya: "Ain Defla", wilaya_id: "44", id: "61" },
  { nom: "Tiberkanine", wilaya: "Ain Defla", wilaya_id: "44", id: "62" },
  { nom: "Zeddine", wilaya: "Ain Defla", wilaya_id: "44", id: "63" },
  { nom: "Aghlel", wilaya: "Ain Temouchent", wilaya_id: "46", id: "64" },
  { nom: "Aoubelil", wilaya: "Ain Temouchent", wilaya_id: "46", id: "65" },
  { nom: "Aïn Kihel", wilaya: "Ain Temouchent", wilaya_id: "46", id: "66" },
  { nom: "Aïn Larbaa", wilaya: "Ain Temouchent", wilaya_id: "46", id: "67" },
  { nom: "Aïn Tolba", wilaya: "Ain Temouchent", wilaya_id: "46", id: "68" },
  {
    nom: "Aïn Témouchent",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "69",
  },
  { nom: "Beni Saf", wilaya: "Ain Temouchent", wilaya_id: "46", id: "70" },
  { nom: "Bouzedjar", wilaya: "Ain Temouchent", wilaya_id: "46", id: "71" },
  {
    nom: "Chaabat El Ham",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "72",
  },
  { nom: "Chentouf", wilaya: "Ain Temouchent", wilaya_id: "46", id: "73" },
  { nom: "El Amria", wilaya: "Ain Temouchent", wilaya_id: "46", id: "74" },
  { nom: "El Hassasna", wilaya: "Ain Temouchent", wilaya_id: "46", id: "75" },
  { nom: "El Malah", wilaya: "Ain Temouchent", wilaya_id: "46", id: "76" },
  {
    nom: "Emir Abdelkader",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "77",
  },
  {
    nom: "Hammam Bouhadjar",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "78",
  },
  {
    nom: "Hassi El Ghella",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "79",
  },
  { nom: "Oued Barkach", wilaya: "Ain Temouchent", wilaya_id: "46", id: "80" },
  { nom: "Oued Sebah", wilaya: "Ain Temouchent", wilaya_id: "46", id: "81" },
  {
    nom: "Ouled Boudjemaa",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "82",
  },
  { nom: "Ouled Kihel", wilaya: "Ain Temouchent", wilaya_id: "46", id: "83" },
  {
    nom: "Oulhassa Gheraba",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "84",
  },
  { nom: "Sidi Ben Adda", wilaya: "Ain Temouchent", wilaya_id: "46", id: "85" },
  {
    nom: "Sidi Boumediène",
    wilaya: "Ain Temouchent",
    wilaya_id: "46",
    id: "86",
  },
  { nom: "Sidi Ouriach", wilaya: "Ain Temouchent", wilaya_id: "46", id: "87" },
  { nom: "Sidi Safi", wilaya: "Ain Temouchent", wilaya_id: "46", id: "88" },
  { nom: "Tamzoura", wilaya: "Ain Temouchent", wilaya_id: "46", id: "89" },
  { nom: "Terga", wilaya: "Ain Temouchent", wilaya_id: "46", id: "90" },
  { nom: "Alger Centre", wilaya: "Alger", wilaya_id: "16", id: "91" },
  { nom: "Aïn Bénian", wilaya: "Alger", wilaya_id: "16", id: "92" },
  { nom: "Aïn Taya", wilaya: "Alger", wilaya_id: "16", id: "93" },
  { nom: "Bab El Oued", wilaya: "Alger", wilaya_id: "16", id: "94" },
  { nom: "Bab Ezzouar", wilaya: "Alger", wilaya_id: "16", id: "95" },
  { nom: "Baba Hacène", wilaya: "Alger", wilaya_id: "16", id: "96" },
  { nom: "Bach Djerah", wilaya: "Alger", wilaya_id: "16", id: "97" },
  { nom: "Baraki", wilaya: "Alger", wilaya_id: "16", id: "98" },
  { nom: "Ben Aknoun", wilaya: "Alger", wilaya_id: "16", id: "99" },
  { nom: "Beni Messous", wilaya: "Alger", wilaya_id: "16", id: "100" },
  { nom: "Bir Mourad Raïs", wilaya: "Alger", wilaya_id: "16", id: "101" },
  { nom: "Birkhadem", wilaya: "Alger", wilaya_id: "16", id: "102" },
  { nom: "Birtouta", wilaya: "Alger", wilaya_id: "16", id: "103" },
  { nom: "Bologhine", wilaya: "Alger", wilaya_id: "16", id: "104" },
  { nom: "Bordj El Bahri", wilaya: "Alger", wilaya_id: "16", id: "105" },
  { nom: "Bordj El Kiffan", wilaya: "Alger", wilaya_id: "16", id: "106" },
  { nom: "Bourouba", wilaya: "Alger", wilaya_id: "16", id: "107" },
  { nom: "Bouzaréah", wilaya: "Alger", wilaya_id: "16", id: "108" },
  { nom: "Casbah", wilaya: "Alger", wilaya_id: "16", id: "109" },
  { nom: "Chéraga", wilaya: "Alger", wilaya_id: "16", id: "110" },
  { nom: "Dar El Beïda", wilaya: "Alger", wilaya_id: "16", id: "111" },
  { nom: "Djasr  Kaçentina", wilaya: "Alger", wilaya_id: "16", id: "112" },
  { nom: "Douera", wilaya: "Alger", wilaya_id: "16", id: "113" },
  { nom: "Draria", wilaya: "Alger", wilaya_id: "16", id: "114" },
  { nom: "Dèly Brahim", wilaya: "Alger", wilaya_id: "16", id: "115" },
  { nom: "El Achour", wilaya: "Alger", wilaya_id: "16", id: "116" },
  { nom: "El Biar", wilaya: "Alger", wilaya_id: "16", id: "117" },
  { nom: "El Harrach", wilaya: "Alger", wilaya_id: "16", id: "118" },
  { nom: "El Madania", wilaya: "Alger", wilaya_id: "16", id: "119" },
  { nom: "El Magharia", wilaya: "Alger", wilaya_id: "16", id: "120" },
  { nom: "El Marsa", wilaya: "Alger", wilaya_id: "16", id: "121" },
  { nom: "El Mouradia", wilaya: "Alger", wilaya_id: "16", id: "122" },
  { nom: "Eucalyptus", wilaya: "Alger", wilaya_id: "16", id: "123" },
  { nom: "Hammamet", wilaya: "Alger", wilaya_id: "16", id: "124" },
  { nom: "Heraoua", wilaya: "Alger", wilaya_id: "16", id: "125" },
  { nom: "Hussein Dey", wilaya: "Alger", wilaya_id: "16", id: "126" },
  { nom: "Hydra", wilaya: "Alger", wilaya_id: "16", id: "127" },
  { nom: "Khraïssia", wilaya: "Alger", wilaya_id: "16", id: "128" },
  { nom: "Kouba", wilaya: "Alger", wilaya_id: "16", id: "129" },
  { nom: "Mahelma", wilaya: "Alger", wilaya_id: "16", id: "130" },
  { nom: "Mohamed Belouizdad", wilaya: "Alger", wilaya_id: "16", id: "131" },
  { nom: "Mohammadia", wilaya: "Alger", wilaya_id: "16", id: "132" },
  { nom: "Oued Koriche", wilaya: "Alger", wilaya_id: "16", id: "133" },
  { nom: "Oued Smar", wilaya: "Alger", wilaya_id: "16", id: "134" },
  { nom: "Ouled Chebel", wilaya: "Alger", wilaya_id: "16", id: "135" },
  { nom: "Ouled Fayet", wilaya: "Alger", wilaya_id: "16", id: "136" },
  { nom: "Rahmania", wilaya: "Alger", wilaya_id: "16", id: "137" },
  { nom: "Raïs Hamidou", wilaya: "Alger", wilaya_id: "16", id: "138" },
  { nom: "Rouiba", wilaya: "Alger", wilaya_id: "16", id: "139" },
  { nom: "Réghaïa", wilaya: "Alger", wilaya_id: "16", id: "140" },
  { nom: "Saoula", wilaya: "Alger", wilaya_id: "16", id: "141" },
  { nom: "Sidi Moussa", wilaya: "Alger", wilaya_id: "16", id: "142" },
  { nom: "Souidania", wilaya: "Alger", wilaya_id: "16", id: "143" },
  { nom: "Staouéli", wilaya: "Alger", wilaya_id: "16", id: "144" },
  { nom: "Tessala El Merdja", wilaya: "Alger", wilaya_id: "16", id: "145" },
  { nom: "Zeralda", wilaya: "Alger", wilaya_id: "16", id: "146" },
  { nom: "Annaba", wilaya: "Annaba", wilaya_id: "23", id: "147" },
  { nom: "Aïn El  Berda", wilaya: "Annaba", wilaya_id: "23", id: "148" },
  { nom: "Berrahal", wilaya: "Annaba", wilaya_id: "23", id: "149" },
  { nom: "Chetaïbi", wilaya: "Annaba", wilaya_id: "23", id: "150" },
  { nom: "Chorfa", wilaya: "Annaba", wilaya_id: "23", id: "151" },
  { nom: "El Bouni", wilaya: "Annaba", wilaya_id: "23", id: "152" },
  { nom: "El Eulma", wilaya: "Annaba", wilaya_id: "23", id: "153" },
  { nom: "El Hadjar", wilaya: "Annaba", wilaya_id: "23", id: "154" },
  { nom: "Oued El Aneb", wilaya: "Annaba", wilaya_id: "23", id: "155" },
  { nom: "Seraïdi", wilaya: "Annaba", wilaya_id: "23", id: "156" },
  { nom: "Sidi Amar", wilaya: "Annaba", wilaya_id: "23", id: "157" },
  { nom: "Treat", wilaya: "Annaba", wilaya_id: "23", id: "158" },
  { nom: "Abdelkader Azil", wilaya: "Batna", wilaya_id: "5", id: "159" },
  { nom: "Arris", wilaya: "Batna", wilaya_id: "5", id: "160" },
  { nom: "Aïn Djasser", wilaya: "Batna", wilaya_id: "5", id: "161" },
  { nom: "Aïn Touta", wilaya: "Batna", wilaya_id: "5", id: "162" },
  { nom: "Aïn Yagout", wilaya: "Batna", wilaya_id: "5", id: "163" },
  { nom: "Barika", wilaya: "Batna", wilaya_id: "5", id: "164" },
  { nom: "Batna", wilaya: "Batna", wilaya_id: "5", id: "165" },
  { nom: "Bitam", wilaya: "Batna", wilaya_id: "5", id: "166" },
  { nom: "Boulhilet", wilaya: "Batna", wilaya_id: "5", id: "167" },
  { nom: "Boumagueur", wilaya: "Batna", wilaya_id: "5", id: "168" },
  { nom: "Boumia", wilaya: "Batna", wilaya_id: "5", id: "169" },
  { nom: "Bouzina", wilaya: "Batna", wilaya_id: "5", id: "170" },
  {
    nom: "Béni  Foudala El Hakania",
    wilaya: "Batna",
    wilaya_id: "5",
    id: "171",
  },
  { nom: "Chemora", wilaya: "Batna", wilaya_id: "5", id: "172" },
  { nom: "Chir", wilaya: "Batna", wilaya_id: "5", id: "173" },
  { nom: "Djerma", wilaya: "Batna", wilaya_id: "5", id: "174" },
  { nom: "Djezzar", wilaya: "Batna", wilaya_id: "5", id: "175" },
  { nom: "El Hassi", wilaya: "Batna", wilaya_id: "5", id: "176" },
  { nom: "El Madher", wilaya: "Batna", wilaya_id: "5", id: "177" },
  { nom: "Fesdis", wilaya: "Batna", wilaya_id: "5", id: "178" },
  { nom: "Foum Toub", wilaya: "Batna", wilaya_id: "5", id: "179" },
  { nom: "Ghassira", wilaya: "Batna", wilaya_id: "5", id: "180" },
  { nom: "Gosbat", wilaya: "Batna", wilaya_id: "5", id: "181" },
  { nom: "Guigba", wilaya: "Batna", wilaya_id: "5", id: "182" },
  { nom: "Hidoussa", wilaya: "Batna", wilaya_id: "5", id: "183" },
  { nom: "Ichmoul", wilaya: "Batna", wilaya_id: "5", id: "184" },
  { nom: "Inoughissen", wilaya: "Batna", wilaya_id: "5", id: "185" },
  { nom: "Kimel", wilaya: "Batna", wilaya_id: "5", id: "186" },
  { nom: "Ksar Belezma", wilaya: "Batna", wilaya_id: "5", id: "187" },
  { nom: "Larbaa", wilaya: "Batna", wilaya_id: "5", id: "188" },
  { nom: "Lazrou", wilaya: "Batna", wilaya_id: "5", id: "189" },
  { nom: "Lemsane", wilaya: "Batna", wilaya_id: "5", id: "190" },
  { nom: "Maafa", wilaya: "Batna", wilaya_id: "5", id: "191" },
  { nom: "Menaa", wilaya: "Batna", wilaya_id: "5", id: "192" },
  { nom: "Merouana", wilaya: "Batna", wilaya_id: "5", id: "193" },
  { nom: "Oued Chaaba", wilaya: "Batna", wilaya_id: "5", id: "194" },
  { nom: "Oued El Ma", wilaya: "Batna", wilaya_id: "5", id: "195" },
  { nom: "Oued Taga", wilaya: "Batna", wilaya_id: "5", id: "196" },
  { nom: "Ouled Ammar", wilaya: "Batna", wilaya_id: "5", id: "197" },
  { nom: "Ouled Aouf", wilaya: "Batna", wilaya_id: "5", id: "198" },
  { nom: "Ouled Fadhel", wilaya: "Batna", wilaya_id: "5", id: "199" },
  { nom: "Ouled Sellem", wilaya: "Batna", wilaya_id: "5", id: "200" },
  { nom: "Ouled Si Slimane", wilaya: "Batna", wilaya_id: "5", id: "201" },
  { nom: "Ouyoun El Assafir", wilaya: "Batna", wilaya_id: "5", id: "202" },
  { nom: "Rahbat", wilaya: "Batna", wilaya_id: "5", id: "203" },
  { nom: "Ras El Aioun", wilaya: "Batna", wilaya_id: "5", id: "204" },
  { nom: "Sefiane", wilaya: "Batna", wilaya_id: "5", id: "205" },
  { nom: "Seggana", wilaya: "Batna", wilaya_id: "5", id: "206" },
  { nom: "Seriana", wilaya: "Batna", wilaya_id: "5", id: "207" },
  { nom: "Talkhamt", wilaya: "Batna", wilaya_id: "5", id: "208" },
  { nom: "Taxlent", wilaya: "Batna", wilaya_id: "5", id: "209" },
  { nom: "Tazoult", wilaya: "Batna", wilaya_id: "5", id: "210" },
  { nom: "Theniet El Abed", wilaya: "Batna", wilaya_id: "5", id: "211" },
  { nom: "Tighanimine", wilaya: "Batna", wilaya_id: "5", id: "212" },
  { nom: "Tigherghar", wilaya: "Batna", wilaya_id: "5", id: "213" },
  { nom: "Tilatou", wilaya: "Batna", wilaya_id: "5", id: "214" },
  { nom: "Timgad", wilaya: "Batna", wilaya_id: "5", id: "215" },
  { nom: "Zana El Beida", wilaya: "Batna", wilaya_id: "5", id: "216" },
  { nom: "Abadla", wilaya: "Bechar", wilaya_id: "8", id: "217" },
  { nom: "Arg Ferradj", wilaya: "Bechar", wilaya_id: "8", id: "218" },
  { nom: "Bechar", wilaya: "Bechar", wilaya_id: "8", id: "219" },
  { nom: "Beni Ikhlef", wilaya: "Bechar", wilaya_id: "8", id: "220" },
  { nom: "Beni Ounif", wilaya: "Bechar", wilaya_id: "8", id: "221" },
  { nom: "Boukais", wilaya: "Bechar", wilaya_id: "8", id: "222" },
  { nom: "Béni Abbes", wilaya: "Bechar", wilaya_id: "8", id: "223" },
  { nom: "El Ouata", wilaya: "Bechar", wilaya_id: "8", id: "224" },
  { nom: "Igli", wilaya: "Bechar", wilaya_id: "8", id: "225" },
  { nom: "Kenadsa", wilaya: "Bechar", wilaya_id: "8", id: "226" },
  { nom: "Kerzaz", wilaya: "Bechar", wilaya_id: "8", id: "227" },
  { nom: "Ksabi", wilaya: "Bechar", wilaya_id: "8", id: "228" },
  { nom: "Lahmar", wilaya: "Bechar", wilaya_id: "8", id: "229" },
  {
    nom: "Mechra Houari Boumédiene",
    wilaya: "Bechar",
    wilaya_id: "8",
    id: "230",
  },
  { nom: "Meridja", wilaya: "Bechar", wilaya_id: "8", id: "231" },
  { nom: "Mougheul", wilaya: "Bechar", wilaya_id: "8", id: "232" },
  { nom: "Ouled Khodeir", wilaya: "Bechar", wilaya_id: "8", id: "233" },
  { nom: "Tabelbala", wilaya: "Bechar", wilaya_id: "8", id: "234" },
  { nom: "Taghit", wilaya: "Bechar", wilaya_id: "8", id: "235" },
  { nom: "Tamtert", wilaya: "Bechar", wilaya_id: "8", id: "236" },
  { nom: "Timoudi", wilaya: "Bechar", wilaya_id: "8", id: "237" },
  { nom: "Adekar", wilaya: "Bejaia", wilaya_id: "6", id: "238" },
  { nom: "Aitrizine", wilaya: "Bejaia", wilaya_id: "6", id: "239" },
  { nom: "Akbou", wilaya: "Bejaia", wilaya_id: "6", id: "240" },
  { nom: "Akfadou", wilaya: "Bejaia", wilaya_id: "6", id: "241" },
  { nom: "Amalou", wilaya: "Bejaia", wilaya_id: "6", id: "242" },
  { nom: "Amizour", wilaya: "Bejaia", wilaya_id: "6", id: "243" },
  { nom: "Aokas", wilaya: "Bejaia", wilaya_id: "6", id: "244" },
  { nom: "Aït Smail", wilaya: "Bejaia", wilaya_id: "6", id: "245" },
  { nom: "Barbacha", wilaya: "Bejaia", wilaya_id: "6", id: "246" },
  { nom: "Beni Maouche", wilaya: "Bejaia", wilaya_id: "6", id: "247" },
  { nom: "Boudjellil", wilaya: "Bejaia", wilaya_id: "6", id: "248" },
  { nom: "Bouhamza", wilaya: "Bejaia", wilaya_id: "6", id: "249" },
  { nom: "Boukhelifa", wilaya: "Bejaia", wilaya_id: "6", id: "250" },
  { nom: "Béjaia", wilaya: "Bejaia", wilaya_id: "6", id: "251" },
  { nom: "Béni Djellil", wilaya: "Bejaia", wilaya_id: "6", id: "252" },
  { nom: "Béni Ksila", wilaya: "Bejaia", wilaya_id: "6", id: "253" },
  { nom: "Béni Melikeche", wilaya: "Bejaia", wilaya_id: "6", id: "254" },
  { nom: "Chellata", wilaya: "Bejaia", wilaya_id: "6", id: "255" },
  { nom: "Chemini", wilaya: "Bejaia", wilaya_id: "6", id: "256" },
  { nom: "Darguina", wilaya: "Bejaia", wilaya_id: "6", id: "257" },
  { nom: "Draa El Kaïd", wilaya: "Bejaia", wilaya_id: "6", id: "258" },
  { nom: "El Kseur", wilaya: "Bejaia", wilaya_id: "6", id: "259" },
  { nom: "Ferraoun", wilaya: "Bejaia", wilaya_id: "6", id: "260" },
  { nom: "Iflaine El Maten", wilaya: "Bejaia", wilaya_id: "6", id: "261" },
  { nom: "Ighil Ali", wilaya: "Bejaia", wilaya_id: "6", id: "262" },
  { nom: "Ighram", wilaya: "Bejaia", wilaya_id: "6", id: "263" },
  { nom: "Kendira", wilaya: "Bejaia", wilaya_id: "6", id: "264" },
  { nom: "Kherrata", wilaya: "Bejaia", wilaya_id: "6", id: "265" },
  { nom: "Leflaye", wilaya: "Bejaia", wilaya_id: "6", id: "266" },
  { nom: "Melbou", wilaya: "Bejaia", wilaya_id: "6", id: "267" },
  { nom: "Oued Ghir", wilaya: "Bejaia", wilaya_id: "6", id: "268" },
  { nom: "Ouzellaguène", wilaya: "Bejaia", wilaya_id: "6", id: "269" },
  { nom: "Seddouk", wilaya: "Bejaia", wilaya_id: "6", id: "270" },
  { nom: "Semaoune", wilaya: "Bejaia", wilaya_id: "6", id: "271" },
  { nom: "Sidi Ayad", wilaya: "Bejaia", wilaya_id: "6", id: "272" },
  { nom: "Sidi Aïch", wilaya: "Bejaia", wilaya_id: "6", id: "273" },
  { nom: "Souk El Tenine", wilaya: "Bejaia", wilaya_id: "6", id: "274" },
  { nom: "Souk Oufella", wilaya: "Bejaia", wilaya_id: "6", id: "275" },
  { nom: "Tala Hamza", wilaya: "Bejaia", wilaya_id: "6", id: "276" },
  { nom: "Tamokra", wilaya: "Bejaia", wilaya_id: "6", id: "277" },
  { nom: "Tamridjet", wilaya: "Bejaia", wilaya_id: "6", id: "278" },
  { nom: "Taourirt Ighil", wilaya: "Bejaia", wilaya_id: "6", id: "279" },
  { nom: "Taskriout", wilaya: "Bejaia", wilaya_id: "6", id: "280" },
  { nom: "Tazmalt", wilaya: "Bejaia", wilaya_id: "6", id: "281" },
  { nom: "Thinabdher", wilaya: "Bejaia", wilaya_id: "6", id: "282" },
  { nom: "Tibane", wilaya: "Bejaia", wilaya_id: "6", id: "283" },
  { nom: "Tichy", wilaya: "Bejaia", wilaya_id: "6", id: "284" },
  { nom: "Tifra", wilaya: "Bejaia", wilaya_id: "6", id: "285" },
  { nom: "Timzrit", wilaya: "Bejaia", wilaya_id: "6", id: "286" },
  { nom: "Toudja", wilaya: "Bejaia", wilaya_id: "6", id: "287" },
  { nom: "Aïn Naga", wilaya: "Biskra", wilaya_id: "7", id: "288" },
  { nom: "Aïn Zaatout", wilaya: "Biskra", wilaya_id: "7", id: "289" },
  { nom: "Besbes", wilaya: "Biskra", wilaya_id: "7", id: "290" },
  { nom: "Biskra", wilaya: "Biskra", wilaya_id: "7", id: "291" },
  { nom: "Bordj Benazzouz", wilaya: "Biskra", wilaya_id: "7", id: "292" },
  { nom: "Bouchagroun", wilaya: "Biskra", wilaya_id: "7", id: "293" },
  { nom: "Branis", wilaya: "Biskra", wilaya_id: "7", id: "294" },
  { nom: "Chaïba", wilaya: "Biskra", wilaya_id: "7", id: "295" },
  { nom: "Chetma", wilaya: "Biskra", wilaya_id: "7", id: "296" },
  { nom: "Djemourah", wilaya: "Biskra", wilaya_id: "7", id: "297" },
  { nom: "Doucen", wilaya: "Biskra", wilaya_id: "7", id: "298" },
  { nom: "El Feidh", wilaya: "Biskra", wilaya_id: "7", id: "299" },
  { nom: "El Ghrous", wilaya: "Biskra", wilaya_id: "7", id: "300" },
  { nom: "El Hadjeb", wilaya: "Biskra", wilaya_id: "7", id: "301" },
  { nom: "El Haouch", wilaya: "Biskra", wilaya_id: "7", id: "302" },
  { nom: "El Kantara", wilaya: "Biskra", wilaya_id: "7", id: "303" },
  { nom: "El Outaya", wilaya: "Biskra", wilaya_id: "7", id: "304" },
  { nom: "Foughala", wilaya: "Biskra", wilaya_id: "7", id: "305" },
  { nom: "Khanguet Sidi Nadji", wilaya: "Biskra", wilaya_id: "7", id: "306" },
  { nom: "Lichana", wilaya: "Biskra", wilaya_id: "7", id: "307" },
  { nom: "Lioua", wilaya: "Biskra", wilaya_id: "7", id: "308" },
  { nom: "Mechounèche", wilaya: "Biskra", wilaya_id: "7", id: "309" },
  { nom: "Mekhadma", wilaya: "Biskra", wilaya_id: "7", id: "310" },
  { nom: "Meziraa", wilaya: "Biskra", wilaya_id: "7", id: "311" },
  { nom: "Ouled Djellal", wilaya: "Biskra", wilaya_id: "7", id: "312" },
  { nom: "Oumache", wilaya: "Biskra", wilaya_id: "7", id: "313" },
  { nom: "Ourlal", wilaya: "Biskra", wilaya_id: "7", id: "314" },
  { nom: "Ras El Miaad", wilaya: "Biskra", wilaya_id: "7", id: "315" },
  { nom: "Sidi Khaled", wilaya: "Biskra", wilaya_id: "7", id: "316" },
  { nom: "Sidi Okba", wilaya: "Biskra", wilaya_id: "7", id: "317" },
  { nom: "Tolga", wilaya: "Biskra", wilaya_id: "7", id: "318" },
  { nom: "Zeribet El Oued", wilaya: "Biskra", wilaya_id: "7", id: "319" },
  { nom: "Ain Romana", wilaya: "Blida", wilaya_id: "9", id: "320" },
  { nom: "Ben Khellil", wilaya: "Blida", wilaya_id: "9", id: "321" },
  { nom: "Blida", wilaya: "Blida", wilaya_id: "9", id: "322" },
  { nom: "Bouarfa", wilaya: "Blida", wilaya_id: "9", id: "323" },
  { nom: "Boufarik", wilaya: "Blida", wilaya_id: "9", id: "324" },
  { nom: "Bougara", wilaya: "Blida", wilaya_id: "9", id: "325" },
  { nom: "Bouinan", wilaya: "Blida", wilaya_id: "9", id: "326" },
  { nom: "Béni Mered", wilaya: "Blida", wilaya_id: "9", id: "327" },
  { nom: "Béni Tamou", wilaya: "Blida", wilaya_id: "9", id: "328" },
  { nom: "Chebli", wilaya: "Blida", wilaya_id: "9", id: "329" },
  { nom: "Chiffa", wilaya: "Blida", wilaya_id: "9", id: "330" },
  { nom: "Chréa", wilaya: "Blida", wilaya_id: "9", id: "331" },
  { nom: "Djebabra", wilaya: "Blida", wilaya_id: "9", id: "332" },
  { nom: "El Affroun", wilaya: "Blida", wilaya_id: "9", id: "333" },
  { nom: "Guerrouaou", wilaya: "Blida", wilaya_id: "9", id: "334" },
  { nom: "Hammam El Ouane", wilaya: "Blida", wilaya_id: "9", id: "335" },
  { nom: "Larbaa", wilaya: "Blida", wilaya_id: "9", id: "336" },
  { nom: "Meftah", wilaya: "Blida", wilaya_id: "9", id: "337" },
  { nom: "Mouzaïa", wilaya: "Blida", wilaya_id: "9", id: "338" },
  { nom: "Oued Djer", wilaya: "Blida", wilaya_id: "9", id: "339" },
  { nom: "Oued El Alleug", wilaya: "Blida", wilaya_id: "9", id: "340" },
  { nom: "Ouled Selama", wilaya: "Blida", wilaya_id: "9", id: "341" },
  { nom: "Ouled Yaich", wilaya: "Blida", wilaya_id: "9", id: "342" },
  { nom: "Souhane", wilaya: "Blida", wilaya_id: "9", id: "343" },
  { nom: "Soumaa", wilaya: "Blida", wilaya_id: "9", id: "344" },
  {
    nom: "Ain Taghrout",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "345",
  },
  {
    nom: "Aïn Tesra",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "346",
  },
  { nom: "Belimour", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "347" },
  {
    nom: "Ben Daoud",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "348",
  },
  {
    nom: "Bir Kasdali",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "349",
  },
  {
    nom: "Bordj Bou Arréridj",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "350",
  },
  {
    nom: "Bordj Ghdir",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "351",
  },
  {
    nom: "Bordj Zemmoura",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "352",
  },
  { nom: "Colla", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "353" },
  { nom: "Djaafra", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "354" },
  { nom: "El Ach", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "355" },
  { nom: "El Achir", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "356" },
  {
    nom: "El Anceur",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "357",
  },
  {
    nom: "El Hamadia",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "358",
  },
  { nom: "El Main", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "359" },
  { nom: "El Mehir", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "360" },
  { nom: "Ghilassa", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "361" },
  { nom: "Haraza", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "362" },
  { nom: "Hasnaoua", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "363" },
  { nom: "Khelil", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "364" },
  { nom: "Ksour", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "365" },
  {
    nom: "Mansourah",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "366",
  },
  { nom: "Medjana", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "367" },
  {
    nom: "Ouled Braham",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "368",
  },
  {
    nom: "Ouled Dahmane",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "369",
  },
  {
    nom: "Ouled Sidi Brahim",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "370",
  },
  { nom: "Rabta", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "371" },
  {
    nom: "Ras El Oued",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "372",
  },
  { nom: "Tafreg", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "373" },
  { nom: "Taglait", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "374" },
  { nom: "Tasmart", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "375" },
  {
    nom: "Teniet En Nasr",
    wilaya: "Bordj Bou Arreridj",
    wilaya_id: "34",
    id: "376",
  },
  { nom: "Tixter", wilaya: "Bordj Bou Arreridj", wilaya_id: "34", id: "377" },
  { nom: "Aghbalou", wilaya: "Bouira", wilaya_id: "10", id: "378" },
  { nom: "Ahl El Ksar", wilaya: "Bouira", wilaya_id: "10", id: "379" },
  { nom: "Aomar", wilaya: "Bouira", wilaya_id: "10", id: "380" },
  { nom: "Aïn Bessem", wilaya: "Bouira", wilaya_id: "10", id: "381" },
  { nom: "Aïn Lahdjar", wilaya: "Bouira", wilaya_id: "10", id: "382" },
  { nom: "Aïn Laloui", wilaya: "Bouira", wilaya_id: "10", id: "383" },
  { nom: "Aïn Turk", wilaya: "Bouira", wilaya_id: "10", id: "384" },
  { nom: "Aït Laaziz", wilaya: "Bouira", wilaya_id: "10", id: "385" },
  { nom: "Bechloul", wilaya: "Bouira", wilaya_id: "10", id: "386" },
  { nom: "Bir Ghbalou", wilaya: "Bouira", wilaya_id: "10", id: "387" },
  { nom: "Bordj Okhriss", wilaya: "Bouira", wilaya_id: "10", id: "388" },
  { nom: "Bouderbala", wilaya: "Bouira", wilaya_id: "10", id: "389" },
  { nom: "Bouira", wilaya: "Bouira", wilaya_id: "10", id: "390" },
  { nom: "Boukram", wilaya: "Bouira", wilaya_id: "10", id: "391" },
  { nom: "Chorfa", wilaya: "Bouira", wilaya_id: "10", id: "392" },
  { nom: "Dechmia", wilaya: "Bouira", wilaya_id: "10", id: "393" },
  { nom: "Dirah", wilaya: "Bouira", wilaya_id: "10", id: "394" },
  { nom: "Djebahia", wilaya: "Bouira", wilaya_id: "10", id: "395" },
  { nom: "El Adjiba", wilaya: "Bouira", wilaya_id: "10", id: "396" },
  { nom: "El Asnam", wilaya: "Bouira", wilaya_id: "10", id: "397" },
  { nom: "El Hachimia", wilaya: "Bouira", wilaya_id: "10", id: "398" },
  { nom: "El Hakimia", wilaya: "Bouira", wilaya_id: "10", id: "399" },
  { nom: "El Khebouzia", wilaya: "Bouira", wilaya_id: "10", id: "400" },
  { nom: "El Mokrani", wilaya: "Bouira", wilaya_id: "10", id: "401" },
  { nom: "Guerrouma", wilaya: "Bouira", wilaya_id: "10", id: "402" },
  { nom: "Hadjra Zerga", wilaya: "Bouira", wilaya_id: "10", id: "403" },
  { nom: "Haizer", wilaya: "Bouira", wilaya_id: "10", id: "404" },
  { nom: "Hanif", wilaya: "Bouira", wilaya_id: "10", id: "405" },
  { nom: "Kadiria", wilaya: "Bouira", wilaya_id: "10", id: "406" },
  { nom: "Lakhdaria", wilaya: "Bouira", wilaya_id: "10", id: "407" },
  { nom: "Maala", wilaya: "Bouira", wilaya_id: "10", id: "408" },
  { nom: "Maamora", wilaya: "Bouira", wilaya_id: "10", id: "409" },
  { nom: "Mesdour", wilaya: "Bouira", wilaya_id: "10", id: "410" },
  { nom: "Oued El Berdi", wilaya: "Bouira", wilaya_id: "10", id: "411" },
  { nom: "Ouled Rached", wilaya: "Bouira", wilaya_id: "10", id: "412" },
  { nom: "Raouraoua", wilaya: "Bouira", wilaya_id: "10", id: "413" },
  { nom: "Ridane", wilaya: "Bouira", wilaya_id: "10", id: "414" },
  { nom: "Saharidj", wilaya: "Bouira", wilaya_id: "10", id: "415" },
  { nom: "Souk El Khemis", wilaya: "Bouira", wilaya_id: "10", id: "416" },
  { nom: "Sour El Ghozlane", wilaya: "Bouira", wilaya_id: "10", id: "417" },
  { nom: "Taghzout", wilaya: "Bouira", wilaya_id: "10", id: "418" },
  { nom: "Taguedit", wilaya: "Bouira", wilaya_id: "10", id: "419" },
  { nom: "Taourirt", wilaya: "Bouira", wilaya_id: "10", id: "420" },
  { nom: "Afir", wilaya: "Boumerdes", wilaya_id: "35", id: "421" },
  { nom: "Ammal", wilaya: "Boumerdes", wilaya_id: "35", id: "422" },
  { nom: "Baghlia", wilaya: "Boumerdes", wilaya_id: "35", id: "423" },
  { nom: "Benchoud", wilaya: "Boumerdes", wilaya_id: "35", id: "424" },
  { nom: "Beni Amrane", wilaya: "Boumerdes", wilaya_id: "35", id: "425" },
  { nom: "Bordj Menaïel", wilaya: "Boumerdes", wilaya_id: "35", id: "426" },
  { nom: "Boudouaou", wilaya: "Boumerdes", wilaya_id: "35", id: "427" },
  {
    nom: "Boudouaou El Bahri",
    wilaya: "Boumerdes",
    wilaya_id: "35",
    id: "428",
  },
  { nom: "Boumerdes", wilaya: "Boumerdes", wilaya_id: "35", id: "429" },
  { nom: "Chaabet El Ameur", wilaya: "Boumerdes", wilaya_id: "35", id: "430" },
  { nom: "Corso", wilaya: "Boumerdes", wilaya_id: "35", id: "431" },
  { nom: "Dellys", wilaya: "Boumerdes", wilaya_id: "35", id: "432" },
  { nom: "Djinet", wilaya: "Boumerdes", wilaya_id: "35", id: "433" },
  { nom: "El Kharouba", wilaya: "Boumerdes", wilaya_id: "35", id: "434" },
  { nom: "Hammedi", wilaya: "Boumerdes", wilaya_id: "35", id: "435" },
  { nom: "Isser", wilaya: "Boumerdes", wilaya_id: "35", id: "436" },
  { nom: "Keddara", wilaya: "Boumerdes", wilaya_id: "35", id: "437" },
  { nom: "Khemis El Khechna", wilaya: "Boumerdes", wilaya_id: "35", id: "438" },
  { nom: "Lagata", wilaya: "Boumerdes", wilaya_id: "35", id: "439" },
  { nom: "Larbatache", wilaya: "Boumerdes", wilaya_id: "35", id: "440" },
  { nom: "Naciria", wilaya: "Boumerdes", wilaya_id: "35", id: "441" },
  { nom: "Ouled Aïssa", wilaya: "Boumerdes", wilaya_id: "35", id: "442" },
  { nom: "Ouled Hadjadj", wilaya: "Boumerdes", wilaya_id: "35", id: "443" },
  { nom: "Ouled Moussa", wilaya: "Boumerdes", wilaya_id: "35", id: "444" },
  { nom: "Si Mustapha", wilaya: "Boumerdes", wilaya_id: "35", id: "445" },
  { nom: "Sidi Daoud", wilaya: "Boumerdes", wilaya_id: "35", id: "446" },
  { nom: "Souk El Had", wilaya: "Boumerdes", wilaya_id: "35", id: "447" },
  { nom: "Taourga", wilaya: "Boumerdes", wilaya_id: "35", id: "448" },
  { nom: "Thenia", wilaya: "Boumerdes", wilaya_id: "35", id: "449" },
  { nom: "Tidjelabine", wilaya: "Boumerdes", wilaya_id: "35", id: "450" },
  { nom: "Timezrit", wilaya: "Boumerdes", wilaya_id: "35", id: "451" },
  { nom: "Zemmouri", wilaya: "Boumerdes", wilaya_id: "35", id: "452" },
  { nom: "Abou El Hassan", wilaya: "Chlef", wilaya_id: "2", id: "453" },
  { nom: "Ain Merane", wilaya: "Chlef", wilaya_id: "2", id: "454" },
  { nom: "Benaria", wilaya: "Chlef", wilaya_id: "2", id: "455" },
  { nom: "Boukadir", wilaya: "Chlef", wilaya_id: "2", id: "456" },
  { nom: "Bouzghaïa", wilaya: "Chlef", wilaya_id: "2", id: "457" },
  { nom: "Breira", wilaya: "Chlef", wilaya_id: "2", id: "458" },
  { nom: "Béni Bouatteb", wilaya: "Chlef", wilaya_id: "2", id: "459" },
  { nom: "Béni Haoua", wilaya: "Chlef", wilaya_id: "2", id: "460" },
  { nom: "Béni Rached", wilaya: "Chlef", wilaya_id: "2", id: "461" },
  { nom: "Chettia", wilaya: "Chlef", wilaya_id: "2", id: "462" },
  { nom: "Chlef", wilaya: "Chlef", wilaya_id: "2", id: "463" },
  { nom: "Dahra", wilaya: "Chlef", wilaya_id: "2", id: "464" },
  { nom: "EL KARIMIA", wilaya: "Chlef", wilaya_id: "2", id: "465" },
  { nom: "El Hadjadj", wilaya: "Chlef", wilaya_id: "2", id: "466" },
  { nom: "El Marsa", wilaya: "Chlef", wilaya_id: "2", id: "467" },
  { nom: "Harchoune", wilaya: "Chlef", wilaya_id: "2", id: "468" },
  { nom: "Harenfa", wilaya: "Chlef", wilaya_id: "2", id: "469" },
  { nom: "Labiodh Medjadja", wilaya: "Chlef", wilaya_id: "2", id: "470" },
  { nom: "Mossadek", wilaya: "Chlef", wilaya_id: "2", id: "471" },
  { nom: "Oued Fodda", wilaya: "Chlef", wilaya_id: "2", id: "472" },
  { nom: "Oued Goussine", wilaya: "Chlef", wilaya_id: "2", id: "473" },
  { nom: "Oued Sly", wilaya: "Chlef", wilaya_id: "2", id: "474" },
  { nom: "Ouled Abbas", wilaya: "Chlef", wilaya_id: "2", id: "475" },
  { nom: "Ouled Ben Abdelkader", wilaya: "Chlef", wilaya_id: "2", id: "476" },
  { nom: "Ouled Farès", wilaya: "Chlef", wilaya_id: "2", id: "477" },
  { nom: "Oum Drou", wilaya: "Chlef", wilaya_id: "2", id: "478" },
  { nom: "Sendjas", wilaya: "Chlef", wilaya_id: "2", id: "479" },
  { nom: "Sidi Abderrhmane", wilaya: "Chlef", wilaya_id: "2", id: "480" },
  { nom: "Sidi Akacha", wilaya: "Chlef", wilaya_id: "2", id: "481" },
  { nom: "Sobha", wilaya: "Chlef", wilaya_id: "2", id: "482" },
  { nom: "Tadjena", wilaya: "Chlef", wilaya_id: "2", id: "483" },
  { nom: "Talassa", wilaya: "Chlef", wilaya_id: "2", id: "484" },
  { nom: "Taougrite", wilaya: "Chlef", wilaya_id: "2", id: "485" },
  { nom: "Ténès", wilaya: "Chlef", wilaya_id: "2", id: "486" },
  { nom: "Zeboudja", wilaya: "Chlef", wilaya_id: "2", id: "487" },
  { nom: "Aïn Abid", wilaya: "Constantine", wilaya_id: "25", id: "488" },
  { nom: "Aïn Smara", wilaya: "Constantine", wilaya_id: "25", id: "489" },
  { nom: "Ben Badis", wilaya: "Constantine", wilaya_id: "25", id: "490" },
  { nom: "Beni Hamidane", wilaya: "Constantine", wilaya_id: "25", id: "491" },
  { nom: "Constantine", wilaya: "Constantine", wilaya_id: "25", id: "492" },
  { nom: "Didouche Mourad", wilaya: "Constantine", wilaya_id: "25", id: "493" },
  { nom: "El Khroub", wilaya: "Constantine", wilaya_id: "25", id: "494" },
  { nom: "Hamma Bouziane", wilaya: "Constantine", wilaya_id: "25", id: "495" },
  { nom: "Ibn Ziad", wilaya: "Constantine", wilaya_id: "25", id: "496" },
  {
    nom: "Messaoud Boudjeriou",
    wilaya: "Constantine",
    wilaya_id: "25",
    id: "497",
  },
  { nom: "Ouled Rahmoun", wilaya: "Constantine", wilaya_id: "25", id: "498" },
  { nom: "Zighoud Youcef", wilaya: "Constantine", wilaya_id: "25", id: "499" },
  { nom: "Amourah", wilaya: "Djelfa", wilaya_id: "17", id: "500" },
  { nom: "Aïn Echouhada", wilaya: "Djelfa", wilaya_id: "17", id: "501" },
  { nom: "Aïn El Ibel", wilaya: "Djelfa", wilaya_id: "17", id: "502" },
  { nom: "Aïn Feka", wilaya: "Djelfa", wilaya_id: "17", id: "503" },
  { nom: "Aïn Maabed", wilaya: "Djelfa", wilaya_id: "17", id: "504" },
  { nom: "Aïn Oussera", wilaya: "Djelfa", wilaya_id: "17", id: "505" },
  { nom: "Ben Nahar", wilaya: "Djelfa", wilaya_id: "17", id: "506" },
  { nom: "Ben Yacoub", wilaya: "Djelfa", wilaya_id: "17", id: "507" },
  { nom: "Birine", wilaya: "Djelfa", wilaya_id: "17", id: "508" },
  { nom: "Bouira Lahdeb", wilaya: "Djelfa", wilaya_id: "17", id: "509" },
  { nom: "Charef", wilaya: "Djelfa", wilaya_id: "17", id: "510" },
  { nom: "Dar Chioukh", wilaya: "Djelfa", wilaya_id: "17", id: "511" },
  { nom: "Deldoul", wilaya: "Djelfa", wilaya_id: "17", id: "512" },
  { nom: "Djelfa", wilaya: "Djelfa", wilaya_id: "17", id: "513" },
  { nom: "Douis", wilaya: "Djelfa", wilaya_id: "17", id: "514" },
  { nom: "El Guedid", wilaya: "Djelfa", wilaya_id: "17", id: "515" },
  { nom: "El Guernini", wilaya: "Djelfa", wilaya_id: "17", id: "516" },
  { nom: "El Idrissia", wilaya: "Djelfa", wilaya_id: "17", id: "517" },
  { nom: "El Khemis", wilaya: "Djelfa", wilaya_id: "17", id: "518" },
  { nom: "Faidh El Botma", wilaya: "Djelfa", wilaya_id: "17", id: "519" },
  { nom: "Guettara", wilaya: "Djelfa", wilaya_id: "17", id: "520" },
  { nom: "Had Sahary", wilaya: "Djelfa", wilaya_id: "17", id: "521" },
  { nom: "Hassi Bahbah", wilaya: "Djelfa", wilaya_id: "17", id: "522" },
  { nom: "Hassi El Euch", wilaya: "Djelfa", wilaya_id: "17", id: "523" },
  { nom: "Hassi Fedoul", wilaya: "Djelfa", wilaya_id: "17", id: "524" },
  { nom: "Messaad", wilaya: "Djelfa", wilaya_id: "17", id: "525" },
  { nom: "Mouadjebar", wilaya: "Djelfa", wilaya_id: "17", id: "526" },
  { nom: "Oum El Adham", wilaya: "Djelfa", wilaya_id: "17", id: "527" },
  { nom: "Sed Rahal", wilaya: "Djelfa", wilaya_id: "17", id: "528" },
  { nom: "Selmana", wilaya: "Djelfa", wilaya_id: "17", id: "529" },
  { nom: "Sidi Baizid", wilaya: "Djelfa", wilaya_id: "17", id: "530" },
  { nom: "Sidi Ladjel", wilaya: "Djelfa", wilaya_id: "17", id: "531" },
  { nom: "Tadmit", wilaya: "Djelfa", wilaya_id: "17", id: "532" },
  { nom: "Zaafrane", wilaya: "Djelfa", wilaya_id: "17", id: "533" },
  { nom: "Zaccar", wilaya: "Djelfa", wilaya_id: "17", id: "534" },
  { nom: "Arbaout", wilaya: "El Bayadh", wilaya_id: "32", id: "535" },
  { nom: "Aïn El Orak", wilaya: "El Bayadh", wilaya_id: "32", id: "536" },
  { nom: "Boualem", wilaya: "El Bayadh", wilaya_id: "32", id: "537" },
  { nom: "Bougtoub", wilaya: "El Bayadh", wilaya_id: "32", id: "538" },
  { nom: "Boussemghoum", wilaya: "El Bayadh", wilaya_id: "32", id: "539" },
  { nom: "Brezina", wilaya: "El Bayadh", wilaya_id: "32", id: "540" },
  { nom: "Cheguig", wilaya: "El Bayadh", wilaya_id: "32", id: "541" },
  { nom: "Chellala", wilaya: "El Bayadh", wilaya_id: "32", id: "542" },
  { nom: "El Bayadh", wilaya: "El Bayadh", wilaya_id: "32", id: "543" },
  { nom: "El Bnoud", wilaya: "El Bayadh", wilaya_id: "32", id: "544" },
  { nom: "El Kheiter", wilaya: "El Bayadh", wilaya_id: "32", id: "545" },
  { nom: "El Mehara", wilaya: "El Bayadh", wilaya_id: "32", id: "546" },
  { nom: "Ghassoul", wilaya: "El Bayadh", wilaya_id: "32", id: "547" },
  { nom: "Kef  Lahmar", wilaya: "El Bayadh", wilaya_id: "32", id: "548" },
  { nom: "Krakda", wilaya: "El Bayadh", wilaya_id: "32", id: "549" },
  {
    nom: "Labiodh Sidi Cheikh",
    wilaya: "El Bayadh",
    wilaya_id: "32",
    id: "550",
  },
  { nom: "Rogassa", wilaya: "El Bayadh", wilaya_id: "32", id: "551" },
  { nom: "Sidi Amar", wilaya: "El Bayadh", wilaya_id: "32", id: "552" },
  { nom: "Sidi Slimane", wilaya: "El Bayadh", wilaya_id: "32", id: "553" },
  { nom: "Sidi Taifour", wilaya: "El Bayadh", wilaya_id: "32", id: "554" },
  { nom: "Stitten", wilaya: "El Bayadh", wilaya_id: "32", id: "555" },
  { nom: "Tousmouline", wilaya: "El Bayadh", wilaya_id: "32", id: "556" },
  { nom: "Bayadha", wilaya: "El Oued", wilaya_id: "39", id: "557" },
  { nom: "Ben Guecha", wilaya: "El Oued", wilaya_id: "39", id: "558" },
  { nom: "Debila", wilaya: "El Oued", wilaya_id: "39", id: "559" },
  { nom: "Djamaa", wilaya: "El Oued", wilaya_id: "39", id: "560" },
  { nom: "Douar El Ma", wilaya: "El Oued", wilaya_id: "39", id: "561" },
  { nom: "El Meghaier", wilaya: "El Oued", wilaya_id: "39", id: "562" },
  { nom: "El Ogla", wilaya: "El Oued", wilaya_id: "39", id: "563" },
  { nom: "El Oued", wilaya: "El Oued", wilaya_id: "39", id: "564" },
  { nom: "Guemar", wilaya: "El Oued", wilaya_id: "39", id: "565" },
  { nom: "Hamraia", wilaya: "El Oued", wilaya_id: "39", id: "566" },
  { nom: "Hassani Abdelkrim", wilaya: "El Oued", wilaya_id: "39", id: "567" },
  { nom: "Hassi Khelifa", wilaya: "El Oued", wilaya_id: "39", id: "568" },
  { nom: "Kouinine", wilaya: "El Oued", wilaya_id: "39", id: "569" },
  { nom: "Magrane", wilaya: "El Oued", wilaya_id: "39", id: "570" },
  { nom: "Mih Ouensa", wilaya: "El Oued", wilaya_id: "39", id: "571" },
  { nom: "Nakhla", wilaya: "El Oued", wilaya_id: "39", id: "572" },
  { nom: "Oued Allenda", wilaya: "El Oued", wilaya_id: "39", id: "573" },
  { nom: "Oum Touyour", wilaya: "El Oued", wilaya_id: "39", id: "574" },
  { nom: "Ourmes", wilaya: "El Oued", wilaya_id: "39", id: "575" },
  { nom: "Reguiba", wilaya: "El Oued", wilaya_id: "39", id: "576" },
  { nom: "Robbah", wilaya: "El Oued", wilaya_id: "39", id: "577" },
  { nom: "Sidi Amrane", wilaya: "El Oued", wilaya_id: "39", id: "578" },
  { nom: "Sidi Aoun", wilaya: "El Oued", wilaya_id: "39", id: "579" },
  { nom: "Sidi Khelil", wilaya: "El Oued", wilaya_id: "39", id: "580" },
  { nom: "Still", wilaya: "El Oued", wilaya_id: "39", id: "581" },
  { nom: "Taghzout", wilaya: "El Oued", wilaya_id: "39", id: "582" },
  { nom: "Taleb Larbi", wilaya: "El Oued", wilaya_id: "39", id: "583" },
  { nom: "Tinedla", wilaya: "El Oued", wilaya_id: "39", id: "584" },
  { nom: "Trifaoui", wilaya: "El Oued", wilaya_id: "39", id: "585" },
  { nom: "Asfour", wilaya: "El Tarf", wilaya_id: "36", id: "586" },
  { nom: "Aïn El Assel", wilaya: "El Tarf", wilaya_id: "36", id: "587" },
  { nom: "Aïn Karma", wilaya: "El Tarf", wilaya_id: "36", id: "588" },
  { nom: "Berrihane", wilaya: "El Tarf", wilaya_id: "36", id: "589" },
  { nom: "Besbes", wilaya: "El Tarf", wilaya_id: "36", id: "590" },
  { nom: "Bouggous", wilaya: "El Tarf", wilaya_id: "36", id: "591" },
  { nom: "Bouhadjar", wilaya: "El Tarf", wilaya_id: "36", id: "592" },
  { nom: "Bouteldja", wilaya: "El Tarf", wilaya_id: "36", id: "593" },
  { nom: "Chatt", wilaya: "El Tarf", wilaya_id: "36", id: "594" },
  { nom: "Chbaita Mokhtar", wilaya: "El Tarf", wilaya_id: "36", id: "595" },
  { nom: "Cheffia", wilaya: "El Tarf", wilaya_id: "36", id: "596" },
  { nom: "Chihani", wilaya: "El Tarf", wilaya_id: "36", id: "597" },
  { nom: "Drean", wilaya: "El Tarf", wilaya_id: "36", id: "598" },
  { nom: "El Aïoun", wilaya: "El Tarf", wilaya_id: "36", id: "599" },
  { nom: "El Kala", wilaya: "El Tarf", wilaya_id: "36", id: "600" },
  { nom: "El Tarf", wilaya: "El Tarf", wilaya_id: "36", id: "601" },
  { nom: "Hammam Beni Salah", wilaya: "El Tarf", wilaya_id: "36", id: "602" },
  { nom: "Lac Des Oiseaux", wilaya: "El Tarf", wilaya_id: "36", id: "603" },
  { nom: "Oued Zitoun", wilaya: "El Tarf", wilaya_id: "36", id: "604" },
  { nom: "Raml Essouk", wilaya: "El Tarf", wilaya_id: "36", id: "605" },
  { nom: "Souarekh", wilaya: "El Tarf", wilaya_id: "36", id: "606" },
  { nom: "Zerizer", wilaya: "El Tarf", wilaya_id: "36", id: "607" },
  { nom: "Zitouna", wilaya: "El Tarf", wilaya_id: "36", id: "608" },
  { nom: "Berriane", wilaya: "Ghardaia", wilaya_id: "47", id: "609" },
  { nom: "Bounoura", wilaya: "Ghardaia", wilaya_id: "47", id: "610" },
  {
    nom: "Dhayat Ben  Dhahoua",
    wilaya: "Ghardaia",
    wilaya_id: "47",
    id: "611",
  },
  { nom: "El Atteuf", wilaya: "Ghardaia", wilaya_id: "47", id: "612" },
  { nom: "El Guerara", wilaya: "Ghardaia", wilaya_id: "47", id: "613" },
  { nom: "El Meniaa", wilaya: "Ghardaia", wilaya_id: "47", id: "614" },
  { nom: "Ghardaia", wilaya: "Ghardaia", wilaya_id: "47", id: "615" },
  { nom: "Hassi  El Fehal", wilaya: "Ghardaia", wilaya_id: "47", id: "616" },
  { nom: "Hassi Gara", wilaya: "Ghardaia", wilaya_id: "47", id: "617" },
  { nom: "Mansourah", wilaya: "Ghardaia", wilaya_id: "47", id: "618" },
  { nom: "Metlili", wilaya: "Ghardaia", wilaya_id: "47", id: "619" },
  { nom: "Sebseb", wilaya: "Ghardaia", wilaya_id: "47", id: "620" },
  { nom: "Zelfana", wilaya: "Ghardaia", wilaya_id: "47", id: "621" },
  { nom: "Aïn Ben Beida", wilaya: "Guelma", wilaya_id: "24", id: "622" },
  { nom: "Aïn Larbi", wilaya: "Guelma", wilaya_id: "24", id: "623" },
  { nom: "Aïn Makhlouf", wilaya: "Guelma", wilaya_id: "24", id: "624" },
  { nom: "Aïn Regada", wilaya: "Guelma", wilaya_id: "24", id: "625" },
  { nom: "Aïn Sandel", wilaya: "Guelma", wilaya_id: "24", id: "626" },
  { nom: "Badjarah", wilaya: "Guelma", wilaya_id: "24", id: "627" },
  { nom: "Belkheir", wilaya: "Guelma", wilaya_id: "24", id: "628" },
  { nom: "Beni Mezline", wilaya: "Guelma", wilaya_id: "24", id: "629" },
  { nom: "Bordj Sabat", wilaya: "Guelma", wilaya_id: "24", id: "630" },
  { nom: "Bouati Mahmoud", wilaya: "Guelma", wilaya_id: "24", id: "631" },
  { nom: "Bouchegouf", wilaya: "Guelma", wilaya_id: "24", id: "632" },
  { nom: "Bouhachana", wilaya: "Guelma", wilaya_id: "24", id: "633" },
  { nom: "Bouhamdane", wilaya: "Guelma", wilaya_id: "24", id: "634" },
  { nom: "Boumahra Ahmed", wilaya: "Guelma", wilaya_id: "24", id: "635" },
  { nom: "Dahouara", wilaya: "Guelma", wilaya_id: "24", id: "636" },
  { nom: "Djebala Khemissi", wilaya: "Guelma", wilaya_id: "24", id: "637" },
  { nom: "El Fedjoudj", wilaya: "Guelma", wilaya_id: "24", id: "638" },
  { nom: "Guelaat Bousbaa", wilaya: "Guelma", wilaya_id: "24", id: "639" },
  { nom: "Guelma", wilaya: "Guelma", wilaya_id: "24", id: "640" },
  { nom: "Hammam Debagh", wilaya: "Guelma", wilaya_id: "24", id: "641" },
  { nom: "Heliopolis", wilaya: "Guelma", wilaya_id: "24", id: "642" },
  { nom: "Houari Boumediene", wilaya: "Guelma", wilaya_id: "24", id: "643" },
  { nom: "Khzara", wilaya: "Guelma", wilaya_id: "24", id: "644" },
  { nom: "Medjez Amar", wilaya: "Guelma", wilaya_id: "24", id: "645" },
  { nom: "Medjez Sfa", wilaya: "Guelma", wilaya_id: "24", id: "646" },
  { nom: "Nechmaya", wilaya: "Guelma", wilaya_id: "24", id: "647" },
  { nom: "Oued Cheham", wilaya: "Guelma", wilaya_id: "24", id: "648" },
  { nom: "Oued Fraga", wilaya: "Guelma", wilaya_id: "24", id: "649" },
  { nom: "Oued Zenati", wilaya: "Guelma", wilaya_id: "24", id: "650" },
  { nom: "Ras El Agba", wilaya: "Guelma", wilaya_id: "24", id: "651" },
  { nom: "Roknia", wilaya: "Guelma", wilaya_id: "24", id: "652" },
  { nom: "Sellaoua Announa", wilaya: "Guelma", wilaya_id: "24", id: "653" },
  { nom: "Tamlouka", wilaya: "Guelma", wilaya_id: "24", id: "654" },
  { nom: "Bordj El Houasse", wilaya: "Illizi", wilaya_id: "33", id: "655" },
  { nom: "Bordj Omar Driss", wilaya: "Illizi", wilaya_id: "33", id: "656" },
  { nom: "Debdeb", wilaya: "Illizi", wilaya_id: "33", id: "657" },
  { nom: "Djanet", wilaya: "Illizi", wilaya_id: "33", id: "658" },
  { nom: "Illizi", wilaya: "Illizi", wilaya_id: "33", id: "659" },
  { nom: "In Amenas", wilaya: "Illizi", wilaya_id: "33", id: "660" },
  { nom: "Boucif Ouled Askeur", wilaya: "Jijel", wilaya_id: "18", id: "661" },
  { nom: "Boudria  Beniyadjis", wilaya: "Jijel", wilaya_id: "18", id: "662" },
  { nom: "Bouraoui Belhadef", wilaya: "Jijel", wilaya_id: "18", id: "663" },
  { nom: "Chahna", wilaya: "Jijel", wilaya_id: "18", id: "664" },
  { nom: "Chekfa", wilaya: "Jijel", wilaya_id: "18", id: "665" },
  { nom: "El Ancer", wilaya: "Jijel", wilaya_id: "18", id: "666" },
  { nom: "El Aouana", wilaya: "Jijel", wilaya_id: "18", id: "667" },
  { nom: "El Djemaa Beni Habibi", wilaya: "Jijel", wilaya_id: "18", id: "668" },
  { nom: "El Kennar Nouchfi", wilaya: "Jijel", wilaya_id: "18", id: "669" },
  { nom: "El Milia", wilaya: "Jijel", wilaya_id: "18", id: "670" },
  { nom: "Emir Abdelkader", wilaya: "Jijel", wilaya_id: "18", id: "671" },
  { nom: "Erraguène", wilaya: "Jijel", wilaya_id: "18", id: "672" },
  { nom: "Ghebala", wilaya: "Jijel", wilaya_id: "18", id: "673" },
  { nom: "Jijel", wilaya: "Jijel", wilaya_id: "18", id: "674" },
  { nom: "Jimla", wilaya: "Jijel", wilaya_id: "18", id: "675" },
  { nom: "Kaous", wilaya: "Jijel", wilaya_id: "18", id: "676" },
  { nom: "Khier Oued Adjoul", wilaya: "Jijel", wilaya_id: "18", id: "677" },
  { nom: "Ouadjana", wilaya: "Jijel", wilaya_id: "18", id: "678" },
  { nom: "Ouled Rabah", wilaya: "Jijel", wilaya_id: "18", id: "679" },
  {
    nom: "Ouled Yahia Khadrouche",
    wilaya: "Jijel",
    wilaya_id: "18",
    id: "680",
  },
  { nom: "Selma  Ben Ziada", wilaya: "Jijel", wilaya_id: "18", id: "681" },
  { nom: "Settara", wilaya: "Jijel", wilaya_id: "18", id: "682" },
  { nom: "Sidi Abdelaziz", wilaya: "Jijel", wilaya_id: "18", id: "683" },
  { nom: "Sidi Marouf", wilaya: "Jijel", wilaya_id: "18", id: "684" },
  { nom: "Taher", wilaya: "Jijel", wilaya_id: "18", id: "685" },
  { nom: "Texenna", wilaya: "Jijel", wilaya_id: "18", id: "686" },
  { nom: "Ziamah  Mansouriah", wilaya: "Jijel", wilaya_id: "18", id: "687" },
  { nom: "Aïn Touila", wilaya: "Khenchela", wilaya_id: "40", id: "688" },
  { nom: "Babar", wilaya: "Khenchela", wilaya_id: "40", id: "689" },
  { nom: "Baghai", wilaya: "Khenchela", wilaya_id: "40", id: "690" },
  { nom: "Bouhmama", wilaya: "Khenchela", wilaya_id: "40", id: "691" },
  { nom: "Chechar", wilaya: "Khenchela", wilaya_id: "40", id: "692" },
  { nom: "Chellia", wilaya: "Khenchela", wilaya_id: "40", id: "693" },
  { nom: "Djellal", wilaya: "Khenchela", wilaya_id: "40", id: "694" },
  { nom: "El Hamma", wilaya: "Khenchela", wilaya_id: "40", id: "695" },
  { nom: "El Mahamel", wilaya: "Khenchela", wilaya_id: "40", id: "696" },
  { nom: "El Ouldja", wilaya: "Khenchela", wilaya_id: "40", id: "697" },
  { nom: "Ensigha", wilaya: "Khenchela", wilaya_id: "40", id: "698" },
  { nom: "Kaïs", wilaya: "Khenchela", wilaya_id: "40", id: "699" },
  { nom: "Khairane", wilaya: "Khenchela", wilaya_id: "40", id: "700" },
  { nom: "Khenchela", wilaya: "Khenchela", wilaya_id: "40", id: "701" },
  { nom: "Ouled Rechache", wilaya: "Khenchela", wilaya_id: "40", id: "702" },
  { nom: "Remila", wilaya: "Khenchela", wilaya_id: "40", id: "703" },
  { nom: "Tamza", wilaya: "Khenchela", wilaya_id: "40", id: "704" },
  { nom: "Taouzinet", wilaya: "Khenchela", wilaya_id: "40", id: "705" },
  { nom: "Yabous", wilaya: "Khenchela", wilaya_id: "40", id: "706" },
  { nom: "Aflou", wilaya: "Laghouat", wilaya_id: "3", id: "707" },
  { nom: "Aïn Madhi", wilaya: "Laghouat", wilaya_id: "3", id: "708" },
  { nom: "Aïn Sidi Ali", wilaya: "Laghouat", wilaya_id: "3", id: "709" },
  { nom: "Beidha", wilaya: "Laghouat", wilaya_id: "3", id: "710" },
  { nom: "Benacer Benchohra", wilaya: "Laghouat", wilaya_id: "3", id: "711" },
  { nom: "Brida", wilaya: "Laghouat", wilaya_id: "3", id: "712" },
  { nom: "El  Houita", wilaya: "Laghouat", wilaya_id: "3", id: "713" },
  { nom: "El Assafia", wilaya: "Laghouat", wilaya_id: "3", id: "714" },
  { nom: "El Ghicha", wilaya: "Laghouat", wilaya_id: "3", id: "715" },
  { nom: "Gueltat Sidi Saad", wilaya: "Laghouat", wilaya_id: "3", id: "716" },
  { nom: "Hadj Mecheri", wilaya: "Laghouat", wilaya_id: "3", id: "717" },
  { nom: "Hassi Delaa", wilaya: "Laghouat", wilaya_id: "3", id: "718" },
  { nom: "Kheneg", wilaya: "Laghouat", wilaya_id: "3", id: "719" },
  { nom: "Ksar El Hirane", wilaya: "Laghouat", wilaya_id: "3", id: "720" },
  { nom: "Laghouat", wilaya: "Laghouat", wilaya_id: "3", id: "721" },
  { nom: "Oued Morra", wilaya: "Laghouat", wilaya_id: "3", id: "722" },
  { nom: "Sebgag", wilaya: "Laghouat", wilaya_id: "3", id: "723" },
  { nom: "Sidi Makhlouf", wilaya: "Laghouat", wilaya_id: "3", id: "724" },
  { nom: "Sidi bouzid", wilaya: "Laghouat", wilaya_id: "3", id: "725" },
  { nom: "Tadjemout", wilaya: "Laghouat", wilaya_id: "3", id: "726" },
  { nom: "Tadjerouna", wilaya: "Laghouat", wilaya_id: "3", id: "727" },
  { nom: "Taouiala", wilaya: "Laghouat", wilaya_id: "3", id: "728" },
  { nom: "Aïn El Hadjel", wilaya: "M'Sila", wilaya_id: "28", id: "729" },
  { nom: "Aïn El Khadra", wilaya: "M'Sila", wilaya_id: "28", id: "730" },
  { nom: "Aïn El Melh", wilaya: "M'Sila", wilaya_id: "28", id: "731" },
  { nom: "Aïn Fares", wilaya: "M'Sila", wilaya_id: "28", id: "732" },
  { nom: "Aïn Rich", wilaya: "M'Sila", wilaya_id: "28", id: "733" },
  { nom: "Belaiba", wilaya: "M'Sila", wilaya_id: "28", id: "734" },
  { nom: "Beni Ilmene", wilaya: "M'Sila", wilaya_id: "28", id: "735" },
  { nom: "Bensrour", wilaya: "M'Sila", wilaya_id: "28", id: "736" },
  { nom: "Benzouh", wilaya: "M'Sila", wilaya_id: "28", id: "737" },
  { nom: "Berhoum", wilaya: "M'Sila", wilaya_id: "28", id: "738" },
  { nom: "Bir Foda", wilaya: "M'Sila", wilaya_id: "28", id: "739" },
  { nom: "Boussaada", wilaya: "M'Sila", wilaya_id: "28", id: "740" },
  { nom: "Bouti Sayah", wilaya: "M'Sila", wilaya_id: "28", id: "741" },
  { nom: "Chellal", wilaya: "M'Sila", wilaya_id: "28", id: "742" },
  { nom: "Dehahna", wilaya: "M'Sila", wilaya_id: "28", id: "743" },
  { nom: "Djebel Messaad", wilaya: "M'Sila", wilaya_id: "28", id: "744" },
  { nom: "El Hamel", wilaya: "M'Sila", wilaya_id: "28", id: "745" },
  { nom: "El Houamed", wilaya: "M'Sila", wilaya_id: "28", id: "746" },
  { nom: "Hammam Dhalaa", wilaya: "M'Sila", wilaya_id: "28", id: "747" },
  { nom: "Khatouti Sed El Djir", wilaya: "M'Sila", wilaya_id: "28", id: "748" },
  { nom: "Khoubana", wilaya: "M'Sila", wilaya_id: "28", id: "749" },
  { nom: "Maadid", wilaya: "M'Sila", wilaya_id: "28", id: "750" },
  { nom: "Maarif", wilaya: "M'Sila", wilaya_id: "28", id: "751" },
  { nom: "Magra", wilaya: "M'Sila", wilaya_id: "28", id: "752" },
  { nom: "Medjedel", wilaya: "M'Sila", wilaya_id: "28", id: "753" },
  { nom: "Menaa", wilaya: "M'Sila", wilaya_id: "28", id: "754" },
  { nom: "Metarfa", wilaya: "M'Sila", wilaya_id: "28", id: "755" },
  { nom: "Mohammed Boudiaf", wilaya: "M'Sila", wilaya_id: "28", id: "756" },
  { nom: "Ouanougha", wilaya: "M'Sila", wilaya_id: "28", id: "757" },
  { nom: "Ouled Addi Guebala", wilaya: "M'Sila", wilaya_id: "28", id: "758" },
  { nom: "Ouled Derradj", wilaya: "M'Sila", wilaya_id: "28", id: "759" },
  { nom: "Ouled Mahdi", wilaya: "M'Sila", wilaya_id: "28", id: "760" },
  { nom: "Ouled Mansour", wilaya: "M'Sila", wilaya_id: "28", id: "761" },
  { nom: "Ouled Sidi Brahim", wilaya: "M'Sila", wilaya_id: "28", id: "762" },
  { nom: "Ouled Slimane", wilaya: "M'Sila", wilaya_id: "28", id: "763" },
  { nom: "Oultene", wilaya: "M'Sila", wilaya_id: "28", id: "764" },
  { nom: "Sidi Ameur", wilaya: "M'Sila", wilaya_id: "28", id: "765" },
  { nom: "Sidi Aïssa", wilaya: "M'Sila", wilaya_id: "28", id: "766" },
  { nom: "Sidi Hadjeres", wilaya: "M'Sila", wilaya_id: "28", id: "767" },
  { nom: "Slim", wilaya: "M'Sila", wilaya_id: "28", id: "768" },
  { nom: "Souamaa", wilaya: "M'Sila", wilaya_id: "28", id: "769" },
  { nom: "Tarmount", wilaya: "M'Sila", wilaya_id: "28", id: "770" },
  { nom: "Temsa", wilaya: "M'Sila", wilaya_id: "28", id: "771" },
  { nom: "Zarzour", wilaya: "M'Sila", wilaya_id: "28", id: "772" },
  { nom: "Aouf", wilaya: "Mascara", wilaya_id: "29", id: "773" },
  { nom: "Aïn Fares", wilaya: "Mascara", wilaya_id: "29", id: "774" },
  { nom: "Aïn Fekkan", wilaya: "Mascara", wilaya_id: "29", id: "775" },
  { nom: "Aïn Ferah", wilaya: "Mascara", wilaya_id: "29", id: "776" },
  { nom: "Aïn Frass", wilaya: "Mascara", wilaya_id: "29", id: "777" },
  { nom: "Benian", wilaya: "Mascara", wilaya_id: "29", id: "778" },
  { nom: "Bou Henni", wilaya: "Mascara", wilaya_id: "29", id: "779" },
  { nom: "Bouhanifia", wilaya: "Mascara", wilaya_id: "29", id: "780" },
  { nom: "Chorfa", wilaya: "Mascara", wilaya_id: "29", id: "781" },
  { nom: "El Alaimia", wilaya: "Mascara", wilaya_id: "29", id: "782" },
  { nom: "El Bordj", wilaya: "Mascara", wilaya_id: "29", id: "783" },
  { nom: "El Gaada", wilaya: "Mascara", wilaya_id: "29", id: "784" },
  { nom: "El Ghomri", wilaya: "Mascara", wilaya_id: "29", id: "785" },
  { nom: "El Gueithna", wilaya: "Mascara", wilaya_id: "29", id: "786" },
  { nom: "El Keurt", wilaya: "Mascara", wilaya_id: "29", id: "787" },
  { nom: "El Menaouer", wilaya: "Mascara", wilaya_id: "29", id: "788" },
  { nom: "Ferraguig", wilaya: "Mascara", wilaya_id: "29", id: "789" },
  { nom: "Froha", wilaya: "Mascara", wilaya_id: "29", id: "790" },
  { nom: "Gharouss", wilaya: "Mascara", wilaya_id: "29", id: "791" },
  { nom: "Ghriss", wilaya: "Mascara", wilaya_id: "29", id: "792" },
  { nom: "Guerdjoum", wilaya: "Mascara", wilaya_id: "29", id: "793" },
  { nom: "Hacine", wilaya: "Mascara", wilaya_id: "29", id: "794" },
  { nom: "Khalouia", wilaya: "Mascara", wilaya_id: "29", id: "795" },
  { nom: "Makdha", wilaya: "Mascara", wilaya_id: "29", id: "796" },
  { nom: "Mamounia", wilaya: "Mascara", wilaya_id: "29", id: "797" },
  { nom: "Maoussa", wilaya: "Mascara", wilaya_id: "29", id: "798" },
  { nom: "Mascara", wilaya: "Mascara", wilaya_id: "29", id: "799" },
  { nom: "Matemore", wilaya: "Mascara", wilaya_id: "29", id: "800" },
  { nom: "Mocta Douz", wilaya: "Mascara", wilaya_id: "29", id: "801" },
  { nom: "Mohammedia", wilaya: "Mascara", wilaya_id: "29", id: "802" },
  { nom: "Nesmoth", wilaya: "Mascara", wilaya_id: "29", id: "803" },
  { nom: "Oggaz", wilaya: "Mascara", wilaya_id: "29", id: "804" },
  { nom: "Oued El Abtal", wilaya: "Mascara", wilaya_id: "29", id: "805" },
  { nom: "Oued Taria", wilaya: "Mascara", wilaya_id: "29", id: "806" },
  { nom: "Rachem", wilaya: "Mascara", wilaya_id: "29", id: "807" },
  { nom: "Ras Aïn Amirouche", wilaya: "Mascara", wilaya_id: "29", id: "808" },
  { nom: "Sedjerara", wilaya: "Mascara", wilaya_id: "29", id: "809" },
  { nom: "Sehailia", wilaya: "Mascara", wilaya_id: "29", id: "810" },
  { nom: "Sidi Abdeldjebar", wilaya: "Mascara", wilaya_id: "29", id: "811" },
  { nom: "Sidi Abdelmoumène", wilaya: "Mascara", wilaya_id: "29", id: "812" },
  { nom: "Sidi Boussaïd", wilaya: "Mascara", wilaya_id: "29", id: "813" },
  { nom: "Sidi Kada", wilaya: "Mascara", wilaya_id: "29", id: "814" },
  { nom: "Sig", wilaya: "Mascara", wilaya_id: "29", id: "815" },
  { nom: "Tighenif", wilaya: "Mascara", wilaya_id: "29", id: "816" },
  { nom: "Tizi", wilaya: "Mascara", wilaya_id: "29", id: "817" },
  { nom: "Zahana", wilaya: "Mascara", wilaya_id: "29", id: "818" },
  { nom: "Zelamta", wilaya: "Mascara", wilaya_id: "29", id: "819" },
  { nom: "Aziz", wilaya: "Medea", wilaya_id: "26", id: "820" },
  { nom: "Aïn Boucif", wilaya: "Medea", wilaya_id: "26", id: "821" },
  { nom: "Aïn Ouksir", wilaya: "Medea", wilaya_id: "26", id: "822" },
  { nom: "Baata", wilaya: "Medea", wilaya_id: "26", id: "823" },
  { nom: "Benchicao", wilaya: "Medea", wilaya_id: "26", id: "824" },
  { nom: "Beni Slimane", wilaya: "Medea", wilaya_id: "26", id: "825" },
  { nom: "Berrouaghia", wilaya: "Medea", wilaya_id: "26", id: "826" },
  { nom: "Bir Ben Abed", wilaya: "Medea", wilaya_id: "26", id: "827" },
  { nom: "Boghar", wilaya: "Medea", wilaya_id: "26", id: "828" },
  { nom: "Bouaiche", wilaya: "Medea", wilaya_id: "26", id: "829" },
  { nom: "Bouaichoune", wilaya: "Medea", wilaya_id: "26", id: "830" },
  { nom: "Bouchrahil", wilaya: "Medea", wilaya_id: "26", id: "831" },
  { nom: "Boughezoul", wilaya: "Medea", wilaya_id: "26", id: "832" },
  { nom: "Bousekene", wilaya: "Medea", wilaya_id: "26", id: "833" },
  { nom: "Chahbounia", wilaya: "Medea", wilaya_id: "26", id: "834" },
  { nom: "Chellalat El Adhaoura", wilaya: "Medea", wilaya_id: "26", id: "835" },
  { nom: "Cheniguel", wilaya: "Medea", wilaya_id: "26", id: "836" },
  { nom: "Derrag", wilaya: "Medea", wilaya_id: "26", id: "837" },
  { nom: "Deux Bassins", wilaya: "Medea", wilaya_id: "26", id: "838" },
  { nom: "Djouab", wilaya: "Medea", wilaya_id: "26", id: "839" },
  { nom: "Draa Essamar", wilaya: "Medea", wilaya_id: "26", id: "840" },
  { nom: "El Aouinet", wilaya: "Medea", wilaya_id: "26", id: "841" },
  { nom: "El Azizia", wilaya: "Medea", wilaya_id: "26", id: "842" },
  { nom: "El Aïssaouia", wilaya: "Medea", wilaya_id: "26", id: "843" },
  { nom: "El Hamdania", wilaya: "Medea", wilaya_id: "26", id: "844" },
  { nom: "El Omaria", wilaya: "Medea", wilaya_id: "26", id: "845" },
  { nom: "Guelb El Kebir", wilaya: "Medea", wilaya_id: "26", id: "846" },
  { nom: "Hanacha", wilaya: "Medea", wilaya_id: "26", id: "847" },
  { nom: "Kef Lakhdar", wilaya: "Medea", wilaya_id: "26", id: "848" },
  { nom: "Khams Djouamaa", wilaya: "Medea", wilaya_id: "26", id: "849" },
  { nom: "Ksar El Boukhari", wilaya: "Medea", wilaya_id: "26", id: "850" },
  { nom: "Maghraoua", wilaya: "Medea", wilaya_id: "26", id: "851" },
  { nom: "Medjebeur", wilaya: "Medea", wilaya_id: "26", id: "852" },
  { nom: "Meftaha", wilaya: "Medea", wilaya_id: "26", id: "853" },
  { nom: "Mezghana", wilaya: "Medea", wilaya_id: "26", id: "854" },
  { nom: "Mihoub", wilaya: "Medea", wilaya_id: "26", id: "855" },
  { nom: "Médéa", wilaya: "Medea", wilaya_id: "26", id: "856" },
  { nom: "Ouamri", wilaya: "Medea", wilaya_id: "26", id: "857" },
  { nom: "Oued Harbil", wilaya: "Medea", wilaya_id: "26", id: "858" },
  { nom: "Ouled Antar", wilaya: "Medea", wilaya_id: "26", id: "859" },
  { nom: "Ouled Bouachra", wilaya: "Medea", wilaya_id: "26", id: "860" },
  { nom: "Ouled Brahim", wilaya: "Medea", wilaya_id: "26", id: "861" },
  { nom: "Ouled Deide", wilaya: "Medea", wilaya_id: "26", id: "862" },
  { nom: "Ouled Hellal", wilaya: "Medea", wilaya_id: "26", id: "863" },
  { nom: "Ouled Maref", wilaya: "Medea", wilaya_id: "26", id: "864" },
  { nom: "Oum El Djallil", wilaya: "Medea", wilaya_id: "26", id: "865" },
  { nom: "Ouzera", wilaya: "Medea", wilaya_id: "26", id: "866" },
  { nom: "Rebaia", wilaya: "Medea", wilaya_id: "26", id: "867" },
  { nom: "Saneg", wilaya: "Medea", wilaya_id: "26", id: "868" },
  { nom: "Sedraya", wilaya: "Medea", wilaya_id: "26", id: "869" },
  { nom: "Seghouane", wilaya: "Medea", wilaya_id: "26", id: "870" },
  { nom: "Si Mahdjoub", wilaya: "Medea", wilaya_id: "26", id: "871" },
  { nom: "Sidi Damed", wilaya: "Medea", wilaya_id: "26", id: "872" },
  { nom: "Sidi Naamane", wilaya: "Medea", wilaya_id: "26", id: "873" },
  { nom: "Sidi Rabie", wilaya: "Medea", wilaya_id: "26", id: "874" },
  { nom: "Sidi Zahar", wilaya: "Medea", wilaya_id: "26", id: "875" },
  { nom: "Sidi Ziane", wilaya: "Medea", wilaya_id: "26", id: "876" },
  { nom: "Souaghi", wilaya: "Medea", wilaya_id: "26", id: "877" },
  { nom: "Tablat", wilaya: "Medea", wilaya_id: "26", id: "878" },
  { nom: "Tafraout", wilaya: "Medea", wilaya_id: "26", id: "879" },
  { nom: "Tamesguida", wilaya: "Medea", wilaya_id: "26", id: "880" },
  { nom: "Tizi Mahdi", wilaya: "Medea", wilaya_id: "26", id: "881" },
  { nom: "Tlelat Ed Douair", wilaya: "Medea", wilaya_id: "26", id: "882" },
  { nom: "Zoubiria", wilaya: "Medea", wilaya_id: "26", id: "883" },
  { nom: "Ahmed Rachedi", wilaya: "Mila", wilaya_id: "43", id: "884" },
  { nom: "Amira Arres", wilaya: "Mila", wilaya_id: "43", id: "885" },
  { nom: "Aïn Beïda Harriche", wilaya: "Mila", wilaya_id: "43", id: "886" },
  { nom: "Aïn Mellouk", wilaya: "Mila", wilaya_id: "43", id: "887" },
  { nom: "Aïn Tine", wilaya: "Mila", wilaya_id: "43", id: "888" },
  { nom: "Benyahia Abderrahmane", wilaya: "Mila", wilaya_id: "43", id: "889" },
  { nom: "Bouhatem", wilaya: "Mila", wilaya_id: "43", id: "890" },
  { nom: "Chelghoum Laïd", wilaya: "Mila", wilaya_id: "43", id: "891" },
  { nom: "Chigara", wilaya: "Mila", wilaya_id: "43", id: "892" },
  { nom: "Derradji Bousselah", wilaya: "Mila", wilaya_id: "43", id: "893" },
  { nom: "El Ayadi Barbès", wilaya: "Mila", wilaya_id: "43", id: "894" },
  { nom: "El Mechira", wilaya: "Mila", wilaya_id: "43", id: "895" },
  { nom: "Ferdjioua", wilaya: "Mila", wilaya_id: "43", id: "896" },
  { nom: "Grarem Gouga", wilaya: "Mila", wilaya_id: "43", id: "897" },
  { nom: "Hamala", wilaya: "Mila", wilaya_id: "43", id: "898" },
  { nom: "Mila", wilaya: "Mila", wilaya_id: "43", id: "899" },
  { nom: "Minar Zarza", wilaya: "Mila", wilaya_id: "43", id: "900" },
  { nom: "Oued Athmania", wilaya: "Mila", wilaya_id: "43", id: "901" },
  { nom: "Oued Endja", wilaya: "Mila", wilaya_id: "43", id: "902" },
  { nom: "Oued Seguen", wilaya: "Mila", wilaya_id: "43", id: "903" },
  { nom: "Ouled Khelouf", wilaya: "Mila", wilaya_id: "43", id: "904" },
  { nom: "Rouached", wilaya: "Mila", wilaya_id: "43", id: "905" },
  { nom: "Sidi Khelifa", wilaya: "Mila", wilaya_id: "43", id: "906" },
  { nom: "Sidi Merouane", wilaya: "Mila", wilaya_id: "43", id: "907" },
  { nom: "Tadjenanet", wilaya: "Mila", wilaya_id: "43", id: "908" },
  { nom: "Tassadane Haddada", wilaya: "Mila", wilaya_id: "43", id: "909" },
  { nom: "Teleghma", wilaya: "Mila", wilaya_id: "43", id: "910" },
  { nom: "Terrai Baïnem", wilaya: "Mila", wilaya_id: "43", id: "911" },
  { nom: "Tessala Lamtai", wilaya: "Mila", wilaya_id: "43", id: "912" },
  { nom: "Tiberguent", wilaya: "Mila", wilaya_id: "43", id: "913" },
  { nom: "Yahia Beni Guecha", wilaya: "Mila", wilaya_id: "43", id: "914" },
  { nom: "Zeghaïa", wilaya: "Mila", wilaya_id: "43", id: "915" },
  { nom: "Achacha", wilaya: "Mostaganem", wilaya_id: "27", id: "916" },
  { nom: "Aïn Boudinar", wilaya: "Mostaganem", wilaya_id: "27", id: "917" },
  { nom: "Aïn Nouicy", wilaya: "Mostaganem", wilaya_id: "27", id: "918" },
  { nom: "Aïn Sidi Chrif", wilaya: "Mostaganem", wilaya_id: "27", id: "919" },
  { nom: "Aïn Tédeles", wilaya: "Mostaganem", wilaya_id: "27", id: "920" },
  {
    nom: "Benabdelmalek Ramdane",
    wilaya: "Mostaganem",
    wilaya_id: "27",
    id: "921",
  },
  { nom: "Bouguirat", wilaya: "Mostaganem", wilaya_id: "27", id: "922" },
  { nom: "El Hassaine", wilaya: "Mostaganem", wilaya_id: "27", id: "923" },
  { nom: "Fornaka", wilaya: "Mostaganem", wilaya_id: "27", id: "924" },
  { nom: "Hadjadj", wilaya: "Mostaganem", wilaya_id: "27", id: "925" },
  { nom: "Hassi Mameche", wilaya: "Mostaganem", wilaya_id: "27", id: "926" },
  { nom: "Khadra", wilaya: "Mostaganem", wilaya_id: "27", id: "927" },
  { nom: "Kheir Eddine", wilaya: "Mostaganem", wilaya_id: "27", id: "928" },
  { nom: "Mansoura", wilaya: "Mostaganem", wilaya_id: "27", id: "929" },
  { nom: "Masra", wilaya: "Mostaganem", wilaya_id: "27", id: "930" },
  { nom: "Mezeghrane", wilaya: "Mostaganem", wilaya_id: "27", id: "931" },
  { nom: "Mostaganem", wilaya: "Mostaganem", wilaya_id: "27", id: "932" },
  { nom: "Nekmaria", wilaya: "Mostaganem", wilaya_id: "27", id: "933" },
  { nom: "Oued El Kheir", wilaya: "Mostaganem", wilaya_id: "27", id: "934" },
  { nom: "Ouled Boughalem", wilaya: "Mostaganem", wilaya_id: "27", id: "935" },
  { nom: "Ouled Maalah", wilaya: "Mostaganem", wilaya_id: "27", id: "936" },
  { nom: "Saf Saf", wilaya: "Mostaganem", wilaya_id: "27", id: "937" },
  { nom: "Sayada", wilaya: "Mostaganem", wilaya_id: "27", id: "938" },
  { nom: "Sidi Ali", wilaya: "Mostaganem", wilaya_id: "27", id: "939" },
  { nom: "Sidi Bellatar", wilaya: "Mostaganem", wilaya_id: "27", id: "940" },
  { nom: "Sidi Lakhdar", wilaya: "Mostaganem", wilaya_id: "27", id: "941" },
  { nom: "Sirat", wilaya: "Mostaganem", wilaya_id: "27", id: "942" },
  { nom: "Souaflia", wilaya: "Mostaganem", wilaya_id: "27", id: "943" },
  { nom: "Sour", wilaya: "Mostaganem", wilaya_id: "27", id: "944" },
  { nom: "Stidia", wilaya: "Mostaganem", wilaya_id: "27", id: "945" },
  { nom: "Tazgait", wilaya: "Mostaganem", wilaya_id: "27", id: "946" },
  { nom: "Touahria", wilaya: "Mostaganem", wilaya_id: "27", id: "947" },
  { nom: "Asla", wilaya: "Naama", wilaya_id: "45", id: "948" },
  { nom: "Aïn Ben Khellil", wilaya: "Naama", wilaya_id: "45", id: "949" },
  { nom: "Aïn Sefra", wilaya: "Naama", wilaya_id: "45", id: "950" },
  { nom: "Djeniene Bourezg", wilaya: "Naama", wilaya_id: "45", id: "951" },
  { nom: "El Biodh", wilaya: "Naama", wilaya_id: "45", id: "952" },
  { nom: "Kasdir", wilaya: "Naama", wilaya_id: "45", id: "953" },
  { nom: "Makmen Ben Amar", wilaya: "Naama", wilaya_id: "45", id: "954" },
  { nom: "Mecheria", wilaya: "Naama", wilaya_id: "45", id: "955" },
  { nom: "Moghrar", wilaya: "Naama", wilaya_id: "45", id: "956" },
  { nom: "Naama", wilaya: "Naama", wilaya_id: "45", id: "957" },
  { nom: "Sfissifa", wilaya: "Naama", wilaya_id: "45", id: "958" },
  { nom: "Tiout", wilaya: "Naama", wilaya_id: "45", id: "959" },
  { nom: "Arzew", wilaya: "Oran", wilaya_id: "31", id: "960" },
  { nom: "Aïn Biya", wilaya: "Oran", wilaya_id: "31", id: "961" },
  { nom: "Aïn El Kerma", wilaya: "Oran", wilaya_id: "31", id: "962" },
  { nom: "Aïn Turk", wilaya: "Oran", wilaya_id: "31", id: "963" },
  { nom: "Ben Freha", wilaya: "Oran", wilaya_id: "31", id: "964" },
  { nom: "Bethioua", wilaya: "Oran", wilaya_id: "31", id: "965" },
  { nom: "Bir El Djir", wilaya: "Oran", wilaya_id: "31", id: "966" },
  { nom: "Boufatis", wilaya: "Oran", wilaya_id: "31", id: "967" },
  { nom: "Bousfer", wilaya: "Oran", wilaya_id: "31", id: "968" },
  { nom: "Boutelelis", wilaya: "Oran", wilaya_id: "31", id: "969" },
  { nom: "El Ançar", wilaya: "Oran", wilaya_id: "31", id: "970" },
  { nom: "El Braya", wilaya: "Oran", wilaya_id: "31", id: "971" },
  { nom: "El Kerma", wilaya: "Oran", wilaya_id: "31", id: "972" },
  { nom: "Es Senia", wilaya: "Oran", wilaya_id: "31", id: "973" },
  { nom: "Gdyel", wilaya: "Oran", wilaya_id: "31", id: "974" },
  { nom: "Hassi Benokba", wilaya: "Oran", wilaya_id: "31", id: "975" },
  { nom: "Hassi Bounif", wilaya: "Oran", wilaya_id: "31", id: "976" },
  { nom: "Hassi Mefsoukh", wilaya: "Oran", wilaya_id: "31", id: "977" },
  { nom: "Mers El Kebir", wilaya: "Oran", wilaya_id: "31", id: "978" },
  { nom: "Mersa El Hadjadj", wilaya: "Oran", wilaya_id: "31", id: "979" },
  { nom: "Misserghin", wilaya: "Oran", wilaya_id: "31", id: "980" },
  { nom: "Oran", wilaya: "Oran", wilaya_id: "31", id: "981" },
  { nom: "Oued Tlelat", wilaya: "Oran", wilaya_id: "31", id: "982" },
  { nom: "Sidi Benyebka", wilaya: "Oran", wilaya_id: "31", id: "983" },
  { nom: "Sidi Chahmi", wilaya: "Oran", wilaya_id: "31", id: "984" },
  { nom: "Tafraoui", wilaya: "Oran", wilaya_id: "31", id: "985" },
  { nom: "Aïn Beida", wilaya: "Ouargla", wilaya_id: "30", id: "986" },
  { nom: "Bennaceur", wilaya: "Ouargla", wilaya_id: "30", id: "987" },
  { nom: "Blidate Ameur", wilaya: "Ouargla", wilaya_id: "30", id: "988" },
  { nom: "El Alia", wilaya: "Ouargla", wilaya_id: "30", id: "989" },
  { nom: "El Borma", wilaya: "Ouargla", wilaya_id: "30", id: "990" },
  { nom: "El Hadjira", wilaya: "Ouargla", wilaya_id: "30", id: "991" },
  { nom: "Hassi Ben Abdellah", wilaya: "Ouargla", wilaya_id: "30", id: "992" },
  { nom: "Hassi Messaoud", wilaya: "Ouargla", wilaya_id: "30", id: "993" },
  { nom: "Megarine", wilaya: "Ouargla", wilaya_id: "30", id: "994" },
  { nom: "Nezla", wilaya: "Ouargla", wilaya_id: "30", id: "995" },
  { nom: "Ouargla", wilaya: "Ouargla", wilaya_id: "30", id: "996" },
  { nom: "Ruissat", wilaya: "Ouargla", wilaya_id: "30", id: "997" },
  { nom: "Sidi Khouiled", wilaya: "Ouargla", wilaya_id: "30", id: "998" },
  { nom: "Sidi Slimane", wilaya: "Ouargla", wilaya_id: "30", id: "999" },
  { nom: "Taïbet", wilaya: "Ouargla", wilaya_id: "30", id: "1,000" },
  { nom: "Tebesbest", wilaya: "Ouargla", wilaya_id: "30", id: "1,001" },
  { nom: "Temacine", wilaya: "Ouargla", wilaya_id: "30", id: "1,002" },
  { nom: "Touggourt", wilaya: "Ouargla", wilaya_id: "30", id: "1,003" },
  { nom: "Zaouia El Abidia", wilaya: "Ouargla", wilaya_id: "30", id: "1,004" },
  {
    nom: "Aïn Babouche",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,005",
  },
  { nom: "Aïn Beida", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,006" },
  { nom: "Aïn Diss", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,007" },
  { nom: "Aïn Fakroun", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,008" },
  { nom: "Aïn Kercha", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,009" },
  { nom: "Aïn Zitoun", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,010" },
  {
    nom: "Behir Chergui",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,011",
  },
  { nom: "Berriche", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,012" },
  {
    nom: "Bir Chouhada",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,013",
  },
  { nom: "Dhalaa", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,014" },
  { nom: "Djazia", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,015" },
  { nom: "El Amiria", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,016" },
  { nom: "El Ballala", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,017" },
  {
    nom: "El Fedjoudj Boughrara  Saoudi",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,018",
  },
  { nom: "El Harmilia", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,019" },
  {
    nom: "Hanchir Toumghani",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,020",
  },
  { nom: "Ksar Sbahi", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,021" },
  { nom: "Meskiana", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,022" },
  { nom: "Oued Nini", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,023" },
  { nom: "Ouled Gacem", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,024" },
  { nom: "Ouled Hamla", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,025" },
  { nom: "Ouled Zaoui", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,026" },
  {
    nom: "Oum El Bouaghi",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,027",
  },
  { nom: "Rahia", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,028" },
  { nom: "Sigus", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,029" },
  {
    nom: "Souk Naamane",
    wilaya: "Oum El Bouaghi",
    wilaya_id: "4",
    id: "1,030",
  },
  { nom: "Zorg", wilaya: "Oum El Bouaghi", wilaya_id: "4", id: "1,031" },
  { nom: "Ammi Moussa", wilaya: "Relizane", wilaya_id: "48", id: "1,032" },
  { nom: "Aïn Rahma", wilaya: "Relizane", wilaya_id: "48", id: "1,033" },
  { nom: "Aïn Tarek", wilaya: "Relizane", wilaya_id: "48", id: "1,034" },
  {
    nom: "Belaassel Bouzegza",
    wilaya: "Relizane",
    wilaya_id: "48",
    id: "1,035",
  },
  { nom: "Bendaoud", wilaya: "Relizane", wilaya_id: "48", id: "1,036" },
  { nom: "Beni Dergoune", wilaya: "Relizane", wilaya_id: "48", id: "1,037" },
  { nom: "Beni Zentis", wilaya: "Relizane", wilaya_id: "48", id: "1,038" },
  { nom: "Dar Ben Abdellah", wilaya: "Relizane", wilaya_id: "48", id: "1,039" },
  { nom: "Djidiouia", wilaya: "Relizane", wilaya_id: "48", id: "1,040" },
  { nom: "El Guettar", wilaya: "Relizane", wilaya_id: "48", id: "1,041" },
  { nom: "El Hamri", wilaya: "Relizane", wilaya_id: "48", id: "1,042" },
  { nom: "El Hassi", wilaya: "Relizane", wilaya_id: "48", id: "1,043" },
  { nom: "El Matmar", wilaya: "Relizane", wilaya_id: "48", id: "1,044" },
  {
    nom: "El Merdja Sidi Abed",
    wilaya: "Relizane",
    wilaya_id: "48",
    id: "1,045",
  },
  { nom: "El Ouldja", wilaya: "Relizane", wilaya_id: "48", id: "1,046" },
  { nom: "Had Echkala", wilaya: "Relizane", wilaya_id: "48", id: "1,047" },
  { nom: "Kalaa", wilaya: "Relizane", wilaya_id: "48", id: "1,048" },
  { nom: "Lahlef", wilaya: "Relizane", wilaya_id: "48", id: "1,049" },
  { nom: "Mazouna", wilaya: "Relizane", wilaya_id: "48", id: "1,050" },
  { nom: "Mediouna", wilaya: "Relizane", wilaya_id: "48", id: "1,051" },
  { nom: "Mendes", wilaya: "Relizane", wilaya_id: "48", id: "1,052" },
  { nom: "Oued Djemaa", wilaya: "Relizane", wilaya_id: "48", id: "1,053" },
  { nom: "Oued Essalem", wilaya: "Relizane", wilaya_id: "48", id: "1,054" },
  { nom: "Oued Rhiou", wilaya: "Relizane", wilaya_id: "48", id: "1,055" },
  { nom: "Ouerizène", wilaya: "Relizane", wilaya_id: "48", id: "1,056" },
  { nom: "Ouled Aïch", wilaya: "Relizane", wilaya_id: "48", id: "1,057" },
  {
    nom: "Ouled Sidi Mihoub",
    wilaya: "Relizane",
    wilaya_id: "48",
    id: "1,058",
  },
  { nom: "Ramka", wilaya: "Relizane", wilaya_id: "48", id: "1,059" },
  { nom: "Relizane", wilaya: "Relizane", wilaya_id: "48", id: "1,060" },
  { nom: "Sidi Khetab", wilaya: "Relizane", wilaya_id: "48", id: "1,061" },
  { nom: "Sidi Lazreg", wilaya: "Relizane", wilaya_id: "48", id: "1,062" },
  { nom: "Sidi Saada", wilaya: "Relizane", wilaya_id: "48", id: "1,063" },
  { nom: "Souk El Had", wilaya: "Relizane", wilaya_id: "48", id: "1,064" },
  { nom: "Yellel", wilaya: "Relizane", wilaya_id: "48", id: "1,065" },
  { nom: "Zemmoura", wilaya: "Relizane", wilaya_id: "48", id: "1,066" },
  { nom: "Al Hassasna", wilaya: "Saida", wilaya_id: "20", id: "1,067" },
  { nom: "Aïn El Hadjar", wilaya: "Saida", wilaya_id: "20", id: "1,068" },
  { nom: "Aïn Skhouna", wilaya: "Saida", wilaya_id: "20", id: "1,069" },
  { nom: "Aïn Soltane", wilaya: "Saida", wilaya_id: "20", id: "1,070" },
  { nom: "Doui Tabet", wilaya: "Saida", wilaya_id: "20", id: "1,071" },
  { nom: "Hounet", wilaya: "Saida", wilaya_id: "20", id: "1,072" },
  { nom: "Maamora", wilaya: "Saida", wilaya_id: "20", id: "1,073" },
  { nom: "Moulaya Larbi", wilaya: "Saida", wilaya_id: "20", id: "1,074" },
  { nom: "Ouled Brahim", wilaya: "Saida", wilaya_id: "20", id: "1,075" },
  { nom: "Ouled Khaled", wilaya: "Saida", wilaya_id: "20", id: "1,076" },
  { nom: "Saïda", wilaya: "Saida", wilaya_id: "20", id: "1,077" },
  { nom: "Sidi Ahmed", wilaya: "Saida", wilaya_id: "20", id: "1,078" },
  { nom: "Sidi Ammar", wilaya: "Saida", wilaya_id: "20", id: "1,079" },
  { nom: "Sidi Boubekeur", wilaya: "Saida", wilaya_id: "20", id: "1,080" },
  { nom: "Tircine", wilaya: "Saida", wilaya_id: "20", id: "1,081" },
  { nom: "Youb", wilaya: "Saida", wilaya_id: "20", id: "1,082" },
  { nom: "Ain Legradj", wilaya: "Setif", wilaya_id: "19", id: "1,083" },
  { nom: "Amoucha", wilaya: "Setif", wilaya_id: "19", id: "1,084" },
  { nom: "Aïn Abessa", wilaya: "Setif", wilaya_id: "19", id: "1,085" },
  { nom: "Aïn Arnat", wilaya: "Setif", wilaya_id: "19", id: "1,086" },
  { nom: "Aïn Azel", wilaya: "Setif", wilaya_id: "19", id: "1,087" },
  { nom: "Aïn El Kebira", wilaya: "Setif", wilaya_id: "19", id: "1,088" },
  { nom: "Aïn Lahdjar", wilaya: "Setif", wilaya_id: "19", id: "1,089" },
  { nom: "Aïn Oulmène", wilaya: "Setif", wilaya_id: "19", id: "1,090" },
  { nom: "Aïn Roua", wilaya: "Setif", wilaya_id: "19", id: "1,091" },
  { nom: "Aïn Sebt", wilaya: "Setif", wilaya_id: "19", id: "1,092" },
  { nom: "Aït Tizi", wilaya: "Setif", wilaya_id: "19", id: "1,093" },
  { nom: "Babor", wilaya: "Setif", wilaya_id: "19", id: "1,094" },
  { nom: "Bazer Sakra", wilaya: "Setif", wilaya_id: "19", id: "1,095" },
  { nom: "Beida Bordj", wilaya: "Setif", wilaya_id: "19", id: "1,096" },
  { nom: "Belaa", wilaya: "Setif", wilaya_id: "19", id: "1,097" },
  { nom: "Beni Aziz", wilaya: "Setif", wilaya_id: "19", id: "1,098" },
  { nom: "Beni Chebana", wilaya: "Setif", wilaya_id: "19", id: "1,099" },
  { nom: "Beni Foudda", wilaya: "Setif", wilaya_id: "19", id: "1,100" },
  { nom: "Beni Mouhli", wilaya: "Setif", wilaya_id: "19", id: "1,101" },
  { nom: "Beni Ourtilane", wilaya: "Setif", wilaya_id: "19", id: "1,102" },
  { nom: "Beni Oussine", wilaya: "Setif", wilaya_id: "19", id: "1,103" },
  { nom: "Bir El Arch", wilaya: "Setif", wilaya_id: "19", id: "1,104" },
  { nom: "Bir Heddada", wilaya: "Setif", wilaya_id: "19", id: "1,105" },
  { nom: "Bouandas", wilaya: "Setif", wilaya_id: "19", id: "1,106" },
  { nom: "Bougaa", wilaya: "Setif", wilaya_id: "19", id: "1,107" },
  { nom: "Bousselam", wilaya: "Setif", wilaya_id: "19", id: "1,108" },
  { nom: "Boutaleb", wilaya: "Setif", wilaya_id: "19", id: "1,109" },
  { nom: "Dehamcha", wilaya: "Setif", wilaya_id: "19", id: "1,110" },
  { nom: "Djemila", wilaya: "Setif", wilaya_id: "19", id: "1,111" },
  { nom: "Draa Kebila", wilaya: "Setif", wilaya_id: "19", id: "1,112" },
  { nom: "El Eulma", wilaya: "Setif", wilaya_id: "19", id: "1,113" },
  { nom: "El Ouldja", wilaya: "Setif", wilaya_id: "19", id: "1,114" },
  { nom: "El Ouricia", wilaya: "Setif", wilaya_id: "19", id: "1,115" },
  { nom: "Guellal", wilaya: "Setif", wilaya_id: "19", id: "1,116" },
  { nom: "Guelta Zerga", wilaya: "Setif", wilaya_id: "19", id: "1,117" },
  { nom: "Guenzet", wilaya: "Setif", wilaya_id: "19", id: "1,118" },
  { nom: "Guijel", wilaya: "Setif", wilaya_id: "19", id: "1,119" },
  { nom: "Hamma", wilaya: "Setif", wilaya_id: "19", id: "1,120" },
  { nom: "Hammam Guergour", wilaya: "Setif", wilaya_id: "19", id: "1,121" },
  { nom: "Hammam Sokhna", wilaya: "Setif", wilaya_id: "19", id: "1,122" },
  { nom: "Harbil", wilaya: "Setif", wilaya_id: "19", id: "1,123" },
  { nom: "Kasr El Abtal", wilaya: "Setif", wilaya_id: "19", id: "1,124" },
  { nom: "Maaouia", wilaya: "Setif", wilaya_id: "19", id: "1,125" },
  { nom: "Maoklane", wilaya: "Setif", wilaya_id: "19", id: "1,126" },
  { nom: "Mezloug", wilaya: "Setif", wilaya_id: "19", id: "1,127" },
  { nom: "Oued Bared", wilaya: "Setif", wilaya_id: "19", id: "1,128" },
  { nom: "Ouled Addouane", wilaya: "Setif", wilaya_id: "19", id: "1,129" },
  { nom: "Ouled Sabor", wilaya: "Setif", wilaya_id: "19", id: "1,130" },
  { nom: "Ouled Si Ahmed", wilaya: "Setif", wilaya_id: "19", id: "1,131" },
  { nom: "Ouled Tebben", wilaya: "Setif", wilaya_id: "19", id: "1,132" },
  { nom: "Rasfa", wilaya: "Setif", wilaya_id: "19", id: "1,133" },
  { nom: "Salah Bey", wilaya: "Setif", wilaya_id: "19", id: "1,134" },
  { nom: "Serdj El Ghoul", wilaya: "Setif", wilaya_id: "19", id: "1,135" },
  { nom: "Sétif", wilaya: "Setif", wilaya_id: "19", id: "1,136" },
  { nom: "Tachouda", wilaya: "Setif", wilaya_id: "19", id: "1,137" },
  { nom: "Tala Ifacène", wilaya: "Setif", wilaya_id: "19", id: "1,138" },
  { nom: "Taya", wilaya: "Setif", wilaya_id: "19", id: "1,139" },
  { nom: "Telaa", wilaya: "Setif", wilaya_id: "19", id: "1,140" },
  { nom: "Amarnas", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,141" },
  { nom: "Aïn Adden", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,142" },
  {
    nom: "Aïn El Berd",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,143",
  },
  { nom: "Aïn Kada", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,144" },
  { nom: "Aïn Thrid", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,145" },
  {
    nom: "Aïn Tidamine",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,146",
  },
  {
    nom: "Badradine El Mokrani",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,147",
  },
  { nom: "Belarbi", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,148" },
  { nom: "Ben Badis", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,149" },
  {
    nom: "Benachiba Chelia",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,150",
  },
  {
    nom: "Boudjebaa El Bordj",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,151",
  },
  {
    nom: "Boukhenefis",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,152",
  },
  {
    nom: "Chetouane Belaila",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,153",
  },
  { nom: "Dhaya", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,154" },
  { nom: "Hassi Daho", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,155" },
  {
    nom: "Hassi Zehana",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,156",
  },
  { nom: "Haçaiba", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,157" },
  { nom: "Lemtar", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,158" },
  { nom: "Makedra", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,159" },
  { nom: "Marhoum", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,160" },
  { nom: "Merine", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,161" },
  { nom: "Mezaourou", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,162" },
  {
    nom: "Mostefa Ben Brahim",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,163",
  },
  {
    nom: "Moulay Slissen",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,164",
  },
  { nom: "Oued Sebaa", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,165" },
  {
    nom: "Oued Sefioune",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,166",
  },
  {
    nom: "Oued Taourira",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,167",
  },
  { nom: "Ras El Ma", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,168" },
  {
    nom: "Redjem Demouche",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,169",
  },
  {
    nom: "Sehala Thaoura",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,170",
  },
  { nom: "Sfisef", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,171" },
  {
    nom: "Sidi Ali Ben Youb",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,172",
  },
  {
    nom: "Sidi Ali Boussidi",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,173",
  },
  {
    nom: "Sidi Bel Abbes",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,174",
  },
  {
    nom: "Sidi Brahim",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,175",
  },
  { nom: "Sidi Chaïb", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,176" },
  {
    nom: "Sidi Dahou Dezairs",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,177",
  },
  {
    nom: "Sidi Hamadouche",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,178",
  },
  {
    nom: "Sidi Khaled",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,179",
  },
  {
    nom: "Sidi Lahcène",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,180",
  },
  {
    nom: "Sidi Yacoub",
    wilaya: "Sidi Bel Abbes",
    wilaya_id: "22",
    id: "1,181",
  },
  { nom: "Tabia", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,182" },
  { nom: "Tafessour", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,183" },
  { nom: "Taoudmout", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,184" },
  { nom: "Teghelimet", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,185" },
  { nom: "Telagh", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,186" },
  { nom: "Tenira", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,187" },
  { nom: "Tessala", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,188" },
  { nom: "Tilmouni", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,189" },
  { nom: "Zerrouala", wilaya: "Sidi Bel Abbes", wilaya_id: "22", id: "1,190" },
  { nom: "Ain Zouit", wilaya: "Skikda", wilaya_id: "21", id: "1,191" },
  { nom: "Azzaba", wilaya: "Skikda", wilaya_id: "21", id: "1,192" },
  { nom: "Aïn Bouziane", wilaya: "Skikda", wilaya_id: "21", id: "1,193" },
  { nom: "Aïn Cherchar", wilaya: "Skikda", wilaya_id: "21", id: "1,194" },
  { nom: "Aïn Kechera", wilaya: "Skikda", wilaya_id: "21", id: "1,195" },
  { nom: "Bein El Ouiden", wilaya: "Skikda", wilaya_id: "21", id: "1,196" },
  { nom: "Bekkouche Lakhdar", wilaya: "Skikda", wilaya_id: "21", id: "1,197" },
  { nom: "Benazouz", wilaya: "Skikda", wilaya_id: "21", id: "1,198" },
  { nom: "Beni Bachir", wilaya: "Skikda", wilaya_id: "21", id: "1,199" },
  { nom: "Beni Oulbane", wilaya: "Skikda", wilaya_id: "21", id: "1,200" },
  { nom: "Beni Zid", wilaya: "Skikda", wilaya_id: "21", id: "1,201" },
  { nom: "Bouchtata", wilaya: "Skikda", wilaya_id: "21", id: "1,202" },
  { nom: "Cheraia", wilaya: "Skikda", wilaya_id: "21", id: "1,203" },
  { nom: "Collo", wilaya: "Skikda", wilaya_id: "21", id: "1,204" },
  {
    nom: "Djendel Saadi Mohamed",
    wilaya: "Skikda",
    wilaya_id: "21",
    id: "1,205",
  },
  { nom: "El Ghedir", wilaya: "Skikda", wilaya_id: "21", id: "1,206" },
  { nom: "El Hadaiek", wilaya: "Skikda", wilaya_id: "21", id: "1,207" },
  { nom: "El Harrouch", wilaya: "Skikda", wilaya_id: "21", id: "1,208" },
  { nom: "El Marsa", wilaya: "Skikda", wilaya_id: "21", id: "1,209" },
  { nom: "Emdjez Edchich", wilaya: "Skikda", wilaya_id: "21", id: "1,210" },
  { nom: "Essebt", wilaya: "Skikda", wilaya_id: "21", id: "1,211" },
  { nom: "Filfila", wilaya: "Skikda", wilaya_id: "21", id: "1,212" },
  { nom: "Hammadi Krouma", wilaya: "Skikda", wilaya_id: "21", id: "1,213" },
  { nom: "Kenoua", wilaya: "Skikda", wilaya_id: "21", id: "1,214" },
  { nom: "Kerkera", wilaya: "Skikda", wilaya_id: "21", id: "1,215" },
  { nom: "Khenag Mayoun", wilaya: "Skikda", wilaya_id: "21", id: "1,216" },
  { nom: "Ouldja Boulballout", wilaya: "Skikda", wilaya_id: "21", id: "1,217" },
  { nom: "Ouled Atia", wilaya: "Skikda", wilaya_id: "21", id: "1,218" },
  { nom: "Ouled Hebaba", wilaya: "Skikda", wilaya_id: "21", id: "1,219" },
  { nom: "Oum Toub", wilaya: "Skikda", wilaya_id: "21", id: "1,220" },
  { nom: "Ramdane Djamel", wilaya: "Skikda", wilaya_id: "21", id: "1,221" },
  { nom: "Salah Bouchaour", wilaya: "Skikda", wilaya_id: "21", id: "1,222" },
  { nom: "Sidi Mezghiche", wilaya: "Skikda", wilaya_id: "21", id: "1,223" },
  { nom: "Skikda", wilaya: "Skikda", wilaya_id: "21", id: "1,224" },
  { nom: "Tamalous", wilaya: "Skikda", wilaya_id: "21", id: "1,225" },
  { nom: "Zardezas", wilaya: "Skikda", wilaya_id: "21", id: "1,226" },
  { nom: "Zitouna", wilaya: "Skikda", wilaya_id: "21", id: "1,227" },
  { nom: "Aïn Soltane", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,228" },
  { nom: "Aïn Zana", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,229" },
  { nom: "Bir Bouhouche", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,230" },
  { nom: "Drea", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,231" },
  { nom: "Haddada", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,232" },
  { nom: "Hannencha", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,233" },
  { nom: "Khedara", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,234" },
  { nom: "Khemissa", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,235" },
  { nom: "Mechroha", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,236" },
  { nom: "Merahna", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,237" },
  { nom: "Oued Kebrit", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,238" },
  { nom: "Ouillen", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,239" },
  { nom: "Ouled Driss", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,240" },
  { nom: "Ouled Moumen", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,241" },
  { nom: "Oum El Adhaim", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,242" },
  { nom: "Ragouba", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,243" },
  { nom: "Safel Ouiden", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,244" },
  { nom: "Sedrata", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,245" },
  { nom: "Sidi Fredj", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,246" },
  { nom: "Souk Ahras", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,247" },
  { nom: "Taoura", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,248" },
  { nom: "Terraguelt", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,249" },
  { nom: "Tiffech", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,250" },
  { nom: "Zaarouria", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,251" },
  { nom: "Zouabi", wilaya: "Souk Ahras", wilaya_id: "41", id: "1,252" },
  {
    nom: "Foggaret  Ezzouaoua",
    wilaya: "Tamanrasset",
    wilaya_id: "11",
    id: "1,253",
  },
  { nom: "Idlès", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,254" },
  { nom: "In Amguel", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,255" },
  { nom: "In Ghar", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,256" },
  { nom: "In Guezzam", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,257" },
  { nom: "In Salah", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,258" },
  {
    nom: "Silet Abalessa",
    wilaya: "Tamanrasset",
    wilaya_id: "11",
    id: "1,259",
  },
  { nom: "Tamanghasset", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,260" },
  { nom: "Tazrouk", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,261" },
  { nom: "Tin Zouatine", wilaya: "Tamanrasset", wilaya_id: "11", id: "1,262" },
  { nom: "Aïn Zerga", wilaya: "Tebessa", wilaya_id: "12", id: "1,263" },
  { nom: "Bedjene", wilaya: "Tebessa", wilaya_id: "12", id: "1,264" },
  { nom: "Bekkaria", wilaya: "Tebessa", wilaya_id: "12", id: "1,265" },
  { nom: "Bir Dheheb", wilaya: "Tebessa", wilaya_id: "12", id: "1,266" },
  { nom: "Bir El Ater", wilaya: "Tebessa", wilaya_id: "12", id: "1,267" },
  { nom: "Bir Mokadem", wilaya: "Tebessa", wilaya_id: "12", id: "1,268" },
  { nom: "Boukhadra", wilaya: "Tebessa", wilaya_id: "12", id: "1,269" },
  { nom: "Boulhef Dyn", wilaya: "Tebessa", wilaya_id: "12", id: "1,270" },
  { nom: "Chéria", wilaya: "Tebessa", wilaya_id: "12", id: "1,271" },
  { nom: "El Aouinet", wilaya: "Tebessa", wilaya_id: "12", id: "1,272" },
  { nom: "El Kouif", wilaya: "Tebessa", wilaya_id: "12", id: "1,273" },
  { nom: "El Ma Labiodh", wilaya: "Tebessa", wilaya_id: "12", id: "1,274" },
  { nom: "El Meridj", wilaya: "Tebessa", wilaya_id: "12", id: "1,275" },
  { nom: "El Mezraa", wilaya: "Tebessa", wilaya_id: "12", id: "1,276" },
  { nom: "El Ogla", wilaya: "Tebessa", wilaya_id: "12", id: "1,277" },
  { nom: "El Ogla El Malha", wilaya: "Tebessa", wilaya_id: "12", id: "1,278" },
  { nom: "Ferkane", wilaya: "Tebessa", wilaya_id: "12", id: "1,279" },
  { nom: "Ghorriguer", wilaya: "Tebessa", wilaya_id: "12", id: "1,280" },
  { nom: "Hammamet", wilaya: "Tebessa", wilaya_id: "12", id: "1,281" },
  { nom: "Lahouidjbet", wilaya: "Tebessa", wilaya_id: "12", id: "1,282" },
  { nom: "Morsott", wilaya: "Tebessa", wilaya_id: "12", id: "1,283" },
  { nom: "Negrine", wilaya: "Tebessa", wilaya_id: "12", id: "1,284" },
  { nom: "Ouenza", wilaya: "Tebessa", wilaya_id: "12", id: "1,285" },
  { nom: "Oum Ali", wilaya: "Tebessa", wilaya_id: "12", id: "1,286" },
  { nom: "Safsaf El Ouesra", wilaya: "Tebessa", wilaya_id: "12", id: "1,287" },
  { nom: "Stah Guentis", wilaya: "Tebessa", wilaya_id: "12", id: "1,288" },
  { nom: "Thilidjène", wilaya: "Tebessa", wilaya_id: "12", id: "1,289" },
  { nom: "Tébessa", wilaya: "Tebessa", wilaya_id: "12", id: "1,290" },
  { nom: "Aïn Bouchekif", wilaya: "Tiaret", wilaya_id: "14", id: "1,291" },
  { nom: "Aïn Dheb", wilaya: "Tiaret", wilaya_id: "14", id: "1,292" },
  { nom: "Aïn Dzarit", wilaya: "Tiaret", wilaya_id: "14", id: "1,293" },
  { nom: "Aïn El Hadid", wilaya: "Tiaret", wilaya_id: "14", id: "1,294" },
  { nom: "Aïn Kermes", wilaya: "Tiaret", wilaya_id: "14", id: "1,295" },
  { nom: "Bougara", wilaya: "Tiaret", wilaya_id: "14", id: "1,296" },
  { nom: "Chehaima", wilaya: "Tiaret", wilaya_id: "14", id: "1,297" },
  { nom: "Dahmouni", wilaya: "Tiaret", wilaya_id: "14", id: "1,298" },
  { nom: "Djebilet Rosfa", wilaya: "Tiaret", wilaya_id: "14", id: "1,299" },
  { nom: "Djillali Ben Amar", wilaya: "Tiaret", wilaya_id: "14", id: "1,300" },
  { nom: "Faidja", wilaya: "Tiaret", wilaya_id: "14", id: "1,301" },
  { nom: "Frenda", wilaya: "Tiaret", wilaya_id: "14", id: "1,302" },
  { nom: "Guertoufa", wilaya: "Tiaret", wilaya_id: "14", id: "1,303" },
  { nom: "Hamadia", wilaya: "Tiaret", wilaya_id: "14", id: "1,304" },
  { nom: "Ksar Chellala", wilaya: "Tiaret", wilaya_id: "14", id: "1,305" },
  { nom: "Madna", wilaya: "Tiaret", wilaya_id: "14", id: "1,306" },
  { nom: "Mahdia", wilaya: "Tiaret", wilaya_id: "14", id: "1,307" },
  { nom: "Mechraa  Sfa", wilaya: "Tiaret", wilaya_id: "14", id: "1,308" },
  { nom: "Medrissa", wilaya: "Tiaret", wilaya_id: "14", id: "1,309" },
  { nom: "Medroussa", wilaya: "Tiaret", wilaya_id: "14", id: "1,310" },
  { nom: "Meghila", wilaya: "Tiaret", wilaya_id: "14", id: "1,311" },
  { nom: "Mellakou", wilaya: "Tiaret", wilaya_id: "14", id: "1,312" },
  { nom: "Nadorah", wilaya: "Tiaret", wilaya_id: "14", id: "1,313" },
  { nom: "Naïma", wilaya: "Tiaret", wilaya_id: "14", id: "1,314" },
  { nom: "Oued Lili", wilaya: "Tiaret", wilaya_id: "14", id: "1,315" },
  { nom: "Racheiga", wilaya: "Tiaret", wilaya_id: "14", id: "1,316" },
  { nom: "Rahouia", wilaya: "Tiaret", wilaya_id: "14", id: "1,317" },
  { nom: "Sebaine", wilaya: "Tiaret", wilaya_id: "14", id: "1,318" },
  { nom: "Sebt", wilaya: "Tiaret", wilaya_id: "14", id: "1,319" },
  { nom: "Serguine", wilaya: "Tiaret", wilaya_id: "14", id: "1,320" },
  { nom: "Si Abdelghani", wilaya: "Tiaret", wilaya_id: "14", id: "1,321" },
  { nom: "Sidi Abderrahmane", wilaya: "Tiaret", wilaya_id: "14", id: "1,322" },
  { nom: "Sidi Ali Mellal", wilaya: "Tiaret", wilaya_id: "14", id: "1,323" },
  { nom: "Sidi Bakhti", wilaya: "Tiaret", wilaya_id: "14", id: "1,324" },
  { nom: "Sidi Hosni", wilaya: "Tiaret", wilaya_id: "14", id: "1,325" },
  { nom: "Sougueur", wilaya: "Tiaret", wilaya_id: "14", id: "1,326" },
  { nom: "Tagdemt", wilaya: "Tiaret", wilaya_id: "14", id: "1,327" },
  { nom: "Takhmaret", wilaya: "Tiaret", wilaya_id: "14", id: "1,328" },
  { nom: "Tiaret", wilaya: "Tiaret", wilaya_id: "14", id: "1,329" },
  { nom: "Tidda", wilaya: "Tiaret", wilaya_id: "14", id: "1,330" },
  { nom: "Tousnina", wilaya: "Tiaret", wilaya_id: "14", id: "1,331" },
  {
    nom: "Zmalet Emir Abdelkader",
    wilaya: "Tiaret",
    wilaya_id: "14",
    id: "1,332",
  },
  { nom: "Oum El Assel", wilaya: "Tindouf", wilaya_id: "37", id: "1,333" },
  { nom: "Tindouf", wilaya: "Tindouf", wilaya_id: "37", id: "1,334" },
  { nom: "Aghbal", wilaya: "Tipaza", wilaya_id: "42", id: "1,335" },
  { nom: "Ahmar El Aïn", wilaya: "Tipaza", wilaya_id: "42", id: "1,336" },
  { nom: "Aïn Tagourait", wilaya: "Tipaza", wilaya_id: "42", id: "1,337" },
  { nom: "Beni Meleuk", wilaya: "Tipaza", wilaya_id: "42", id: "1,338" },
  { nom: "Bou Ismaïl", wilaya: "Tipaza", wilaya_id: "42", id: "1,339" },
  { nom: "Bouharoun", wilaya: "Tipaza", wilaya_id: "42", id: "1,340" },
  { nom: "Bourkika", wilaya: "Tipaza", wilaya_id: "42", id: "1,341" },
  { nom: "Chaiba", wilaya: "Tipaza", wilaya_id: "42", id: "1,342" },
  { nom: "Cherchell", wilaya: "Tipaza", wilaya_id: "42", id: "1,343" },
  { nom: "Damous", wilaya: "Tipaza", wilaya_id: "42", id: "1,344" },
  { nom: "Douaouda", wilaya: "Tipaza", wilaya_id: "42", id: "1,345" },
  { nom: "Fouka", wilaya: "Tipaza", wilaya_id: "42", id: "1,346" },
  { nom: "Gouraya", wilaya: "Tipaza", wilaya_id: "42", id: "1,347" },
  { nom: "Hadjout", wilaya: "Tipaza", wilaya_id: "42", id: "1,348" },
  { nom: "Hadjrat Nouss", wilaya: "Tipaza", wilaya_id: "42", id: "1,349" },
  { nom: "Hattatba", wilaya: "Tipaza", wilaya_id: "42", id: "1,350" },
  { nom: "Khemisti", wilaya: "Tipaza", wilaya_id: "42", id: "1,351" },
  { nom: "Koléa", wilaya: "Tipaza", wilaya_id: "42", id: "1,352" },
  { nom: "Larhat", wilaya: "Tipaza", wilaya_id: "42", id: "1,353" },
  { nom: "Menaceur", wilaya: "Tipaza", wilaya_id: "42", id: "1,354" },
  { nom: "Merad", wilaya: "Tipaza", wilaya_id: "42", id: "1,355" },
  { nom: "Messelmoun", wilaya: "Tipaza", wilaya_id: "42", id: "1,356" },
  { nom: "Nador", wilaya: "Tipaza", wilaya_id: "42", id: "1,357" },
  { nom: "Sidi Amar", wilaya: "Tipaza", wilaya_id: "42", id: "1,358" },
  { nom: "Sidi Ghilesse", wilaya: "Tipaza", wilaya_id: "42", id: "1,359" },
  { nom: "Sidi Rached", wilaya: "Tipaza", wilaya_id: "42", id: "1,360" },
  { nom: "Sidi Semiane", wilaya: "Tipaza", wilaya_id: "42", id: "1,361" },
  { nom: "Tipaza", wilaya: "Tipaza", wilaya_id: "42", id: "1,362" },
  { nom: "Ammari", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,363" },
  {
    nom: "Bordj Bou Naama",
    wilaya: "Tissemsilt",
    wilaya_id: "38",
    id: "1,364",
  },
  {
    nom: "Bordj Emir  Abdelkader",
    wilaya: "Tissemsilt",
    wilaya_id: "38",
    id: "1,365",
  },
  { nom: "Boukaïd", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,366" },
  { nom: "Béni Chaïb", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,367" },
  { nom: "Béni Lahcène", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,368" },
  { nom: "Khemisti", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,369" },
  { nom: "Larbaa", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,370" },
  { nom: "Lardjem", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,371" },
  { nom: "Layoun", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,372" },
  { nom: "Lazharia", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,373" },
  { nom: "Maaçem", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,374" },
  { nom: "Melaab", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,375" },
  { nom: "Ouled Bessem", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,376" },
  { nom: "Sidi Abed", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,377" },
  {
    nom: "Sidi Boutouchent",
    wilaya: "Tissemsilt",
    wilaya_id: "38",
    id: "1,378",
  },
  { nom: "Sidi Lantri", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,379" },
  { nom: "Sidi Slimane", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,380" },
  { nom: "Tamelaht", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,381" },
  { nom: "Theniet ElHad", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,382" },
  { nom: "Tissemsilt", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,383" },
  { nom: "Youssoufia", wilaya: "Tissemsilt", wilaya_id: "38", id: "1,384" },
  { nom: "Abi  Youcef", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,385" },
  {
    nom: "Aghni Goughrane",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,386",
  },
  { nom: "Aghrib", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,387" },
  { nom: "Akbil", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,388" },
  { nom: "Akerrou", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,389" },
  { nom: "Assi Youcef", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,390" },
  { nom: "Azzazga", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,391" },
  { nom: "Azzefoun", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,392" },
  { nom: "Aïn El Hammam", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,393" },
  { nom: "Aïn Zaouia", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,394" },
  { nom: "Aït Agouacha", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,395" },
  {
    nom: "Aït Aïssa Mimoun",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,396",
  },
  { nom: "Aït Bouadou", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,397" },
  { nom: "Aït Boumahdi", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,398" },
  { nom: "Aït Chaffa", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,399" },
  { nom: "Aït Khelili", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,400" },
  { nom: "Aït Mahmoud", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,401" },
  { nom: "Aït Oumalou", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,402" },
  { nom: "Aït Toudert", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,403" },
  { nom: "Aït Yahia", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,404" },
  {
    nom: "Aït Yahia Moussa",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,405",
  },
  { nom: "Boghni", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,406" },
  { nom: "Boudjima", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,407" },
  { nom: "Bounouh", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,408" },
  { nom: "Bouzeguène", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,409" },
  { nom: "Béni Aïssi", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,410" },
  { nom: "Béni Douala", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,411" },
  { nom: "Béni Yenni", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,412" },
  { nom: "Béni Zeki", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,413" },
  { nom: "Béni Zemenzer", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,414" },
  {
    nom: "Draa Ben Khedda",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,415",
  },
  { nom: "Draa El Mizan", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,416" },
  { nom: "Frikat", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,417" },
  { nom: "Fréha", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,418" },
  { nom: "Iboudrarène", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,419" },
  { nom: "Idjeur", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,420" },
  { nom: "Iferhounène", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,421" },
  { nom: "Ifigha", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,422" },
  { nom: "Iflissen", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,423" },
  { nom: "Illilten", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,424" },
  {
    nom: "Illoula Oumalou",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,425",
  },
  { nom: "Imsouhal", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,426" },
  { nom: "Irdjen", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,427" },
  {
    nom: "Larbaa Nath Iraten",
    wilaya: "Tizi Ouzou",
    wilaya_id: "15",
    id: "1,428",
  },
  { nom: "Maatka", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,429" },
  { nom: "Makouda", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,430" },
  { nom: "Mechtras", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,431" },
  { nom: "Mekla", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,432" },
  { nom: "Mizrana", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,433" },
  { nom: "Ouacif", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,434" },
  { nom: "Ouadhia", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,435" },
  { nom: "Ouaguenoun", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,436" },
  { nom: "Sidi Naamane", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,437" },
  { nom: "Souamaa", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,438" },
  { nom: "Souk El Tenine", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,439" },
  { nom: "Tadmaït", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,440" },
  { nom: "Tigzirt", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,441" },
  { nom: "Timizart", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,442" },
  { nom: "Tirmitine", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,443" },
  { nom: "Tizi Ghenif", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,444" },
  { nom: "Tizi Ouzou", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,445" },
  { nom: "Tizi Rached", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,446" },
  { nom: "Yakourène", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,447" },
  { nom: "Yatafène", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,448" },
  { nom: "Zekri", wilaya: "Tizi Ouzou", wilaya_id: "15", id: "1,449" },
  { nom: "Amieur", wilaya: "Tlemcen", wilaya_id: "13", id: "1,450" },
  { nom: "Azail", wilaya: "Tlemcen", wilaya_id: "13", id: "1,451" },
  { nom: "Aïn Fetah", wilaya: "Tlemcen", wilaya_id: "13", id: "1,452" },
  { nom: "Aïn Fezza", wilaya: "Tlemcen", wilaya_id: "13", id: "1,453" },
  { nom: "Aïn Ghoraba", wilaya: "Tlemcen", wilaya_id: "13", id: "1,454" },
  { nom: "Aïn Kebira", wilaya: "Tlemcen", wilaya_id: "13", id: "1,455" },
  { nom: "Aïn Nahala", wilaya: "Tlemcen", wilaya_id: "13", id: "1,456" },
  { nom: "Aïn Tallout", wilaya: "Tlemcen", wilaya_id: "13", id: "1,457" },
  { nom: "Aïn Youcef", wilaya: "Tlemcen", wilaya_id: "13", id: "1,458" },
  { nom: "Bab El  Assa", wilaya: "Tlemcen", wilaya_id: "13", id: "1,459" },
  { nom: "Bensekrane", wilaya: "Tlemcen", wilaya_id: "13", id: "1,460" },
  { nom: "Bouhlou", wilaya: "Tlemcen", wilaya_id: "13", id: "1,461" },
  { nom: "Béni Bahdel", wilaya: "Tlemcen", wilaya_id: "13", id: "1,462" },
  { nom: "Béni Boussaid", wilaya: "Tlemcen", wilaya_id: "13", id: "1,463" },
  { nom: "Béni Khaled", wilaya: "Tlemcen", wilaya_id: "13", id: "1,464" },
  { nom: "Béni Mester", wilaya: "Tlemcen", wilaya_id: "13", id: "1,465" },
  { nom: "Béni Ouarsous", wilaya: "Tlemcen", wilaya_id: "13", id: "1,466" },
  { nom: "Béni Semiel", wilaya: "Tlemcen", wilaya_id: "13", id: "1,467" },
  { nom: "Béni Snous", wilaya: "Tlemcen", wilaya_id: "13", id: "1,468" },
  { nom: "Chetouane", wilaya: "Tlemcen", wilaya_id: "13", id: "1,469" },
  { nom: "Dar Yaghmoracen", wilaya: "Tlemcen", wilaya_id: "13", id: "1,470" },
  { nom: "Djebala", wilaya: "Tlemcen", wilaya_id: "13", id: "1,471" },
  { nom: "El  Aricha", wilaya: "Tlemcen", wilaya_id: "13", id: "1,472" },
  { nom: "El  Fehoul", wilaya: "Tlemcen", wilaya_id: "13", id: "1,473" },
  { nom: "El Bouihi", wilaya: "Tlemcen", wilaya_id: "13", id: "1,474" },
  { nom: "El Gor", wilaya: "Tlemcen", wilaya_id: "13", id: "1,475" },
  { nom: "Fellaoucéne", wilaya: "Tlemcen", wilaya_id: "13", id: "1,476" },
  { nom: "Ghazaouet", wilaya: "Tlemcen", wilaya_id: "13", id: "1,477" },
  { nom: "Hammam Bougherara", wilaya: "Tlemcen", wilaya_id: "13", id: "1,478" },
  { nom: "Hennaya", wilaya: "Tlemcen", wilaya_id: "13", id: "1,479" },
  { nom: "Honaine", wilaya: "Tlemcen", wilaya_id: "13", id: "1,480" },
  { nom: "Maghnia", wilaya: "Tlemcen", wilaya_id: "13", id: "1,481" },
  { nom: "Mansourah", wilaya: "Tlemcen", wilaya_id: "13", id: "1,482" },
  { nom: "Nedroma", wilaya: "Tlemcen", wilaya_id: "13", id: "1,483" },
  { nom: "Oued Lakhdar", wilaya: "Tlemcen", wilaya_id: "13", id: "1,484" },
  { nom: "Oueld Mimoun", wilaya: "Tlemcen", wilaya_id: "13", id: "1,485" },
  { nom: "Ouled Ryah", wilaya: "Tlemcen", wilaya_id: "13", id: "1,486" },
  { nom: "Remchi", wilaya: "Tlemcen", wilaya_id: "13", id: "1,487" },
  { nom: "Sabra", wilaya: "Tlemcen", wilaya_id: "13", id: "1,488" },
  { nom: "Sebaa Chioukh", wilaya: "Tlemcen", wilaya_id: "13", id: "1,489" },
  { nom: "Sebdou", wilaya: "Tlemcen", wilaya_id: "13", id: "1,490" },
  { nom: "Sidi Abdelli", wilaya: "Tlemcen", wilaya_id: "13", id: "1,491" },
  { nom: "Sidi Djillali", wilaya: "Tlemcen", wilaya_id: "13", id: "1,492" },
  { nom: "Sidi Medjahed", wilaya: "Tlemcen", wilaya_id: "13", id: "1,493" },
  { nom: "Souahlia", wilaya: "Tlemcen", wilaya_id: "13", id: "1,494" },
  { nom: "Souani", wilaya: "Tlemcen", wilaya_id: "13", id: "1,495" },
  { nom: "Souk Thlata", wilaya: "Tlemcen", wilaya_id: "13", id: "1,496" },
  {
    nom: "Terny  Beni Hediel",
    wilaya: "Tlemcen",
    wilaya_id: "13",
    id: "1,497",
  },
  { nom: "Tianet", wilaya: "Tlemcen", wilaya_id: "13", id: "1,498" },
  { nom: "Tlemcen", wilaya: "Tlemcen", wilaya_id: "13", id: "1,499" },
  { nom: "Zenata", wilaya: "Tlemcen", wilaya_id: "13", id: "1,500" },
];

export const getCommunForWilaya = (wilaya) => {
  const communs = commun.filter((cmn) => cmn.wilaya == wilaya);
  return communs;
};

export const getWilayaByName = (name) => {
  return wilaya.find((wilaya) => wilaya.nom == name);
};

export const getCommunWithWilaya = () => {
  const communWithWilaya = [];
  wilaya.forEach((wilaya) => {
    communWithWilaya.push({
      id: wilaya.id,
      state: wilaya.nom,
      label: wilaya.nom,
    });
    const communs = getCommunForWilaya(wilaya);
    communs.forEach((commun) => {
      communWithWilaya.push({
        id: `${wilaya.id}-${commun.id}`,
        state: wilaya.nom,
        label: `${commun.nom}, ${wilaya.nom}`,
        city: commun.nom,
      });
    });
  });
  return communWithWilaya;
};

export const formattedWilaya = () => {};
