import {
  Box,
  Container,
  Grid,
  Hidden,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Facebook, FaceOutlined, LinkedIn, Twitter } from "@material-ui/icons";
import React from "react";
import BlogCard from "../components/common/blogCard";
import Navbar from "../components/common/Navbar";
import i1 from "../assets/tiles/camera.jpg";
import i2 from "../assets/tiles/hats.jpg";
import i3 from "../assets/tiles/honey.jpg";
import i4 from "../assets/tiles/morning.jpg";
import i5 from "../assets/tiles/star.jpg";
import Footer from "../components/Footer";

const styles = makeStyles(() => ({
  root: {
    display: "block",
    alignItems: "flex-end",
    position: "relative",
    top: 0,
    zIndex: 4,
    height: "300px",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(86,101,174,1) 0%, rgba(77,154,210,1) 53%, rgba(69,199,240,1) 100%)",
  },
  gridContainer: {
    alignItems: "flex-end",
    height: "70%",
  },
  h_100: {
    height: "100%",
  },

  section: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    overflowX: "hidden",
  },
  heading: {
    position: "relative",
    display: "inline-block",
    margin: "auto",
    zIndex: 2,
  },
}));
const Post = () => {
  const classes = styles();
  const tileData = [
    { title: "one", img: i1 },
    { title: "two", img: i2 },
    { title: "three", img: i3 },
  ];
  return (
    <>
      <section className={classes.root}>
        <Navbar />
        <Container className={classes.h_100}>
          <Grid container className={classes.gridContainer}>
            <Grid item md={7}>
              <Typography variant="h2" component="h1" gutterBottom>
                test
              </Typography>
              <Typography variant="subtitle1" component="p">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Error
                sapiente laborum voluptatem assumenda laboriosam. Vero atque
                quasi nisi culpa voluptatem?
              </Typography>
            </Grid>
            <Grid item md={5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "100%",
                }}
              >
                <Facebook style={{ margin: 5 }} />
                <Twitter style={{ margin: 5 }} />
                <LinkedIn style={{ margin: 5 }} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>

      <section className={classes.section}>
        <Container>
          <Grid container spacing={4}>
            <Grid item md={9}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec
              aliquam turpis, non interdum elit. Ut semper est quis ligula
              luctus, vitae scelerisque tortor maximus. Pellentesque habitant
              morbi tristique senectus et netus et malesuada fames ac turpis
              egestas. Nulla pretium leo sit amet odio ornare dapibus ut et
              enim. Maecenas ut condimentum risus. Nam sodales, neque nec
              pretium posuere, turpis ante egestas felis, a finibus turpis
              mauris id est. Nullam a facilisis sapien, porttitor blandit sem.
              Ut in auctor sem, id dictum ante. Nam vitae scelerisque magna, et
              bibendum sem. Phasellus nec tincidunt elit. Donec ac mattis nulla,
              sed ornare enim. Pellentesque a metus eu velit fringilla tristique
              a a urna. Vestibulum consequat diam vel purus tempor, maximus
              molestie eros rutrum. Quisque ut hendrerit justo. Quisque vel
              scelerisque massa. Nulla ac diam ipsum. Etiam vitae sapien quis
              eros cursus blandit non sit amet leo. Mauris in luctus quam.
              Nullam interdum erat eget velit ornare dictum. Sed sit amet risus
              arcu. Quisque imperdiet laoreet lorem ut elementum. Aliquam
              porttitor rutrum tellus, vitae porta mi semper id. Morbi eros
              nibh, scelerisque non gravida nec, faucibus nec lectus. Nullam
              risus nisi, accumsan in dolor at, facilisis tincidunt velit. Nam
              tempus nisl in purus convallis, sed tempor erat tempus. Curabitur
              porta, neque ac tincidunt tempus, felis lorem varius ante, sed
              viverra lectus nisl at erat. Pellentesque habitant morbi tristique
              senectus et netus et malesuada fames ac turpis egestas. Nam
              sagittis sodales dui, tristique porttitor dolor venenatis at. Nam
              nec augue posuere, semper magna in, hendrerit quam. Integer
              lobortis augue et aliquam lacinia. Proin sit amet elit posuere,
              iaculis felis id, rhoncus nisl. Vestibulum semper molestie justo
              nec elementum. Nullam lacinia, felis at sollicitudin egestas,
              lacus nulla maximus metus, a ultricies ligula velit a nibh. Nulla
              ultrices, nisi a aliquet egestas, lacus neque iaculis nisl, in
              accumsan dolor lacus eget velit. Duis quam enim, tempor in dictum
              sed, tincidunt at elit. Sed lorem augue, molestie eget facilisis
              ac, fermentum ac risus. Aenean non orci laoreet, lobortis magna
              id, eleifend nulla. Nullam tempor at nisl eget imperdiet. Proin
              tempor hendrerit placerat. Etiam dignissim ligula nunc, ac
              porttitor mauris dignissim sit amet. Nam ante nibh, tincidunt eget
              lectus ac, cursus tincidunt turpis. Cras tincidunt imperdiet
              euismod. Suspendisse ornare tincidunt mi non ornare. Aenean in
              erat et arcu rutrum posuere. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Nulla tincidunt nunc erat, porttitor
              ornare mauris vestibulum id. Vestibulum dignissim lorem a
              ultricies rhoncus. Mauris ac tempus odio, quis aliquet lectus.
              Cras lectus lorem, malesuada eget ex sed, rutrum vulputate augue.
              Nulla accumsan elit id felis gravida gravida. Nulla cursus vel
              felis sed tempor. Aliquam nec rhoncus ipsum. Mauris non imperdiet
              quam, non lobortis augue. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Mauris erat nulla, bibendum non rhoncus sit amet,
              rutrum id tellus. Vestibulum lectus nisl, venenatis et metus sit
              amet, tristique euismod lectus. Morbi posuere odio ac lectus
              posuere, eget pulvinar ex sagittis. Aliquam tempor est a velit
              blandit feugiat. Aenean risus nunc, commodo tristique tempor
              rhoncus, pharetra eget dolor. Nam sapien magna, pharetra ac orci
              vel, sodales cursus nibh. Aenean in quam id magna sodales
              fringilla auctor vitae lectus. Morbi egestas euismod elit eu
              feugiat. Nam posuere nisi vitae imperdiet ultricies. Nulla
              pulvinar quam id magna rutrum, quis pharetra mauris commodo. Nulla
              fringilla quis odio sed placerat. Generated 5 paragraphs, 551
              words, 3638 bytes of Lorem Ipsum
            </Grid>
            <Hidden smDown>
              <Grid item md={3}>
                <Typography variant="h6" gutterBottom>
                  Recent articles
                </Typography>
                {tileData.map((tile) => (
                  <Box mb={4}>
                    <BlogCard
                      key={tile.img}
                      title={tile.title}
                      img={tile.img}
                    />
                  </Box>
                ))}
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
      <Footer />
    </>
  );
};

export default Post;
