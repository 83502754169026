import React, { useEffect, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useLng } from "../../hooks/lng";
import { getContent } from "../../content/getContent";
import ReactPaginate from "react-paginate";
import "../../styles/pagination-style.css";
import {
  TextField,
  Typography,
  Button,
  Grid,
  Checkbox,
  Link,
  Modal,
} from "@material-ui/core";
import axios from "axios";
import { useUser } from "../../hooks/user";
import { Formik } from "formik";

function createData(
  name: string,
  sender: string,
  receiver: string,
  date: string,
  price: number,
  type: string
) {
  return { name, sender, receiver, date, price, type };
}

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 1000,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  paper2: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  table: {
    minWidth: 650,
  },
}));
export default function BasicTable() {
  const classes = useStyles();
  const { lng } = useLng();
  const formikRef = useRef(null);
  const titles = getContent("adminPanel", "offers", lng);
  const { REACT_APP_API_URL } = process.env;
  const { user } = useUser();
  const accessToken = user?.accessToken;

  const [offers, setOffers] = React.useState([]);
  const [offersPageAccount, setOffersPageAccount] = React.useState(0);
  const [offerState, setOfferState] = React.useState({
    active: true,
    accepted: false,
  });
  const [information, setInformation] = React.useState({
    informations: {},
    key: "",
  });
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState(false);
  const [infosUser, setInfosUser] = React.useState();
  const handleOpen = (item, key) => {
    setInformation({ informations: item, key: key });
    if (key === "sender" || key === "receiver") {
      fetchUser(item?.uuid);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const rows = () => {
    var result;
    result = offers.map((item) => {
      return createData(
        item,
        item.sender,
        item.receiver,
        item.session_start_date,
        item.session_price,
        item.session_type
      );
    });
    return result;
  };
  const initialValues = {
    teacher_name: "",
    consumer_name: "",
    consumer_phone_number: "",
    teacher_phone_number: "",
    teacher_email: "",
    consumer_email: "",
  };
  const LIMIT = 10;
  const fetchTransactions = async (page = 1) => {
    try {
      if (accessToken) {
        const offers = await axios.get(
          `${REACT_APP_API_URL}/offers?limit=${LIMIT}&page=${page}`,
          {
            params: {
              active: offerState.active,
              accepted: offerState.accepted,
              teacher_name: formikRef?.current?.values.teacher_name,
              consumer_name: formikRef?.current?.values.consumer_name,
              teacher_phone_number:
                formikRef?.current?.values.teacher_phone_number,
              consumer_phone_number:
                formikRef?.current?.values.consumer_phone_number,
              teacher_email: formikRef?.current?.values.teacher_email,
              consumer_email: formikRef?.current?.values.consumer_email,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (offers.data) {
          setOffers(offers.data["records"]);
          setOffersPageAccount(offers.data.total_pages);
          rows();
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  useEffect(() => {
    fetchTransactions();
  }, []);
  const body = (item) => {
    return (
      <>
        {information?.key === "offre" ? (
          <div style={modalStyle} className={classes.paper}>
            <Typography
              variant="h3"
              color="primary"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              Offer
            </Typography>
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Sender : {item.sender.last_name} {item.sender.first_name}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Receiver : {item.receiver.last_name} {item.receiver.first_name}
            </Typography>

            {item?.email && (
              <Typography style={{ fontSize: 25, marginBottom: 5 }}>
                Email : {item.receiver.email}
              </Typography>
            )}
            {item?.phone_number && (
              <Typography style={{ fontSize: 25, marginBottom: 5 }}>
                Phone number : {item.receiver.phone_number}
              </Typography>
            )}
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Type : {item.session_type}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Price : {item.session_price} Da
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Session Start Date : {item.session_start_date}
            </Typography>
            {item?.session_type === "subscription" && (
              <Typography style={{ fontSize: 25, marginBottom: 5 }}>
                Session End Date : {item.session_end_date}
              </Typography>
            )}

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Session Duration :
              {Number.isInteger(item?.session_duration)
                ? ` ${item?.session_duration} H`
                : ` ${Math.trunc(item?.session_duration)} H 30 min`}
            </Typography>
            {item?.description && (
              <Typography style={{ fontSize: 25, marginBottom: 5 }}>
                Description : {item.description}
              </Typography>
            )}
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Type of session :
              {`${
                item?.group
                  ? " Group"
                  : item?.individual
                  ? " Indevidual"
                  : item?.online
                  ? " Online"
                  : ""
              }`}
            </Typography>
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              WorkPlace :
              {item?.incall === true ? " At Home" : " At the teacher House"}
            </Typography>
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Days of work :
              {`${
                item?.saturday
                  ? " Saturday" + " => " + item.saturday.slice(0, 5) + `${"\n"}`
                  : ""
              }${
                item?.sunday
                  ? " Sunday" + " => " + item.sunday.slice(0, 5) + `${"\n"}`
                  : ""
              }${
                item?.monday
                  ? " Monday" + " => " + item.monday.slice(0, 5) + `${" "}`
                  : ""
              }${
                item?.tuesday
                  ? " Tuesday" + " => " + item.tuesday.slice(0, 5) + `${"\n"}`
                  : ""
              }${
                item?.wednesday
                  ? " Wednesday" +
                    " => " +
                    item.wednesday.slice(0, 5) +
                    `${"\n"}`
                  : ""
              }${
                item?.thursday
                  ? " Thursday" + " => " + item.thursday.slice(0, 5) + `${"\n"}`
                  : ""
              }${
                item?.friday
                  ? " Friday" + " => " + item.friday.slice(0, 5) + `${"\n"}`
                  : ""
              }`}
            </Typography>
          </div>
        ) : (
          <div style={modalStyle} className={classes.paper2}>
            <Typography
              variant="h3"
              color="primary"
              style={{ textAlign: "center", marginBottom: 20 }}
            >
              {`${information?.key === "sender" ? "Sender" : "Receiver"}`}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              First name : {item.first_name}
            </Typography>
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Last name : {item.last_name}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Sex : {item.sex}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              State : {item.state}
            </Typography>

            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              City : {item.city}
            </Typography>
            <Typography style={{ fontSize: 25, marginBottom: 5 }}>
              Role : {item.role}
            </Typography>
            {information?.key === "receiver" && (
              <Typography style={{ fontSize: 25, marginBottom: 5 }}>
                Grade :{" "}
                {`${
                  item?.is_professional ? "Professional" : "Not professional"
                }`}
              </Typography>
            )}
          </div>
        )}
      </>
    );
  };
  const handleChange = (event) => {
    setOfferState({ ...offerState, [event.target.name]: event.target.checked });
  };
  const fetchUser = async (uuid) => {
    try {
      if (accessToken) {
        const userInfos = await axios.get(
          `${REACT_APP_API_URL}/users/${uuid}`,
          {
            params: { uuid: uuid },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (userInfos.data) {
          setInfosUser(userInfos.data);
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  const handleSubmit = () => {};
  return (
    <div>
      <Formik
        initialValues={initialValues}
        innerRef={formikRef}
        onSubmit={handleSubmit}
      >
        {({
          values,
          setFieldValue,
          submitCount,
          errors,
          handleSubmit,
          isSubmitting,
          isValid,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6} lg={6}>
                <Typography gutterBottom variant="h6" color="primary">
                  Teacher
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Typography gutterBottom variant="h6" color="primary">
                  Parent
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  label="Teacher name"
                  name="Teacher name"
                  value={values.teacher_name}
                  onChange={(event) =>
                    setFieldValue(
                      "teacher_name",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  autoComplete="Teacher name"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  name="Parent name"
                  label="Parent name"
                  onChange={(event) =>
                    setFieldValue(
                      "consumer_name",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  value={values.consumer_name}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  name="Teacher phone number"
                  label="Teacher phone number"
                  onChange={(event) =>
                    setFieldValue(
                      "teacher_phone_number",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  value={values.teacher_phone_number}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  name="Parent phone number"
                  label="Parent phone number"
                  onChange={(event) =>
                    setFieldValue(
                      "consumer_phone_number",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  value={values.consumer_phone_number}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  name="Teacher email"
                  label="Teacher email"
                  onChange={(event) =>
                    setFieldValue(
                      "teacher_email",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  value={values.teacher_email}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <TextField
                  style={{ margin: 10 }}
                  variant="outlined"
                  margin="normal"
                  name="Parent email"
                  label="Parent email"
                  onChange={(event) =>
                    setFieldValue(
                      "consumer_email",
                      event.target.value,
                      !!submitCount
                    )
                  }
                  value={values.consumer_email}
                />
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "left",
                flexDirection: "row",
                margin: 10,
                marginLeft: 20,
              }}
            >
              <Checkbox
                checked={offerState.active}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
                name="active"
              />
              <Typography style={{ marginTop: 5 }}>active</Typography>
              <Checkbox
                checked={offerState.accepted}
                onChange={handleChange}
                inputProps={{ "aria-label": "primary checkbox" }}
                name="accepted"
              />
              <Typography style={{ marginTop: 5 }}>accepted</Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "right",
              }}
            >
              {" "}
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ marginBottom: 20, marginLeft: 10 }}
                onClick={() => {
                  fetchTransactions();
                }}
              >
                Search
              </Button>
            </div>
          </form>
        )}
      </Formik>
      <ReactPaginate
        pageCount={offersPageAccount}
        containerClassName={"react-paginate"}
        onPageChange={(page) => {
          fetchTransactions(page.selected + 1);
        }}
        activeClassName={"active"}
        nextLabel={">"}
        previousLabel={"<"}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{titles.offers}</TableCell>
              <TableCell align="right">{titles.sender}</TableCell>
              <TableCell align="right">{titles.receiver}</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">{titles.price}</TableCell>
              <TableCell align="right">Type</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows().map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row">
                  <Link
                    href="#"
                    onClick={() => handleOpen(row.name, "offre")}
                    color="inherit"
                  >
                    Offre
                  </Link>
                </TableCell>

                <TableCell align="right">
                  <Link
                    href="#"
                    onClick={() => handleOpen(row.sender, "sender")}
                    color="inherit"
                  >
                    {row.sender.last_name} {row.sender.first_name}
                  </Link>
                </TableCell>
                <TableCell align="right">
                  <Link
                    href="#"
                    onClick={() => handleOpen(row.receiver, "receiver")}
                    color="inherit"
                  >
                    {row.receiver.last_name} {row.receiver.first_name}
                  </Link>
                </TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.price}</TableCell>
                <TableCell align="right">{row.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body(information?.informations)}
      </Modal>
    </div>
  );
}
