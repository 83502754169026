export default {
    fr: {
      offers: {
        offers: "Les Offres",
        pendingOffers :'Offre en attente',
        sender: "Expéditeur",
        receiver: "Destinataire",
        price: "Prix",


      },
      titles :{
        users :'Utilisateurs'
      },
      copyright: "© 2021 TutorDz. Tous les droits sont réservés",
      getUpdates: "Recevez les dernières mises à jour sur notre blog",
 
    },
    en: {
        offers: {
        offers: "Offres",
        pendingOffers :'Pending Offers',
        sender: "Sender",
        receiver: "Receiver",
        price: "Price",

      },
      titles :{
        users :'Users'
      },
      getUpdates: "Get the latest updates on our blog",
      copyright: "© 2020 TutorDz. All rights reserved",

    },

  };
  