import React from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
  withStyles,
} from "@material-ui/core/styles";
import { Box, Card, Container, Grid, Typography } from "@material-ui/core";
import search from "../assets/img/search.svg";
import plan from "../assets/img/calendar.svg";
import chat from "../assets/img/chat.svg";
import { useLng } from "../hooks/lng";
import { getContent } from "../content/getContent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
      overflowX: "hidden",
    },
    heading: {
      position: "relative",
      display: "inline-block",
      margin: "auto",
      marginBottom: "3rem",
      zIndex: 2,
    },
    featSvg: {
      width: "50%",
      margin: "auto",
    },
    featCard: {
      textAlign: "center",
    },
  })
);

export default function Features() {
  const classes = useStyles();
  const theme = useTheme();
  const { lng } = useLng();
  const feats = getContent("home_", "features", lng);

  return (
    <section className={classes.section}>
      <Container>
        <Box style={{ textAlign: "center" }}>
          <div className={classes.heading}>
            <Typography align="center" variant="h4" component="h2" gutterBottom>
              {feats.title}
            </Typography>
            <Typography align="center" variant="subtitle2" component="h5">
              {feats.subTitle}
            </Typography>
          </div>
        </Box>
        <Grid container>
          <Grid item md={4} xs={12}>
            <div className={classes.featCard}>
              <img src={search} alt="" className={classes.featSvg} />
              <Typography variant="h5">{feats.feat1}</Typography>
              <Typography variant="caption">{feats.feat1Text}</Typography>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.featCard}>
              <img src={plan} alt="" className={classes.featSvg} />
              <Typography variant="h5">{feats.feat2}</Typography>
              <Typography variant="caption">
                <Typography variant="caption">{feats.feat2Text}</Typography>
              </Typography>
            </div>
          </Grid>
          <Grid item md={4} xs={12}>
            <div className={classes.featCard}>
              <img src={chat} alt="" className={classes.featSvg} />
              <Typography variant="h5">{feats.feat3}</Typography>
              <Typography variant="caption">{feats.feat3Text}</Typography>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
