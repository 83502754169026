export default {
  fr: {
    hero: {
      slogan: "Trouvez le meilleur enseignant pour votre enfant.",
      courses: "Cours particuliers",
      control: "Contrôle",
      follow: "suivi",
      soon: "Disponible sur",
    },
    about: {
      title: "À propos de TUTOR",
      text: "C'est une application p2p  de mise en relation entre les enseignants et les parents d'élèves, grâce à cette application vous pouvez trouver des enseignants qui donnent les cours particuliers, assurer un contrôle bien précis du progrès de votre enfant et maintenir un  bon suivi avec le feedback donné par les enseignants.\nTUTOR n'est pas seulement pour les parents et leurs enfants, mais aussi pour vous les enseignants, cette application vous donne l'opportunité pour donner les cours particuliers.",
    },
    how: {
      title: "Comment ça marche",
      step1: {
        title: "Connectez-vous ou créez un nouveau compte",
        text: "Pour commencer l'aventure, c'est la 1ère étape!",
      },
      step2: {
        title: "Trouver un enseignant",
        text: "Après l'authentification, vous pouvez chercher et trouver votre enseignant",
      },
      step3: {
        title: "Composez une offre",
        text: "Une fois que vous trouvez l'enseignant favorable, vous pouvez le contacter en composant une offre personnalisée, négociable.",
      },
      step4: {
        title: "Contrôlez et suivez le progrès",
        text: "Nous avons développé tout un système qui permet d'assurer un contrôle et un suivi bien précis du progrès de votre enfant.",
      },
    },

    features: {
      title: "Les avantages",
      subTitle:
        "TUTOR  offre différentes fonctionnalités, vous pouvez donc faire des offres et suivre facilement les progrès de votre enfant",
      feat1: "Rechercher des enseignants",
      feat2: "Planifier des sessions",
      feat3: "contrôle et suivi",
      feat1Text:
        "Avec TUTOR, vous pouvez personnaliser votre recherche d'enseignants par sujet et région, ou par type de session",
      feat2Text:
        "Vous pouvez envoyer des offres aux enseignants et définir la date et le lieu de la session souhaitée",
      feat3Text:
        "TUTOR offre un système de feedback pour que vous puissiez suivre les progrès de votre enfant",
    },

    modal: {
      title: "Avis",
      text: "Nous sommes actuellement dans la version de test fermée (closed beta), si vous voulez tester l'application avec nous, veuillez nous joindre par le formulaire  ",
      cta: "Joindre",
      close: "Fermer",
    },
  },
  en: {
    hero: {
      slogan: "Find the best teacher for your child.",
      courses: "Private lessons",
      control: "Control",
      follow: "following",
      soon: "Available on",
    },
    about: {
      title: "About TUTOR",
      text: "It is a p2p application for connecting teachers and parents of students, thanks to this application you can find teachers who give private lessons, ensure precise control of your child's progress and maintain a good follow-up with the feedback given by the teachers. \nTUTOR is not only for parents and their children, but also for you teachers, this application gives you the opportunity to give private lessons. ",
    },
    how: {
      title: "How it works",
      step1: {
        title: "Log in or create a new account",
        text: "To start the adventure, this is the 1st step!",
      },
      step2: {
        title: "Find a teacher",
        text: "After authentication you can search and find your teacher",
      },
      step3: {
        title: "Compose an offer",
        text: "Once you find the favorable teacher, you can contact him by composing a personalized, negotiable offer.",
      },
      step4: {
        title: "Control and follow the progress",
        text: "We have developed a whole system that ensures precise control and monitoring of your child's progress.",
      },
    },
    features: {
      title: "Features",
      subTitle:
        "Tutor app offer different functionalities , so you can make offers and follow your child progress easily",
      feat1: "Search teachers",
      feat2: "Schedule sessions",
      feat3: "Monitoring",
      feat1Text:
        "With TUTOR, you can cutomize your search for teachers by subject and region, or by type of session",
      feat2Text:
        "You can send offers to teachers and define the desired date and place of the session",
      feat3Text:
        "TUTOR offers a feedback system so you can track your child's progress",
    },
    modal: {
      title: "Notice",
      text: "We are currently in the closed beta version, if you want to test the application with us, please contact us using the form",
      cta: "Join",
      close: "Close",
    },
  },
  ar: {
    hero: {
      slogan: "ابحث على أفضل معلم لطفلك.",
      courses: "دروس خصوصية",
      control: "مراقبة",
      follow: "متابعة",
      soon: "متاح على",
    },
    about: {
      title: "حول TUTOR",
      text: "إنه تطبيق p2p لربط المعلمين وأولياء أمور الطلاب ، بفضل هذا التطبيق ، يمكنك العثور على مدرسين يقدمون دروسًا خاصة ، وضمان التحكم الدقيق في تقدم طفلك والحفاظ على متابعة جيدة مع الملاحظات التي قدمها المعلمون . \nTUTOR ليس فقط للآباء وأبنائهم ، ولكن أيضًا للمدرسين ، يمنحك هذا التطبيق الفرصة لإعطاء دروس خصوصية. ",
    },
    how: {
      title: "كيف تعمل",
      step1: {
        title: "تسجيل الدخول أو إنشاء حساب جديد",
        text: "إنها الخطوة الأولى لبدء المغامرة!",
      },
      step2: {
        title: "البحث عن مدرس",
        text: "بعد المصادقة ، يمكنك البحث والعثور على معلمك",
      },
      step3: {
        title: "إنشاء عرض",
        text: "بمجرد العثور على المعلم المفضل ، يمكنك الاتصال به من خلال تأليف عرض شخصي قابل للتفاوض.",
      },
      step4: {
        title: "التحكم ومتابعة التقدم",
        text: "لقد طورنا نظامًا كاملاً يضمن التحكم الدقيق ومراقبة تقدم طفلك.",
      },
    },
    features: {
      title: "الميزات",
      subTitle:
        "يقدم تطبيق Tutor وظائف مختلفة ، بحيث يمكنك تقديم العروض ومتابعة تقدم طفلك بسهولة",
      feat1: "ابحث عن المعلمين",
      feat2: "جدولة الجلسات",
      feat3: "مراقبة و متابعة",
      feat1Text:
        "باستخدام TUTOR ، يمكنك تخصيص بحثك عن المعلمين حسب المواد التعليمية والمنطقة ، أو حسب نوع الحصص",
      feat2Text: "يمكنك إرسال عروض للمعلمين وتحديد موعد ومكان الحصص المطلوبين",
      feat3Text: "يقدم TUTOR نظام ملاحظات حتى تتمكن من تتبع تقدم طفلك",
    },
    modal: {
      title: "تنويه",
      text: "نحن حاليًا في الإصدار التجريبي المغلق ، إذا كنت ترغب في اختبار التطبيق معنا ، فيرجى الاتصال بنا باستخدام النموذج",
      cta: "انضم",
      close: "إغلاق",
    },
  },
  apiResponses: {
    incorrect_username_or_password: {
      fr: "Email (ou numéro de téléphone) mot pass est incorrect",
      eng: "Incorrect email (or phone number)or password",
    },
    email_not_verified: {
      fr: "Vous devez verifier votre email pour pouvoir vos connectez.",
      eng: "You must verify your email to access your account",
    },
  },
};
