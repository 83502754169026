export default {
  fr: {
    buttons: {
      conditions: "termes et conditions",
      seeMore: "Voir plus",
      showMore: "Afficher plus",
      seeAll: "Voir tout",
      ShowAll: "Afficher tout",
      share: "Partager",
      getTheApp: "Telecharger l'application",
      download: "Telecharger",
      downloadNow: "Telecharger maintenant",
      availableAt: "Disponible sur",
      myAccount: "Mon compte",
      logIn: "Se connecter",
      logOut: "Se déconnecter",
      mentors: "Enseignants",
      listings: "Annonces",
      learnMore: "En savoir plus",
      license: "Licence",
      about: "À propos",
      help: "Aide",
      pricing: "Tarification",
      features: "Fonctionalités",
      Careers: "carrières",
      howItWorks: "Comment ça marche",
      blog: "Blog",
      spaces: "      ",
      tutor: "tutor",
      stats: "statistiques",
      feats: "Avantages",
    },
    copyright: "© 2020 TutorDz. Tous les droits sont réservés",
    getUpdates: "Recevez les dernières mises à jour sur notre blog",
    stats: {
      title: "Quelques chiffres importants",
      text: "Nos réalisations au cours de notre aventure illustrées en chiffres",
      tutors: "Enseignants",
      wilayas: "Wilayas",
      offers: "Offres",
      subjects: "Matières",
    },
  },
  en: {
    buttons: {
      conditions: "terms and conditions",
      seeMore: "See more",
      showMore: "Show more",
      seeAll: "See all",
      ShowAll: "Show all",
      share: "Share",
      getTheApp: "Download the application",
      download: "Download",
      downloadNow: "Download now",
      availableAt: "Available at",
      myAccount: "My account",
      logIn: "Log in",
      logOut: "Log out",
      mentors: "Mentors",
      listings: "Listings",
      learnMore: "Learn more",
      license: "License",
      about: "About",
      help: "Help",
      pricing: "Pricing",
      features: "Features",
      careers: "Careers",
      howItWorks: "How it works",
      blog: "Blog",
      spaces: "      ",
      tutor: "tutor",
      stats: "stats",
      feats: "Features",
    },
    getUpdates: "Get the latest updates on our blog",
    copyright: "© 2020 TutorDz. All rights reserved",
    stats: {
      title: "Some counts that matter",
      text: "Our achievements in our journey depicted in numbers",
      tutors: "Teachers",
      wilayas: "Wilayas",
      offers: "Offers",
      subjects: "Subjects",
    },
  },
  ar: {
    buttons: {
      conditions: "الأحكام والشروط",
      seeMore: "أظهر المزيد",
      showMore: "إظهار المزيد",
      seeAll: "إظهار الكل",
      ShowAll: "إظهار الكل",
      share: "شارك",
      getTheApp: "تحميل التطبيق",
      download: "تحميل",
      downloadNow: "قم بتحميله الآن",
      availableAt: "متواجد في",
      myAccount: "حسابي",
      logIn: "تسجيل دخول",
      logOut: "تسجيل الخروج",
      mentors: "المعلمين",
      listings: "القوائم",
      learnMore: "اعرف المزيد",
      getUpdates: "احصل على آخر التحديثات على مدونتنا",
      copyright: "© 2020 TutorDz. كل الحقوق محفوظة",
      license: "ترخيص",
      about: "حول",
      help: "مساعدة",
      pricing: "التسعير",
      features: "المميزات",
      careers: "الوظائف",
      howItWorks: "كيف تعمل",
      blog: "مدونتنا",
      spaces: "      ",
      tutor: "tutor",
      stats: "إحصاءات",
      feats: "مميزات",
    },
    stats: {
      title: "بعض الأرقام المهمة",
      text: "إنجازاتنا خلال رحلتنا موضحة بالأرقام",
      tutors: "المدرسون",
      wilayas: "الولايات",
      offers: "العروض",
      subjects: "المواضيع",
    },
  },
  apiResponses: {
    incorrect_username_or_password: {
      fr: "Email (ou numéro de téléphone) mot pass est incorrect",
      eng: "Incorrect email (or phone number)or password",
    },
    email_not_verified: {
      fr: "Vous devez verifier votre email pour pouvoir vos connectez.",
      eng: "You must verify your email to access your account",
    },
  },
};
