import React, { useEffect, useMemo, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Avatar, Button, CircularProgress } from "@material-ui/core";
import axios from "axios";
import { useUser } from "../../hooks/user";
import ReactPaginate from "react-paginate";
import "../../styles/pagination-style.css";
import { useCallback } from "react";
import { wilaya, getCommunForWilaya } from "../../content/states";

const Accordion = withStyles({
  root: {
    border: "1px solid rgba(0, 0, 0, .125)",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, .03)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const LIMIT = 10;
interface Iwilaya {
  id: string;
  code: string;
  nom: string;
}
interface ICommune {
  nom: string;
  wilaya: string;
  wilaya_id: string;
  id: string;
}
export default function UsersList({
  index,
  users,
  usersPageCount,

  fetchUsers,
}) {
  const [expanded, setExpanded] = React.useState("panel1");

  // Filters

  //  ...query.searchTerm,
  // subject_key:
  //   query?.phase && query?.year && query?.subject
  //     ? generateKey(query.phase, query.year, query.subject)
  //     : null,
  // is_professional: query.is_professional,
  // group_price: query?.group_price,
  // group_price_subscription: query?.group_price_subscription,
  // online_price: query?.online_price,
  // online_price_subscription: query?.online_price_subscription,
  // individual_price: query?.individual_price,
  // individual_price_subscription: query?.individual_price_subscription,
  // role: query?.role,
  // sex: query?.sex,

  // More filters

  const [isLoading, setIsLoading] = React.useState(true);
  const { user } = useUser();
  const { REACT_APP_API_URL } = process.env;
  const displayBlockButton = index === 0 || index === 1;
  const displayActiveButton = index === 0 || index === 2 || index === 3;
  const displayToAwaitListButton = index === 0 || index === 1;

  const accessToken = user?.accessToken;
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const updateUser = async (id, values, endpoint) => {
    try {
      if (accessToken) {
        const response = await axios.patch(
          `${REACT_APP_API_URL}/users/${id}/${endpoint}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        // if (response.data) {
        //  fetchUsers(1);
        // }
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  return (
    <div>
      <>
        {users.length > 0 && (
          <ReactPaginate
            pageCount={usersPageCount}
            containerClassName={"react-paginate"}
            onPageChange={(page) => {
              fetchUsers(page.selected + 1);
            }}
            activeClassName={"active"}
            nextLabel={">"}
            previousLabel={"<"}
          />
        )}
        {users.length === 0 && <h4>No users found</h4>}
        {users?.map((item, index) => {
          return (
            <Accordion
              square
              expanded={expanded === `panel${index + 1}`}
              onChange={handleChange(`panel${index + 1}`)}
            >
              <AccordionSummary
                aria-controls={`panel${index + 1}d-content`}
                id={`panel${index + 1}d-header`}
              >
                <Avatar style={{ marginRight: 10 }}>
                  <AccountCircleIcon />
                </Avatar>
                <Typography>{`${item.first_name} ${item.last_name}`}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                  }}
                >
                  <Typography>
                    Full Name : {item.first_name} {item.last_name}
                  </Typography>
                  {item.is_professional && (
                    <Typography>Role : Professional teacher</Typography>
                  )}
                  {!item.is_professional && item.role === "teacher" && (
                    <Typography>Role : Non Professional teacher</Typography>
                  )}
                  {item.role === "parent" && (
                    <Typography>Role : Parent</Typography>
                  )}
                  {item.email && <Typography>E-Mail : {item.email}</Typography>}
                  {item.phone_number && (
                    <Typography>Phone Number : {item.phone_number}</Typography>
                  )}
                  <Typography>
                    Personal address : {item.address} - {item.city} -{" "}
                    {item.state}
                  </Typography>
                  {item?.is_professional && (
                    <Typography>
                      work address :{item?.user_config?.work_school?.city} -{" "}
                      {item?.user_config?.work_school?.state}
                    </Typography>
                  )}
                  {item?.state && <Typography>State : {item.state}</Typography>}
                  {item?.city && <Typography>City : {item.city}</Typography>}
                  {item?.user_config?.principle_subject && (
                    <Typography>
                      Principle subject :{" "}
                      {item?.user_config?.principle_subject?.name}
                    </Typography>
                  )}
                  {item?.is_professional && (
                    <Typography>
                      School name :{item?.user_config?.work_school?.name} (
                      {item?.user_config?.work_school?.level})
                    </Typography>
                  )}

                  <Typography>
                    Finished on boarding :{" "}
                    {item.finished_on_boarding ? "YES" : "NO"}
                  </Typography>

                  <Typography style={{ fontSize: 23 }}>
                    Created at : {item.inserted_at}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-end",
                    }}
                  >
                    {displayActiveButton && (
                      <Button
                        onClick={() =>
                          updateUser(item.id, { active: true }, "account")
                        }
                        variant="contained"
                        style={{
                          backgroundColor: "teal",
                          color: "white",
                          marginRight: 7,
                        }}
                      >
                        Activate
                      </Button>
                    )}

                    {displayBlockButton && (
                      <Button
                        onClick={() =>
                          updateUser(item.id, { active: false }, "account")
                        }
                        variant="contained"
                        style={{
                          backgroundColor: "tomato",
                          color: "white",
                          marginRight: 7,
                        }}
                      >
                        Block
                      </Button>
                    )}
                    {displayToAwaitListButton && (
                      <Button
                        onClick={() =>
                          updateUser(item.id, { waitlist: true }, "waitlist")
                        }
                        variant="outlined"
                        color="primary"
                      >
                        to wait list
                      </Button>
                    )}
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </>
    </div>
  );
}
