import React from "react";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Title from "./Title";
import { Card } from "@material-ui/core";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
    marginTop: 20,
  },
  margin: {
    marginTop: 20,
  },
  card: {},
});

export default function UserCard(props) {
  const classes = useStyles();
  return (
    <Card variant="outlined" className={classes.card}>
      <Title>{props.userType}</Title>
      <Typography
        component="p"
        variant="h4"
        align="center"
        className={classes.margin}
      >
        {props.number}
      </Typography>

      <div className={classes.margin}></div>
    </Card>
  );
}
