import React, { useEffect, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import PendingTransactions from "./PendingTransactions";
import { useLng } from "../../hooks/lng";
import { getContent } from "../../content/getContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import PublicIcon from "@material-ui/icons/Public";
import UserCard from "./userCard";
import AccountPieChart from "./AccountPieChart";
import UsersChart from "./UsersChart";
import UsersTab from "./UsersTab";
import OffersChart from "./OffersChart";
import axios from "axios";
import { useUser } from "../../hooks/user";
import HomeIcon from "@material-ui/icons/Home";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    display: "flex",
  },
  title2: {
    textAlign: "center",
  },
}));

type statsType = {
  finished_on_boarding: number;
  parent: number;
  pending: number;
  teacher: number;
  teacher_pro: number;
  users: number;
  verified: number;
};
export default function Dashboard() {
  const classes = useStyles();
  const { REACT_APP_API_URL } = process.env;
  const { user } = useUser();
  const [activeUsers, setActiveUsers] = React.useState([]);
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [stats, setStats] = React.useState<statsType>({
    finished_on_boarding: 0,
    parent: 0,
    pending: 0,
    teacher: 0,
    teacher_pro: 0,
    users: 0,
    verified: 0,
  });
  const accessToken = user?.accessToken;
  const [open, setOpen] = React.useState(true);
  const [choice, setChoice] = React.useState({
    transaction: false,
    usersStat: false,
    AccountsStat: false,
    Accounts: true,
    Report: false,
  });
  const { lng } = useLng();
  const offers = getContent("adminPanel", "offers", lng);
  const titles = getContent("adminPanel", "titles", lng);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  useEffect(() => {
    async function fetchData() {
      try {
        if (accessToken) {
          const responseActive = await axios.get(`${REACT_APP_API_URL}/users`, {
            params: { active: true },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const responsePending = await axios.get(
            `${REACT_APP_API_URL}/users`,
            {
              params: { active: false },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          if (responseActive.data) {
            setActiveUsers(responseActive.data["records"]);
          }
          if (responsePending.data) {
            setPendingUsers(responsePending.data["records"]);
          }
        }
      } catch (e) {
        console.error("error", e);
      }
    }
    fetchData();
  }, [accessToken]);

  const fetchStats = useCallback(async () => {
    try {
      if (accessToken) {
        const res = await axios.get(`${REACT_APP_API_URL}/users/stats`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        setStats(res.data);
      }
    } catch (e) {
      console.error("error", e);
      console.log("err");
    }
  }, [accessToken, REACT_APP_API_URL]);

  useEffect(() => {
    console.log("run");
    fetchStats();
  }, [fetchStats]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            className={classes.title}
          >
            Dashboard
          </Typography>
          <Link
            to="/"
            component={RouterLink}
            color="inherit"
            style={{ textDecoration: "none" }}
          >
            <IconButton edge="start" color="inherit">
              <HomeIcon />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            <ListItem button>
              <ListItemIcon>
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Transactions"
                onClick={() =>
                  setChoice({
                    ...choice,
                    transaction: true,
                    usersStat: false,
                    AccountsStat: false,
                    Accounts: false,
                    Report: false,
                  })
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary="users Stat"
                onClick={() =>
                  setChoice({
                    ...choice,
                    transaction: false,
                    usersStat: true,
                    AccountsStat: false,
                    Accounts: false,
                    Report: false,
                  })
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <PeopleIcon />
              </ListItemIcon>
              <ListItemText
                primary="Accounts Stat"
                onClick={() =>
                  setChoice({
                    ...choice,
                    transaction: false,
                    usersStat: false,
                    AccountsStat: true,
                    Accounts: false,
                    Report: false,
                  })
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <PublicIcon />
              </ListItemIcon>
              <ListItemText
                primary="Accounts"
                onClick={() =>
                  setChoice({
                    ...choice,
                    transaction: false,
                    usersStat: false,
                    AccountsStat: false,
                    Accounts: true,
                    Report: false,
                  })
                }
              />
            </ListItem>
            <ListItem button>
              <ListItemIcon>
                <AssessmentIcon />
              </ListItemIcon>
              <ListItemText
                primary="Report"
                onClick={() =>
                  setChoice({
                    ...choice,
                    transaction: false,
                    usersStat: false,
                    AccountsStat: false,
                    Accounts: false,
                    Report: true,
                  })
                }
              />
            </ListItem>
          </div>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {choice.transaction && (
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="primary"
            >
              {offers.pendingOffers}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={fixedHeightPaper}>
                  <PendingTransactions />
                </Paper>
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        )}
        {choice.AccountsStat && (
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="primary"
            ></Typography>
            <Grid style={{ marginTop: 20 }} container spacing={6}>
              <Grid item xs={12} md={12} lg={12}>
                <UserCard userType="All users - TOTAL" number={stats?.users} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <UserCard userType="All parents" number={stats?.parent} />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <UserCard
                  userType="Non professional teachers"
                  number={stats?.teacher}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <UserCard
                  userType="Finshed onboarding users"
                  number={stats?.finished_on_boarding}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <UserCard userType="Pending users" number={stats?.pending} />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <UserCard
                  userType="Professional teachers"
                  number={stats?.teacher_pro}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <UserCard userType="Verifed users" number={stats?.verified} />
              </Grid>
            </Grid>

            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        )}
        {choice.usersStat && (
          <Container maxWidth="lg" className={classes.container}>
            <Typography
              align="center"
              gutterBottom
              variant="h3"
              color="primary"
            >
              {titles.users}
            </Typography>
            <Grid style={{ marginTop: 20 }} container spacing={6}>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="Teacher" number="31" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="TeacherPro" number="25" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="Parent" number="32" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="Children" number="5" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="users pending verification" number="12" />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <UserCard userType="users under 18" number="3" />
              </Grid>
            </Grid>
            <Box pt={4}>
              <Copyright />
            </Box>
          </Container>
        )}
        {choice.Accounts && <UsersTab />}
        {choice.Report && (
          <>
            <Typography
              align="center"
              variant="h4"
              color="primary"
              style={{ margin: 10 }}
            >
              Online account
            </Typography>
            <UsersChart />
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    variant="h4"
                    color="primary"
                    style={{ margin: 10 }}
                  >
                    Total Accounts
                  </Typography>
                  <AccountPieChart
                    active={activeUsers.length}
                    pending={pendingUsers.length}
                  />
                </Paper>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <Paper
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    align="center"
                    variant="h4"
                    color="primary"
                    style={{ margin: 10 }}
                  >
                    Total Offers
                  </Typography>
                  <OffersChart />
                </Paper>
              </Grid>
            </Grid>
          </>
        )}
      </main>
    </div>
  );
}
