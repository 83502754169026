import { Container } from "@material-ui/core";
import React, { useEffect } from "react";
import Navbar from "../components/common/Navbar";

const Conditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar bgColor="#333" showItems={false} />
      <Container>
        <h1 style={{ textAlign: "center" }}>Conditions Générales</h1>
        <p>
          L’entreprise EURL GOLDEN CORP, (ci-après désignée par « Tutor »)
          immatriculée au Registre de Commerce sous le numéro 20B1015259-00/16
          dont le siège social est située Cyber Parc ANPT, Rahmania Alger
          propose une solution (application mobile) qui relie entre les parents
          d'élèves et les enseignants.
        </p>
        <p>
          Les présentes conditions générales (ci-après désignées par les «
          Conditions Générales d’Utilisation ») régissent les conditions
          d’utilisation l’application mobile Tutor (sur Play Store, dont EURL
          Golden Corp est éditeur, ainsi que les droits et obligations des
          Membres inscrits.
        </p>

        <p>
          En cas de différend entre Tutor et l’Utilisateur concernant la
          validité, l’exécution, l’inexécution ou l’interprétation des présentes
          Conditions Générales ou, plus généralement, les relations entre Tutor
          et l’Utilisateur, ce dernier pourra recourir à un accord en vue de la
          résolution amiable ou tout autre mode alternatif de règlement des
          différends. Il est expressément rappelé que les demandes de règlement
          amiable ne suspendent pas les délais ouverts pour intenter les actions
          judiciaires.
        </p>
        <p>
          A défaut de résolution amiable, tout différend sera soumis au tribunal
          de Chéraga, ALGER.
        </p>

        <h2> ARTICLE PRELIMINAIRE – DÉFINITIONS :</h2>
        <p>
          Membre : qui soit parents d’élèves ou professeurs. Qui s’est inscrit
          sur l’application mobile Tutor après avoir accepté les Conditions
          Générales d’Utilisation et fourni l’ensemble des informations
          demandées
        </p>
        <p>
          Acquéreur : Personne Membre parents d’élèves qui décide d’être mis en
          relation pour une séance de cours ou d’accompagnement personnalisé, en
          groupe ou à distance.
        </p>
        <p>
          Contrat : désigne les présentes conditions générales d’utilisation
        </p>
        <p>
          Compte : Compte tenu par Tutor pour chaque Membre, sur lequel sont
          notamment inscrits les Crédits dont ils disposent.
        </p>
        <p>Parties : désignent Tutor et les Membres</p>
        <p>
          Mise en relation : étape où les contacts sont partagés entre les
          membres (parents d’élèves contre professeur) portant sur le programme
          scolaire et extrascolaire pour les élèves.
        </p>
        <p>
          Utilisateur : désigne toute personne, Acquéreur ou Membre, utilisant
          l’application mobile Tutor
        </p>
        <p>
          Service : Ensemble des moyens mis en œuvre par Tutor et des
          prestations effectuées par cette dernière
        </p>
        <p>
          Application mobile : application exploitée par Tutor, accessible sur
          Play Store ou App Store et sur lequel est fourni le Service
        </p>
        <h2>ARTICLE I – OBJET :</h2>
        <p>
          Les présentes Conditions Générales d’Utilisation ont pour objet de
          définir les règles régissant la fourniture du Service par Tutor à ses
          Membres.
        </p>
        <h2>ARTICLE II – INSCRIPTION SUR L’APPLICATION MOBILE :</h2>
        <p>
          Avant toute mise en relation et réservation de session ou
          d’accompagnement de l’internaute doit procéder à son inscription sur
          l’Application mobile. La procédure d’inscription se déroule selon les
          conditions et modalités ci-dessous développées.
        </p>
        <h4>2-1. Capacité de contracter :</h4>
        <p>
          L’inscription sur l’Application mobile en tant que Membre est ouverte
          à toute personne :
        </p>
        <ul>
          <li>
            Pour les professeurs : au minimum d’un niveau universitaire ayant
            les qualifications d’enseigner (parcours et expérience à justifier
            par des attestations ou certificats de travail) aux élèves du niveau
            primaire, C.E.M et lycéen.
          </li>
          <li>
            Pour les parents d’élèves : qui soit scolarisé en public ou en
            secteur privé dans un primaire, C.E.M ou Lycée.
          </li>
        </ul>
        <h4>2-2. Fourniture de données personnelles :</h4>
        <p>L’internaute est appelé à fournir des informations le concernant:</p>
        <ul>
          <li>Le nom </li>
          <li>Le prénom</li>
          <li>La wilaya</li>
          <li>La commune</li>
          <li>La profession</li>
          <li>L'organisme de travail</li>
          <li>Les matières à enseigner ( pour les enseignants)</li>
          <li>Le timing est la disponibilité ( pour les enseignants)</li>
          <li>Les tarifs des séances ( pour les enseignants).</li>
        </ul>
        <p>
          Ces informations recueillies par Tutor, le sont dans le cadre des
          dispositions de Loi n°18-05 du 10 mai 2018 relative au commerce
          électronique.
        </p>

        <h4>2-3. Choix d’un identifiant et d’un mot de passe :</h4>
        <p>
          En s’inscrivant, chaque Membre fournira un identifiant (numero de
          telephone) et une adresse e-mail valide et un mot de passe. Les
          identifiants et mots de passe sont personnels et confidentiels,
        </p>

        <p>
          Chaque Membre est entièrement responsable de l’utilisation et de la
          préservation des identifiants et mots de passe le concernant, et donc,
          le Membre s’engage à mettre tout en œuvre pour conserver secret les
          identifiants et mots de passe le concernant et à ne les divulguer à
          quiconque.
        </p>
        <p>
          En cas de perte ou divulgation involontaire d’élément susceptible de
          permettre à un tiers de prendre connaissance des identifiants et mots
          de passe d’un Membre, devra être immédiatement signalée par écrit à
          Tutor, afin que cette dernière procède à un changement des
          identifiants et mots de passe.
        </p>

        <h4>2-4. Acceptation des Conditions Générales d’Utilisation :</h4>
        <p>
          L’internaute ne deviendra Membre qu’après avoir lu et accepté les
          présentes Conditions Générales d’Utilisation en cliquant sur le bouton
          “valider” prévu à cet effet.
        </p>
        <h2>ARTICLE III- DROIT ET OBLIGATIONS DES MEMBRES</h2>
        <p>
          En leur qualité de Membres les internautes accèderont à l’ensemble des
          offres et services proposés par Tutor.
        </p>
        <p>
          Un Membre pourra dans ces conditions acheter des séances de cours et
          des prestations d’accompagnement. Cette prestation se fera En groupe
          ou en ligne au niveau :
        </p>
        <ul>
          <li>
            Du domicile d’un des membres (Professeurs ou Parents d’élèves),
          </li>
          <li>En ligne,</li>
        </ul>
        <h4>3-1. Utilisation de l’application Mobile :</h4>
        <p>
          Le Membre reconnaît expressément que la mise en relation entre Membres
          ne peuvent s’exécuter qu’à travers de Tutor.
        </p>

        <h4>3-2. Restrictions et interdictions :</h4>
        <p>
          Il est interdit aux Membres d’envoyer, de transmettre, de diffuser la
          séance de cours issu de la mise en relation de Tutor des contenus tels
          que :
        </p>
        <ul>
          <li>
            Susceptibles de porter atteinte à la dignité de la personne humaine
            ;
          </li>
          <li>incitant à la haine raciale ou à la discrimination ;</li>
          <li>
            portant atteinte à la vie privée et/ou au droit à l’image de toute
            personne ;
          </li>
          <li>
            portant atteinte aux droits de propriété intellectuelle ou droits
            voisins d’un tiers ;
          </li>
          <li>à caractère pornographique ou pédophile ;</li>
          <li>faisant l’apologie de crimes contre l’humanité ;</li>
          <li>incitant à la commission de crimes et délits ;</li>
          <li>
            portant atteinte aux droits de producteurs de bases de données ;
          </li>
          <li>constitutifs de publicité trompeuse ou comparative ;</li>
          <li>
            faisant la publicité du tabac, de l’alcool ou de médicaments ;
          </li>
          <li>
            contenant un virus ou tout autre programme susceptible d’endommager,
            de détruire ou d’altérer les systèmes, programmes, fichiers
            informatiques ou données etc. de Tutor ou de ses Membres ;
          </li>
        </ul>
        <p>de manière générale, à caractère illicite.</p>
        <p>
          Cette interdiction s’applique également aux contenus auxquels donnent
          accès les liens hypertextes fournis par l’un des Membres sur
          l’application Tutor
        </p>
        <h3>3-4. Garantie relative aux informations fournies :</h3>
        <p>
          Chaque Membre garantit la véracité et l’exactitude des informations
          qu’il fournit.
        </p>
        <h2>ARTICLE IV – PRIX DES COURS ET PRESTATIONS D’ACCOMPAGNEMENT:</h2>
        <p>
          L’utilisation de l’application TUTOR ne donne lieu à aucune
          tarification, autrement dit le téléchargement de l’application et
          l’inscription sont totalement gratuits.
        </p>
        <p>
          L’enseignant perçoit ses rémunérations de la part des parents d'élèves
          directement, en argent comptant après que la prestation ait été
          effectuée.
        </p>
        <p>
          L’enseignant est chargé de payer une commission équivalente à 25% du
          prix de chaque rémunération.
        </p>

        <p>
          TUTOR est en période de test jusqu'au 10/08/2021, durant cette période
          l’utilisateur n'est soumis à aucune charge ou frais pour utiliser
          l’application.
        </p>
        <p>
          Les prix et les tarifs des cours sont fixés par chaque enseignant, ces
          tarifs sont négociables lors de construction de l’offre, et les deux
          parties (parent d'élève ou enseignant) ont le droit d’accepter ou de
          refuser ou bien de faire une contre-offre
        </p>
        <p>Les prix sont indiqués en dinars algérien.</p>
        <h2>ARTICLE V – MODALITÉS DE PAIEMENT :</h2>
        <p>
          La mise en relation et de prestations d’accompagnement s’effectue en
          ligne, par virement bancaire de l’entreprise commercialisant Tutor :
          N°005 001580000003293 clé 69 ouvert auprès de la Banque B.D.L Agence
          STAOUELI 1 - 00158 ALGER.
        </p>
        <h2>ARTICLE VI – DURÉE :</h2>
        <p>
          Les Conditions Générales demeurent en vigueur jusqu'à la fermeture du
          compte de l'Utilisateur.
        </p>
        <h2>Article VII Résiliation</h2>
        <p>
          La résiliation du contrat liant le Membre ne donne pas le droit au
          remboursement des crédits non utilisés
        </p>
        <h2>ARTICLE VIII – RESPONSABILITÉ :</h2>
        <h3>
          9-1. Responsabilité lors de la fourniture par les Membres de leurs
          coordonnées :
        </h3>
        <p>
          Il est de la responsabilité des Membres de s’assurer que les
          coordonnées qu’ils fournissent sont correctes. En aucun cas, la
          responsabilité de Tutor ne pourra être retenue si, par exemple, un
          Membre Acquéreur ne reçoit pas le cours ou l’accompagnement souhaité,
          en raison d’une erreur de son fait dans la saisie de ses coordonnées
        </p>
        <h3>
          9-2. Responsabilité quant à la divulgation des identifiants et mots de
          passe :
        </h3>
        <p>
          Tutor ne pourra en aucun cas être responsable des dommages liés à la
          perte ou à la divulgation des identifiants et mots de passe propres à
          chaque Membre.
        </p>
        <h2>ARTICLE IX – MISE EN GARDE :</h2>
        <h3> 10-1. Prérequis à l’utilisation de l’Application Tutor :</h3>
        <p>
          Le Service de mise en relation des Acquéreurs de cours ou
          d’accompagnement n’est accessible qu’aux personnes disposant d’un
          téléphone smartphone suffisant leur permettant notamment d’être mis en
          contact.
        </p>
        <p>Les Membres reconnaissent en avoir pris connaissance.</p>
        <h3>10-2. Limites de l’Internet :</h3>
        <p>
          Les Membres déclarent bien connaître Internet, ses caractéristiques et
          ses limites et reconnaissent :
        </p>
        <p>
          Que les transmissions de données sur Internet ne bénéficient pas d’une
          fiabilité technique absolue, celles-ci circulant sur des réseaux
          hétérogènes aux caractéristiques et capacités techniques diverses, qui
          sont parfois saturés à certaines heures de la journée ;
        </p>
        <p>
          Que les données circulant sur Internet ne sont pas protégées contre
          des détournements éventuels, et qu’ainsi la communication de toute
          information est effectuée par les Membres à leurs risques et périls.
        </p>
        <h2>
          ARTICLE X – MODIFICATION DES CONDITIONS GÉNÉRALES D’UTILISATION :
        </h2>
        <p>
          Les présentes Conditions Générales d’Utilisation sont susceptibles
          d’être modifiées, à tout moment, par Tutor. La nouvelle version des
          Conditions Générales d’Utilisation sera communiquée par e-mail aux
          Membres concernés et entrera en vigueur dans les trois (3) jours
          suivant cet envoi. A défaut d’acceptation de cette nouvelle version,
          les Membres auront la possibilité de se désinscrire et de résilier
          leur contrat par simple notification adressée à Tutor (par e-mail).
          Au-delà du délai susmentionné et en l’absence de manifestation des
          Membres, la nouvelle version des Conditions Générales d’Utilisation
          sera considérée comme acceptée par les Membres concernés.
        </p>
        <h2>ARTICLE XI– UTILISATION DE L’APPLICATION MOBILE :</h2>
        <h3>12-1. Dysfonctionnement de l’application mobile :</h3>
        <p>
          Tutor n’est pas responsable si l’internaute ne peut accéder à
          l’application mobile, en raison notamment d’opérations de maintenance
          ou si l’accès est interrompu, à quelque moment que ce soit, ou si un
          quelconque défaut, indépendant de sa volonté, empêche de réaliser une
          opération. Tutor ne garantit pas que l’application mobile soit exempte
          de virus informatiques ni d’autres anomalies indépendantes de sa
          volonté.
        </p>
        <h3>12-2. Disponibilité et accès à l’application mobile:</h3>
        <p>
          Tenue à une obligation de moyen et non de résultat, Tutor s’engage à
          tout mettre en œuvre pour garantir un accès permanent à l’application
          mobile 7/7 jours et 24/24 heures, sous réserve des périodes de
          maintenance et des pannes éventuelles.
        </p>
        <p>
          Toute interruption pour des travaux de maintenance dont la durée
          prévisible est supérieure à 24 heures sera signalée aux Membres par
          courrier électronique vingt-quatre (24) heures à l’avance.
        </p>
        <h2>ARTICLE XII – DONNÉES PERSONNELLES :</h2>
        <p>
          Tutor invite expressément l’Utilisateur à consulter sa Politique
          relative à la protection des Données Personnelles qui fait partie
          intégrante des présentes CGU.
        </p>
        <p>
          Le traitement des données personnelles de chaque Utilisateur est
          effectué par Tutor, qui s’engage à assurer un niveau de protection
          suffisant des informations fournies pour garantir le respect de la vie
          privée et des libertés et droits fondamentaux des personnes.
        </p>
        <p>
          Chaque Utilisateur dispose d’un droit d’opposition, d’accès, de
          modification, de rectification et de suppression des données qui le
          concernent. Chaque Utilisateur peut exercer ces droits en adressant au
          responsable du traitement des données de Tutor par e-mail.
        </p>
        <p>
          Sur réquisition de l’autorité publique et dans les conditions définies
          par la réglementation en vigueur, Tutor pourra être amenée à fournir
          des données personnelles concernant l’un des Membres.
        </p>
        <h3>13.1 Traitements de données personnelles</h3>
        <p>
          Les données personnelles pouvant être collectées sur l’application
          mobile sont les suivantes :
        </p>
        <p>
          Création de compte/profil : sont notamment enregistrées, lors de la
          création de compte/profil, les nom, prénom, date de naissance, adresse
          postale, adresse électronique, numéro de téléphone ainsi que les
          données de connexion.
        </p>
        <p>
          Connexion au site : à cette occasion, sont notamment enregistrées, les
          données de connexion, de navigation ou encore de localisation.
        </p>
        <p>
          Informations en provenance de tiers : Certaines données (en
          particulier les nom, prénom et coordonnées téléphoniques et/ou
          postales) sont susceptibles d’avoir été transmises, avec l’accord
          préalable de l’Utilisateur, par les partenaires du Site. Dans ce cas,
          le traitement des données personnelles sera régi par les présentes.
        </p>
        <p>
          Contact : Lors du remplissage du formulaire de contact, sont collectés
          les nom, prénom, adresse électronique et message.
        </p>
        <p>
          Cookies : Les Cookies sont utilisés, dans le cadre de l’utilisation du
          Site, afin de collecter certaines informations sur l’Utilisateur (en
          particulier adresse IP, informations relatives à l’ordinateur utilisé
          pour la navigation, mode de connexion, système d’exploitation, pages
          consultées sur l’application mobile).
        </p>
        <p>
          Les utilisations des données personnelles sont principalement les
          suivantes :
        </p>
        <ul>
          <li>accès et utilisation de l’application mobile;</li>
          <li>
            vérification et authentification des données des Utilisateurs ;
          </li>
          <li>
            optimisation de l’agencement et du fonctionnement de l’application
            mobile ;
          </li>
          <li>
            lutte contre les fraudes, utilisations abusives, virus et autres
            logiciels malveillants ;
          </li>
          <li>gestion de la relation avec les Utilisateurs ;</li>
          <li>
            mise en place et gestion d’un espace de communication entre les
            Utilisateurs de l’application mobile,
          </li>
          <li>fourniture d’une assistance Utilisateurs ;</li>
          <li>gestion des services de paiement ;</li>
          <li>
            fourniture de contenus et services personnalisés, en fonction de
            l’historique de navigation, des préférences et centres d’intérêts
            des Utilisateurs ;
          </li>
          <li>
            envoi d’informations commerciales et de messages publicitaires, en
            fonction de l’historique de navigation, des préférences et centres
            d’intérêts des Utilisateurs;
          </li>
        </ul>
        <p>
          Lorsque certaines informations sont obligatoires pour accéder à des
          fonctionnalités spécifiques de l’application mobile, ce caractère
          obligatoire est indiqué au moment de la saisie des données. En cas de
          refus de la part de l’Utilisateur de fournir des informations
          obligatoires, il pourra ne pas avoir accès à certains services,
          fonctionnalités ou rubriques du Site.
        </p>

        <h2>ARTICLE XIII– RÈGLEMENT DES LITIGES – DROIT APPLICABLE :</h2>
        <h3>14-1. Loi applicable :</h3>
        <p>
          Dans l’hypothèse où un Membre serait de nationalité étrangère, il est
          convenu que le contrat conclu entre ce dernier et Tutor restera soumis
          à la loi Algérienne. En conséquence, la version des Conditions
          Générales d’Utilisation en langue française fait foi.
        </p>
        <h3>14-2. Tribunal compétent :</h3>
        <p>
          Dans l’hypothèse où le Membre serait commerçant ou de nationalité
          étrangère, il est convenu que tous différends relatifs à la formation,
          la validité, l’interprétation, l’exécution et l’extinction du contrat
          conclu entre ce dernier et Tutor et que les parties ne pourraient
          résoudre à l’amiable, seront soumis au TRIBUNAL DE CHERAGA, ALGER.
          Cette clause par accord exprès des parties s’applique également en cas
          de procédure en référé, de pluralité de défendeurs ou d’appel en
          garantie.
        </p>
      </Container>
    </>
  );
};

export default Conditions;
