import React, { createContext, Dispatch, useEffect, useState } from "react";

export const LngContext = createContext<any>({
  lng: "",
  setLng: () => {},
});
export const LngProvider = ({ children }) => {
  const [lng, setLanguage] = useState("fr");
  const setLng = (lng) => {
    setLanguage(lng);
    localStorage.setItem("lng", lng);
  };

  useEffect(() => {
    const getLng = async () => {
      const storedLng = await localStorage.getItem("lng");
      if (storedLng) setLanguage(storedLng);
    };
    getLng();
  }, []);
  return (
    <LngContext.Provider
      value={{
        lng,
        setLng,
      }}
    >
      {children}
    </LngContext.Provider>
  );
};

export const LngConsumer = LngContext.Consumer;
