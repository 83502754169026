import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  Link,
  MenuItem,
  Container,
  FormControl,
  InputLabel,
  Select,
  Menu,
  Box,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Translate from "@material-ui/icons/Translate";
import {
  createStyles,
  makeStyles,
  withStyles,
  Theme,
} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "../../assets/img/logo_footer.svg";
import logoColored from "../../assets/img/Logo.svg";
import InputBase from "@material-ui/core/InputBase";
import { useLng } from "../../hooks/lng";
import { getContent } from "../../content/getContent";
import { Breakpoint } from "@material-ui/core/styles/createBreakpoints";
import { Link as ScrollLink } from "react-scroll";
import { useUser } from "../../hooks/user";
import ProfilMenu from "./ProfilMenu";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "transparent",

    textTransform: "capitalize",
    color: "#FEFEFE",
  },
  logo: {
    fontWeight: 600,
    color: "#333",
    textAlign: "left",
  },
  menuButton: {
    size: "18px",
    marginLeft: "0px",
    textTransform: "capitalize",
    color: "white",
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  drawerContainer: {
    padding: 30,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  menuItem: {
    fontSize: "0.8rem",
  },
}));

// type SupportedLocales = keyof typeof locales;

export default function Header({ bgColor = null, showItems = true }) {
  const classes = useStyles();
  const { lng, setLng } = useLng();

  const buttons = getContent("translation", "buttons", lng);
  const headersData = [
    {
      label: buttons.about,
      id: "about",
    },
    {
      label: buttons.howItWorks,
      id: "how",
    },
    {
      label: buttons.feats,
      id: "feats",
    },
    {
      label: buttons.stats,
      id: "stats",
    },
  ];

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  // const [locale, setLocale] = React.useState<SupportedLocales>('frFR');
  const { mobileView, drawerOpen } = state;

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setLang(event.target.value as string);
  // };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={classes.toolbar}>
        {tutorLogo}
        {showItems ? <div>{getMenuButtons(headersData)}</div> : <div></div>}
      </Toolbar>
    );
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(lng: string) {
    // also handles language change

    setLng(lng);
    setAnchorEl(null);
  }
  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar disableGutters>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {showItems ? (
            <IconButton
              {...{
                edge: "start",
                color: "inherit",
                "aria-label": "menu",
                "aria-haspopup": "true",
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon />
            </IconButton>
          ) : (
            <div></div>
          )}
        </div>
        <Link
          {...{
            component: RouterLink,
            to: ".",
            color: "inherit",
            style: { textDecoration: "none" },
          }}
        >
          {tutorLogo}
        </Link>
        <div
          style={{
            marginLeft: lng === "ar" ? "" : "auto",
            marginRight: lng !== "ar" ? "" : "auto",
          }}
        >
          {" "}
          {languageMenu()} <ProfilMenu />
        </div>

        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={classes.drawerContainer}>
            {showItems ? (
              <div>{getDrawerChoices(headersData)}</div>
            ) : (
              <div></div>
            )}
          </div>
        </Drawer>
      </Toolbar>
    );
  };

  function getDrawerChoices(headersData) {
    return (
      <Fragment>
        <Box py={4}>
          <RouterLink to="/">
            <img style={{ height: 40 }} src={logoColored} alt="TUTOR"></img>
          </RouterLink>
        </Box>

        {headersData.map(({ label, id }) => {
          return (
            <ScrollLink
              {...{
                activeClass: label === "home" ? "active" : null,
                to: id,
                color: "inherit",
                style: { textDecoration: "none" },
                key: label,
                spy: true,
                smooth: true,
              }}
            >
              <MenuItem>{label}</MenuItem>
            </ScrollLink>
          );
        })}
      </Fragment>
    );
  }

  function languageMenu(color = "white") {
    return (
      <Fragment>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.menuButton}
          endIcon={
            lng === "fr" ? (
              <img
                style={{ height: 16 }}
                src={require("../../assets/img/france.png").default}
              />
            ) : lng === "en" ? (
              <img
                style={{ height: 16 }}
                src={require("../../assets/img/united-kingdom.png").default}
              />
            ) : (
              <img
                style={{ height: 16 }}
                src={require("../../assets/img/algeria.png").default}
              />
            )
          }
          style={{ color: color }}
        >
          {lng === "fr" && (mobileView ? <Translate /> : "français")}
          {lng === "en" && (mobileView ? <Translate /> : "english")}
          {lng === "ar" && (mobileView ? <Translate /> : " العربية ")}
        </Button>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => handleClose(lng)}
        >
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => handleClose("fr")}
            id="fr"
          >
            {
              <img
                style={{ height: 16 }}
                src={require("../../assets/img/france.png").default}
              />
            }{" "}
            <span style={{ paddingLeft: 10 }}>Français</span>
          </MenuItem>
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => handleClose("en")}
            id="en"
          >
            <img
              style={{ height: 16 }}
              src={require("../../assets/img/united-kingdom.png").default}
            />
            <span style={{ paddingLeft: 10 }}>English</span>
          </MenuItem>
          <MenuItem
            classes={{ root: classes.menuItem }}
            onClick={() => handleClose("ar")}
            id="ar"
          >
            <img
              style={{ height: 16 }}
              src={require("../../assets/img/algeria.png").default}
            />
            <span style={{ paddingLeft: 10 }}>عربية</span>
          </MenuItem>
        </Menu>
      </Fragment>
    );
  }

  const tutorLogo = (
    <div className={classes.logo}>
      <RouterLink to="/">
        <img src={logo} alt="TUTOR"></img>
      </RouterLink>
    </div>
  );

  function getMenuButtons(headersData) {
    return (
      <>
        {headersData.map(({ label, id }) => {
          return (
            <Button
              {...{
                key: label,
                color: "inherit",
                className: classes.menuButton,
              }}
            >
              <ScrollLink
                {...{
                  activeClass: label === "home" ? "active" : null,
                  to: id,
                  color: "inherit",
                  style: { textDecoration: "none" },
                  spy: true,
                  smooth: true,
                }}
              >
                {label}
              </ScrollLink>
            </Button>
          );
        })}

        {languageMenu()}
        <ProfilMenu />
      </>
    );
  }

  return (
    <AppBar
      position="relative"
      elevation={0}
      className={classes.header}
      style={{ backgroundColor: bgColor ? "#333" : "transparent" }}
    >
      <Container>{mobileView ? displayMobile() : displayDesktop()}</Container>
    </AppBar>
  );
}
