import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import { LngProvider } from "./context/lngContext";
import App from "./App";

ReactDOM.render(
  <Router>
    <LngProvider>
      <App />
    </LngProvider>
  </Router>,

  document.querySelector("#root")
);
