import React, {
  createContext,
  useEffect,
  useMemo,
  useState,
  useCallback,
} from "react";
import axios from "axios";
import { useLng } from "../hooks/lng";
import { getErrorForField } from "../content/getContent";
import { Typography } from "@material-ui/core";

const storageKey = "user-access-token";
const { REACT_APP_API_URL } = process.env;
const loginContent = "logIn";
export const transformUser = (user, accessToken) => {
  return {
    accessToken: accessToken,
    firstName: user?.first_name,
    lastName: user?.last_name,
    email: user?.email,
    phoneNumber: user?.phone_number,
    city: user?.city,
    address: user?.address,
    state: user?.state,
    verified: user?.verified,
    verified_at: user?.verified_at,
    isUnder18: user?.is_under_18,
    isProfessional: user?.is_professional,
    finishedOnBoarding: user?.finished_on_boarding,
    skippedOnBoarding: user?.skipped_on_boarding,
    role: user?.role,
    uuid: user?.uuid,
    isTarget: user?.isTarget,
  };
};

export const userContext = createContext<any>({
  user: null,
  userLoading: true,
  loginUser: () => {},
});
export const UserProvider = ({ children }) => {
  const [apiErrors, setApiErrors] = useState([]);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const { lng } = useLng();

  const loginUser = async (user, accessToken) => {
    try {
      const currentUser = transformUser(user, accessToken);
      const result = await localStorage.setItem(storageKey, accessToken);
      setUser(currentUser);
      setLoading(false);
    } catch (e) {
      console.error("ERROR SETTING THE STORAGE", e);
      const response = e?.response?.data?.errors || [];
      const messages = response.map((el) => {
        setLoading(false);
        return getErrorForField(loginContent, el.field, el.code, lng);
      });
      setApiErrors(messages);
    }
  };
  const logoutUser = async () => {
    try {
      const result = await localStorage.removeItem(storageKey);
      setUser(null);
    } catch (e) {
      console.error("ERROR SETTING THE STORAGE", e);
      const response = e?.response?.data?.errors || [];
      const messages = response.map((el) => {
        return getErrorForField(loginContent, el.field, el.code, lng);
      });
      setApiErrors(messages);
    }
  };
  const doLogout = useCallback(async () => {
    try {
      const response = await axios.delete(`${REACT_APP_API_URL}/ouath/logout`, {
        headers: {
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      logoutUser();
    } catch (e) {
      logoutUser();
    }
  }, []);
  const logoutHandler = useCallback(() => {
    doLogout();
  }, [lng]);
  useEffect(() => {
    async function fetchData() {
      try {
        const accessToken = await localStorage.getItem(storageKey);
        if (accessToken) {
          const response = await axios.get(`${REACT_APP_API_URL}/users/me`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          const user = transformUser(response.data, accessToken);
          setUser(user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      } catch (e) {
        console.error("error", e);
        localStorage.removeItem(storageKey);
        setUser(null);
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  const resetUser = useCallback(
    (user, accessToken) => {
      const newUser = transformUser(user, accessToken);
      setUser(newUser);
    },
    [transformUser, setUser]
  );
  const memoizedChildren = useMemo(() => children, [children]);
  const renderErrors = useCallback(() => {
    return apiErrors.map((msg, index) => {
      return (
        <Typography key={index} color="error" variant="h6">
          {msg}
        </Typography>
      );
    });
  }, [apiErrors]);
  return (
    <div>
      <div>{renderErrors()}</div>
      <userContext.Provider
        value={{
          user: user,
          loginUser: loginUser,
          userLoading: loading,
          logoutHandler: logoutHandler,
          resetUser: resetUser,
        }}
      >
        {memoizedChildren}
      </userContext.Provider>
    </div>
  );
};

export const UserConsumer = userContext.Consumer;
