import React, { Fragment, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Grid,
  Collapse,
  Container,
  Hidden,
} from "@material-ui/core";
import HowData from "../content/howData.json";

import welcomePhone from "../assets/img/phoneSignUp.png";
import teacherPhone from "../assets/img/phoneFindTeachers.png";
import teacherPhone2 from "../assets/img/phoneFindTeachers2.png";
import teacherPhone3 from "../assets/img/phoneFindTeachers3.png";
import gplay from "../assets/badges/google_play/en.png";
import appstore from "../assets/badges/app_store/black/en.svg";
import useHover from "../hooks/useHover";
import { getField, getContent } from "../content/getContent";
import { useLng } from "../hooks/lng";
import Slider from "react-slick";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/slickConfig.css";

//todo: Make a container for the how to
// todo: change screens depending on the active card. animate it, transition top to bottom
// animate either using workflow, or something simpler
// animation @-webkit-keyframes pulsate does not work with useStyles, needs a css :/
// todo: make it responsive: [theme.breakpoints.between("sm", "md")]:
// stepper should contain card + phone for each element when xs.
// change component based on media size

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    section: {
      background: "rgb(240 241 245)",
      paddingTop: "3rem",
      paddingBottom: "3rem",
      overflowX: "hidden",
    },
    howContainer: {},
    contentBox: {
      minHeight: 100,

      cursor: "pointer",
    },
    illustration: {
      display: "block",
      margin: "0% 0%",
      transition: "all 1s ease-out",
      [theme.breakpoints.between("sm", "md")]: {
        // width:"40%",
      },
      "& img": {
        height: "650px",
      },
    },
    dots: {
      "& li.slick-active button::before": {
        width: 8,
        height: 8,
        borderRadius: "50%",
        background: "#fff",
        color: "#45C7F0",
        boxShadow: "0px 0px 10px 2px rgba(69, 199, 240, 0.5)",
      },
      "& li": {
        "& button::before": {
          width: 8,
          height: 8,
          borderRadius: "50%",
          background: "#fff",
          color: "#C4C4C4",
        },
      },
    },
    slider: {
      "& .slick-slider> .slick-prev": {
        zIndex: 100,
      },
      "& .slick-slider> .slick-next": {
        zIndex: 100,
      },
      "& .single-item-rtl .slick": {
        rtl: true,
      },
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      background: "#C4C4C4",
      cursor: "pointer",
    },
    circleIcon: {
      position: "relative",
      background: "#FFF",
      display: "flex",
      height: 32,
      width: 32,
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
    },
    activeCircle: {
      width: 8,
      height: 8,
      borderRadius: "50%",
      background: "#45C7F0",
      boxShadow: "0px 0px 10px 2px rgba(69, 199, 240, 0.5)",
    },
    active: {
      padding: 10,
      border: "1px solid rgba(244,252,255,0)",
      borderRadius: "7px",
      backgroundColor: "#F4FCFF",
      transition: "all 300ms cubic-bezier(.13,.44,.92,.6)",
    },
    notActive: {
      padding: 10,
      border: "1px solid rgba(244,252,255,0)",
      borderRadius: "7px",
      "&:hover": {
        border: "1px solid rgba(244,252,255,0.9)",
        backgroundColor: "rgba(244,252,255,0.65)",
        transition: "all 600ms cubic-bezier(.13,.44,.92,.6)",
      },
    },

    heading: {
      position: "relative",
      display: "inline-block",
      margin: "auto",
      marginBottom: "3rem",
      zIndex: 2,
    },
    badges: {
      margin: "auto 0%",
      textAlign: "left",
      justifyContent: "right",
      "& a": {
        display: "block",
        padding: "5px 0px 5px 0px",
      },
    },
    mobileSlide: {
      height: "80hv",
      // media breakdown is better SM is too big xs is too small
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    desktopHow: {
      display: "inline",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default function HowItWorks() {
  const classes = useStyles();
  const { lng } = useLng();
  const how = getContent("home_", "how", lng);
  const content = [HowData.create, HowData.exp1, HowData.exp2, HowData.exp3];
  const phoneImages = [
    welcomePhone,
    teacherPhone,
    teacherPhone2,
    teacherPhone3,
  ];
  const [activeScreen, setScreen] = useState(phoneImages[0]);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["step1", "step2", "step3", "step4"];

  const [slide, setSlide] = useState({ currentSlide: 0 });
  //todo: steps will be context based
  //todo: make slides the same height
  // center the buttons, there is a left offset
  // add a line behind the buttons to mimic desktop style
  // todo!:make the slider takes at max 100% of vh, should display everything.

  const handleStep = (step: number) => () => {
    setScreen(phoneImages[step]);
    setActiveStep(step);
  };
  const chevRight = (
    <ChevronRight
      color="primary"
      fontSize={"large"}
      style={{ zIndex: 10000 }}
    />
  );
  const chevLeft = (
    <ChevronLeft color="primary" fontSize={"large"} style={{ zIndex: 10000 }} />
  );

  const settings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    rtl: lng === "ar",
    nextArrow: lng === "ar" ? chevLeft : chevRight,
    prevArrow: lng === "ar" ? chevRight : chevLeft,
    // dotsClass: `slick-dots ${classes.dots}`,
  };
  return (
    <Fragment>
      <section className={classes.section}>
        <Container>
          <Box style={{ textAlign: "center" }}>
            <Typography
              align="center"
              className={classes.heading}
              variant="h4"
              component="h2"
            >
              {how.title}
            </Typography>
          </Box>
          <Hidden smDown>
            <Grid container className={classes.howContainer}>
              <Grid
                container
                xs={12}
                sm={6}
                justify="center"
                alignItems="center"
              >
                <Box className={classes.illustration}>
                  <img src={activeScreen} />
                </Box>
              </Grid>
              <Grid item xs={12} sm={6}>
                {steps.map((item, index: number) => {
                  return (
                    <Box
                      key={index}
                      onClick={handleStep(index)}
                      className={classes.contentBox}
                      // this checks if last one to not add a border
                      style={{
                        borderLeft:
                          index !== content.length - 1 && lng !== "ar"
                            ? "2px solid #C4C4C4"
                            : "none",
                        borderRight:
                          index !== content.length - 1 && lng === "ar"
                            ? "2px solid #C4C4C4"
                            : "none",
                        paddingLeft: lng !== "ar" ? 30 : 0,
                        paddingRight: lng === "ar" ? 30 : 0,
                      }}
                    >
                      <Box
                        className={classes.circleIcon}
                        style={{
                          position: "relative",
                          top: 0,
                          left: lng !== "ar" ? -30 : null,
                          right: lng === "ar" ? -63 : null,
                          transform: "translate(-50%, -50%)",
                        }}
                      >
                        <Box
                          className={
                            index === activeStep
                              ? classes.activeCircle
                              : classes.circle
                          }
                        ></Box>
                      </Box>
                      <Box
                        className={
                          index === activeStep
                            ? classes.active
                            : classes.notActive
                        }
                        style={{ marginTop: "-52px", padding: 30 }}
                      >
                        <Typography
                          style={{
                            textAlign: lng === "ar" ? "right" : "left",
                            fontWeight: 700,
                          }}
                          gutterBottom
                          component="h6"
                          color="primary"
                        >
                          {how[item].title}
                        </Typography>
                        <Typography
                          gutterBottom
                          variant="h5"
                          component="h2"
                          style={{
                            fontWeight: 700,
                            textAlign: lng === "ar" ? "right" : "left",
                          }}
                        >
                          {how[item].text}
                        </Typography>
                        <Collapse in={index === activeStep} collapsedHeight={0}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                            component="p"
                            style={{
                              textAlign: lng === "ar" ? "right" : "left",
                            }}
                          >
                            {how[item].text}
                          </Typography>
                        </Collapse>
                      </Box>
                      <Box height="50px"></Box>
                    </Box>
                  );
                })}
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Box className={classes.slider}>
              <Slider {...settings}>
                {steps.map((item, index) => {
                  return (
                    <div key={index} style={{ textAlign: "center" }}>
                      <div style={{ textAlign: "center" }}>
                        <Typography
                          style={{ textAlign: "center", fontWeight: 700 }}
                          gutterBottom
                          component="h6"
                          color="primary"
                        >
                          {how[item].title}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="textSecondary"
                          component="p"
                          style={{
                            textAlign: "center",
                          }}
                        >
                          {how[item].text}
                        </Typography>
                      </div>
                      <img
                        style={{ margin: "30px auto 0px auto", height: 400 }}
                        src={phoneImages[index]}
                      />
                    </div>
                  );
                })}
              </Slider>
            </Box>
          </Hidden>
        </Container>
      </section>
    </Fragment>
  );
}
