import { keys } from "@material-ui/core/styles/createBreakpoints";
import home from "./home";
import home_ from "./home_";
import posts from "./posts";
import translation from "./translation";
import adminPanel from './adiminPanel'
import logIn from './logIn'

const unknowError = {
  required: {
    fr: "Veuillez saisir ce champ",
    en: "Please fill this field",
  },

  invalid: {
    fr: "Ce Champ nest pas valid",
    en: "Invalid field",
  },
  unique: {
    fr: "Saisir une autre valeur",
    en: "Try another one",
  },

  nope: {
    fr: "Error inconnue",
    en: "Unknown Error",
  },
};
const getObject = (name: string) => {
  switch (name) {
    case "home":
      return home;
    case "home_":
      return home_;
    case "posts":
      return posts;
    case "logIn" : 
      return logIn;
    case "translation":
        return translation;
        case "adminPanel" :
          return adminPanel
    default:
      return unknowError;
    case "UnknownError":
          return unknowError;
   
  }
};

export const getField = (name: string, key: string, lng: string) => {
  try {
    const object = getObject(name);
    const field = object[key].label[lng];
    return field;
  } catch {
    return "Unknown";
  }
};


export const getContent = (page: string, key: string, lang: string) => {
  try {
    const object = getObject(page);
    const field = object[lang][key];
    return field;
  } catch {
    return "Unknown";
  }
};
export const getErrorForField = (name, key, action, lng) => {
  try {
    const object = getObject(name);
    const error = object[lng].action[key];
    return error;
  } catch {
    return getField('UnknownError', action, lng);
  }
};