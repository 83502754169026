import { Card } from "@material-ui/core";
import React, { PureComponent, useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import { useUser } from "../../hooks/user";
import axios from "axios";

const COLORS = ["#4caf50", "#ffc400", "#d50000"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function OffersChart() {
  const { user } = useUser();
  const accessToken = user?.accessToken;
  const { REACT_APP_API_URL } = process.env;
  const [offers, setOffers] = useState({
    activeOffers: "",
    acceptedOffers: "",
    rejectedOffers: "",
  });
  const [activeOffers, setActiveOffers] = useState(0);
  const [acceptedOffers, setAcceptedOffers] = useState(0);

  const [rejectedOffers, setRejectedOffers] = useState(0);

  const fetchActiveOffers = async () => {
    try {
      if (accessToken) {
        const offersActive = await axios.get(`${REACT_APP_API_URL}/offers`, {
          params: {
            active: true,
            accepted: false,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (offersActive.data) {
          setActiveOffers(offersActive.data["records"].length);
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  const fetchAcceptedOffers = async () => {
    try {
      if (accessToken) {
        const offersAccepted = await axios.get(`${REACT_APP_API_URL}/offers`, {
          params: {
            active: true,
            accepted: true,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (offersAccepted.data) {
          setAcceptedOffers(offersAccepted.data["records"].length);
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  const fetchRejectedOffers = async () => {
    try {
      if (accessToken) {
        const offersRejected = await axios.get(`${REACT_APP_API_URL}/offers`, {
          params: {
            active: false,
            accepted: false,
          },
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (offersRejected.data) {
          setRejectedOffers(offersRejected.data["records"].length);
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };
  useEffect(() => {
    fetchActiveOffers();
    fetchAcceptedOffers();
    fetchRejectedOffers();
  }, [accessToken]);
  const data = [
    { name: "Total Offers accepted", value: acceptedOffers },
    { name: "Total Offers pending", value: activeOffers },
    { name: "Total Offers rejected", value: rejectedOffers },
  ];

  return (
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        cx="50%"
        cy="50%"
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={180}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );
}
