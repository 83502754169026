import React, { PureComponent } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const data = [
  {
    name: "2021/05/29",
    number: 50,
  },
  {
    name: "2021/05/30",
    number: 70,
  },
  {
    name: "2021/05/31",
    number: 90,
  },
  {
    name: "2021/06/01",
    number: 100,
  },
  {
    name: "2021/06/03",
    number: 40,
  },
  {
    name: "2021/06/05",
    number: 60,
  },
  {
    name: "2021/06/06",
    number: 200,
  },
];

export default class UserChart extends PureComponent {
  render() {
    return (
      <ResponsiveContainer width="100%" height="50%">
        <BarChart
          width={500}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          barSize={20}
        >
          <XAxis
            dataKey="name"
            scale="point"
            padding={{ left: 10, right: 10 }}
          />
          <YAxis />
          <Tooltip />
          <Legend />
          <CartesianGrid strokeDasharray="3 3" />
          <Bar dataKey="number" fill="#8884d8" background={{ fill: "#eee" }} />
        </BarChart>
      </ResponsiveContainer>
    );
  }
}
