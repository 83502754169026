export default {
  heroTitle: {
    label: {
      fr: "Trouvez le meilleur enseignant pour votre enfant.",
      en: "Find the best teacher for your child.",
      ar: "ابحث عن أفضل معلم لطفلك.",
    },
  },
  courses: {
    label: {
      fr: "Cours particuliers",
      en: "Private lessons",
      ar: "دروس خصوصية",
    },
  },
  control: {
    label: {
      fr: "Contrôle",
      en: "Control",
      ar: "مراقبة",
    },
  },
  suivi: {
    label: {
      fr: "Suivi",
      en: "following",
      ar: "متابعة",
    },
  },
  aboutText: {
    label: {
      fr:
        "C'est une application p2p  de mise en relation entre les enseignants et les parents d'élèves, grâce à cette application vous pouvez trouver des enseignants qui donnent les cours particuliers, assurer un contrôle bien précis du progrès de votre enfant et maintenir un  bon suivi avec le feedback donné par les enseignants.TUTOR n'est pas seulement pour les parents et leurs enfants, mais aussi pour vous les enseignants, cette application vous donne l'opportunité pour donner les cours particuliers.",
      en:
        "It is a p2p application for connecting teachers and parents of students, thanks to this application you can find teachers who give private lessons, ensure precise control of your child's progress and maintain good follow-up. with feedback from teachers.TUTOR is not only for parents and their children, but also for you teachers, this app gives you the opportunity to give private lessons.",
      ar:
        "إنه تطبيق p2p لربط المعلمين وأولياء أمور الطلاب ، بفضل هذا التطبيق ، يمكنك العثور على مدرسين يقدمون دروسًا خاصة ، ويضمنون التحكم الدقيق في تقدم طفلك ويحافظ على متابعة جيدة مع تعليقات المعلمين.TUTOR ليس مخصصًا للآباء وأطفالهم فقط ، ولكن أيضًا للمعلمين ، يمنحك هذا التطبيق الفرصة لإعطاء دروس خصوصية.",
    },
  },
  aboutTutorTitle: {
    label: {
      fr: "À propos de TUTOR",
      en: "About TUTOR",
      ar: "TUTOR حول",
    },
  },
  step1: {
    label: {
      fr: "Connectez-vous ou créez un nouveau compte",
      en: "Pour commencer l'aventure, c'est la 1er étape !",
      ar: "لبدء المغامرة ، إنها الخطوة الأولى!",
    },
  },
  step1Text: {
    label: {
      fr: "Pour commencer l'aventure, c'est la 1ère étape!",
      en: "To start the adventure, it's the 1st step!",
      ar: "إنها الخطوة الأولى لبدء المغامرة!",
    },
  },
  step2: {
    label: {
      fr: "Trouver un enseignant",
      en: "step en 2 select",
      ar: "2 كمل",
    },
    text: {
      fr: "Après l'authentification, vous pouvez chercher et trouver votre enseignant",
      en: "",
      ar: "",
    }
  },
  step3: {
    label: {
      fr: "Composez une offre",
      en: "step en 3 offer",
      ar: "3 كيف",
    },

  },
  step3Text: {
    label: {
      fr: "Une fois vous trouvez l'enseignant favorable, vous pouvez le contacter en composant une offre personnalisée, négociable.",
      en: "",
      ar: "",
    },
  },
  step4: {
    label: {
      fr: "Contrôlez et suivez le progrès",
      en: "step en 4 learn",
      ar: "4 تعمل",
    },
    text: {
      fr: "Nous avons développé tout un système qui permet d'assurer un contrôle et un suivi bien précis du progrès de votre enfant.",
      en: "",
      ar: "",
    }
  },
  howItWorksTitle: {
    label: {
      fr: "Comment ça marche",
      en: "How it works",
      ar: "كيف تعمل",
    },
  },
  stats: {
    label: {
      fr: "Quelques chiffres importants",
      en: "How it works",
      ar: "كيف تعمل",}
  },
  apiResponses: {
    incorrect_username_or_password: {
      fr: 'Email (ou numéro de téléphone) mot pass est incorrect',
      en: 'Incorrect email (or phone number)or password',
    },
    email_not_verified: {
      fr: 'Vous devez verifier votre email pour pouvoir vos connectez.',
      en: 'You must verify your email to access your account',
    },
  },

};

