export default {
    fr: {
      label: {
        email: "Adresse Mail",
        password: "Mot de passe",
        logIn :"S'identifer",
        LogOut :'Se Déconnecter',
        connection :'Se Connecter'
 
      },
      required :{
          email : 'Veuillez saisir votre email',
          password :'Veuillez saisir votre mot de passe'
      },
      invalid : {
          email : "Votre adresse mail n'est pas valid",
          password :"Votre mot de passe n'est pas valide"
      }

   
    },
    en: {
        label: {
            email: "Email adress",
            password: "Password",
            logIn :"Log In",
            LogOut :'Log Out',
            connection :'Conexion'

     
          },
          required :{
              email : 'Please enter your email',
              password :'Please enter your password'
          },
          invalid : {
              email : "Your email isn't a valid email",
              password :"Your password isn't valid"
          }
    },
    apiResponses: {
      incorrect_username_or_password: {
        fr: 'Email (ou numéro de téléphone) mot pass est incorrect',
        eng: 'Incorrect email (or phone number)or password',
      },
      email_not_verified: {
        fr: 'Vous devez verifier votre email pour pouvoir vos connectez.',
        eng: 'You must verify your email to access your account',
      },
    },
  };
  