import React, { Fragment, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Grid,
  Container,
} from "@material-ui/core";
import { Facebook, FaceOutlined, LinkedIn, Twitter } from "@material-ui/icons";

import BlogCard from "../components/common/blogCard";
import Navbar from "../components/common/Navbar";

import i1 from "../assets/tiles/camera.jpg";
import i2 from "../assets/tiles/hats.jpg";
import i3 from "../assets/tiles/honey.jpg";
import i4 from "../assets/tiles/morning.jpg";
import i5 from "../assets/tiles/star.jpg";
import { useLng } from "../hooks/lng";
import {getContent} from "../content/getContent";
import Footer from "../components/Footer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root:{
      display: "block",
      alignItems: "flex-end",
      position: "relative",
      top: 0,
      zIndex: 4,
      height: "300px",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(86,101,174,1) 0%, rgba(77,154,210,1) 53%, rgba(69,199,240,1) 100%)",
  
    },
    gridContainer: {
      alignItems: "flex-end",
      height: "70%",
    },
    h_100: {
      height: "100%",
    },
  
    section: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
      overflowX: "hidden",
    },
    heading: {
      position: "relative",
      display: "inline-block",
      margin: "auto",
      zIndex: 2,
    },

    image: {
      height: "100%",
      width: "100%",
    },
  })
);

export default function Posts() {
  const { lng } = useLng();
  const classes = useStyles();
  const hero = getContent("posts", "hero", lng);
  const tileData = [
    { title: "one", img: i1 },
    { title: "two", img: i2 },
    { title: "three", img: i3 },
    { title: "four", img: i4 },
    { title: "five", img: i5 },
  ];

  return (
    <Fragment>
      <section className={classes.root}>
        <Navbar />
        <Container className={classes.h_100}>
          <Grid container className={classes.gridContainer}>
            <Grid item md={7}>
              <Typography variant="h2" component="h1" gutterBottom>
              {hero.title}
              </Typography>
              <Typography variant="subtitle1" component="p">
              {hero.text}
              </Typography>
            </Grid>
            <Grid item md={5}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  height: "100%",
                }}
              >
                <Facebook style={{ margin: 5 }} />
                <Twitter style={{ margin: 5 }} />
                <LinkedIn style={{ margin: 5 }} />
              </div>
            </Grid>
          </Grid>
        </Container>
      </section>
    <section className={classes.section}>
      <Container>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center" spacing={6}>
              {tileData.map((tile) => (
                <Grid item key={tile.img} md={4}>
                  <BlogCard title={tile.title} img={tile.img} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </section>
    <Footer />
   </Fragment>
  );
}
