import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useLng } from "../../hooks/lng";
import { getContent } from "../../content/getContent";

export default function AlertDialogSlide() {
  const [open, setOpen] = React.useState(true);
  const { lng } = useLng();
  const modal = getContent("home_", "modal", lng);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    localStorage.setItem("closed", "ok");
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        style={{ textAlign: lng === "ar" ? "right" : "left" }}
        open={open}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{modal.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {modal.text}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {modal.close}
          </Button>
          <Button
            target="_blank"
            onClick={handleClose}
            href="https://play.google.com/store/apps/details?id=com.tutordz.dev"
            color="primary"
          >
            {modal.cta}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
