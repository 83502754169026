import React, { useCallback, useEffect, useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Formik } from "formik";
import * as Yup from "yup";
import { useLng } from "../hooks/lng";
import { getContent, getErrorForField } from "../content/getContent";
import axios from "axios";
import { useUser } from "../hooks/user";
import background from "../assets/img/PhoneWelcomeClean.png";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    background:
      "linear-gradient(90deg, rgba(86,101,174,1) 0%, rgba(77,154,210,1) 53%, rgba(69,199,240,1) 100%)",
  },
  image: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));
const loginContent = "logIn";

export default function LogIn() {
  const classes = useStyles();
  const { lng } = useLng();
  const { loginUser } = useUser();
  const history = useHistory();
  const location = {
    pathname: "/Dashboard",
    state: { fromDashboard: true },
  };
  const { REACT_APP_API_URL } = process.env;
  const titles = getContent("logIn", "label", lng);
  const required = getContent("logIn", "required", lng);
  const invalid = getContent("logIn", "invalid", lng);
  const [apiErrors, setApiErrors] = useState([]);

  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string().email(invalid.email).required(required.email),

    password: Yup.string().required(required.password),
  });

  const handleSubmit = useCallback(async (values) => {
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/ouath/login`,
        {
          ...values,
        },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      if (response.data) {
        const { access_token, user } = response.data;
        await loginUser(user, access_token);
        history.push(location);
      }
    } catch (e) {
      const response = e?.response?.data?.errors || [];
      const messages = response.map((el) => {
        return getErrorForField(loginContent, el.field, el.code, lng);
      });
      setApiErrors(messages);
    }
  }, []);
  const renderErrors = useCallback(() => {
    return apiErrors.map((msg, index) => {
      return (
        <Typography key={index} color="error" variant="h6">
          {msg}
        </Typography>
      );
    });
  }, [apiErrors]);
  return (
    <Grid container component="main" className={classes.root}>
      <div>{renderErrors()}</div>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {titles.logIn}
          </Typography>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({
              values,
              setFieldValue,
              submitCount,
              errors,
              handleSubmit,
              isSubmitting,
              isValid,
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  error={errors?.email ? true : false}
                  label={titles.email}
                  name={titles.email}
                  value={values.email}
                  onChange={(event) =>
                    setFieldValue("email", event.target.value, !!submitCount)
                  }
                  helperText={errors.email}
                  autoComplete="email"
                  autoFocus
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name={titles.password}
                  label={titles.password}
                  onChange={(event) =>
                    setFieldValue("password", event.target.value, !!submitCount)
                  }
                  error={errors?.password ? true : false}
                  value={values.password}
                  helperText={errors.password}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />

                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting || !isValid}
                  className={classes.submit}
                >
                  {titles.connection}
                </Button>
              </form>
            )}
          </Formik>
        </div>
      </Grid>
    </Grid>
  );
}
