import React, { Fragment, useState } from "react";
import {
  Container,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import HeroImg from "../assets/img/WelcomeToTutor.png";
import Gpb from "../assets/img/gpb.png";

import Aboutimg from "../assets/img/about.png";
import How from "../components/How";
import Stats from "../components/Stats";

import Footer from "../components/Footer";
import Features from "../components/Features";
import { getContent } from "../content/getContent";
import { useLng } from "../hooks/lng";
import Navbar from "../components/common/Navbar";
import { CheckCircleOutline } from "@material-ui/icons";

const styles = makeStyles((theme: Theme) => ({
  root: {
    display: "block",
    alignItems: "center",
    position: "relative",
    top: 0,
    zIndex: 4,
    height: "100vh",
    color: "white",
    background:
      "linear-gradient(90deg, rgba(86,101,174,1) 0%, rgba(77,154,210,1) 53%, rgba(69,199,240,1) 100%)",
  },
  gridContainer: {
    alignItems: "center",
    height: "100%",
    whiteSpace: "pre-wrap",
    "& h1": {
      [theme.breakpoints.down("sm")]: {
        fontSize: 50,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 38,
      },
    },
  },
  h_100: {
    height: "100%",
  },
  heroImg: { height: "450px" },
  aboutImg: { maxWidth: "100%" },
  cta: {
    height: 55,
    marginRight: 10,
  },
  ctaContainer: {
    display: "flex",
    marginTop: 15,
    flexWrap: "wrap",
  },
  section: {
    paddingTop: "3rem",
    paddingBottom: "3rem",
    overflowX: "hidden",
  },
  heading: {
    position: "relative",
    display: "inline-block",
    margin: "auto",
    marginBottom: "3rem",
    zIndex: 2,
  },
}));

export default function Home() {
  const { lng } = useLng();
  const classes = styles();
  const hero = getContent("home_", "hero", lng);
  const about = getContent("home_", "about", lng);
  return (
    <Fragment>
      <section id="home" className={classes.root}>
        <Navbar />
        <Container className={classes.h_100}>
          <Grid container className={classes.gridContainer}>
            <Grid item md={7}>
              <Typography variant="h2" component="h1" gutterBottom>
                {hero.slogan}
              </Typography>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 15,
                  }}
                >
                  <CheckCircleOutline />
                  <Typography variant="h5" component="h2" style={{ margin: 5 }}>
                    {hero.courses}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 15,
                  }}
                >
                  <CheckCircleOutline />
                  <Typography variant="h5" component="h2" style={{ margin: 5 }}>
                    {hero.control}
                  </Typography>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 15,
                  }}
                >
                  <CheckCircleOutline />
                  <Typography variant="h5" component="h2" style={{ margin: 5 }}>
                    {hero.follow}
                  </Typography>
                </div>
              </div>
              <Typography variant="h5" component="h2" style={{ marginTop: 15 }}>
                {hero.soon} :
              </Typography>
              <div className={classes.ctaContainer}>
                <div>
                  <Typography>{/* {navigator.userAgent} */}</Typography>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.tutordz.dev"
                  >
                    <img className={classes.cta} src={Gpb} />
                  </a>
                </div>
              </div>
            </Grid>
            <Hidden smDown>
              <Grid
                item
                md={5}
                style={{
                  textAlign: "center",
                  alignItems: "center",
                }}
              >
                <img className={classes.heroImg} src={HeroImg} />
              </Grid>
            </Hidden>
          </Grid>
        </Container>
      </section>
      <section
        id="about"
        className={classes.section}
        style={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <Container style={{ whiteSpace: "pre-wrap" }}>
          <Grid container alignItems="center">
            <Grid item md={6}>
              <img src={Aboutimg} className={classes.aboutImg} />
            </Grid>
            <Grid item md={6}>
              <Typography
                align="center"
                className={classes.heading}
                variant="h4"
                component="h2"
                gutterBottom
              >
                {about.title}
              </Typography>
              <Typography variant="body1" component="p">
                {about.text}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>
      <section id="how">
        <How />
      </section>
      <section id="feats">
        <Features />
      </section>

      <Stats />
      <Footer />
    </Fragment>
  );
}
