export  default { 
  fr: {
    hero: {
      title: "Nos articles de blog",
      text: "Jetez un coup d'œil aux dernières mises à jour et nouvelles sur notre application, notre système éducatif et l'état actuel du bitcoin, et comment vous devriez investir dans la cryptographie à moins que vous ne souhaitiez être déprimé à l'avenir en sachant à quel point vous avez manqué"
    },
    
  },
  en: {
      hero: {
        title: "Our blog posts",
        text: "Get a glance at the latest updates and news about our app, our education system and the current state of bitcoin, and how you should invest in crypto unless you want to be depressed in the future knowing how much you missed out"
      },
    },
  ar: {
    hero: {
      title: "منشورات مدونتنا",
      text: "ألقِ نظرة على آخر التحديثات والأخبار حول تطبيقنا ونظامنا التعليمي والوضع الحالي لعملة البيتكوين ، وكيف يجب أن تستثمر في العملات المشفرة إلا إذا كنت تريد أن تصاب بالاكتئاب في المستقبل مع معرفة مقدار ما فاتك"
      },
  },
  apiResponses: {
    incorrect_username_or_password: {
      fr: 'Email (ou numéro de téléphone) mot pass est incorrect',
      eng: 'Incorrect email (or phone number)or password',
    },
    email_not_verified: {
      fr: 'Vous devez verifier votre email pour pouvoir vos connectez.',
      eng: 'You must verify your email to access your account',
    },
  },
}