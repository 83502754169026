import React, { useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useUser } from "../hooks/user";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { user, userLoading } = useUser();

  if (userLoading) return <h2>Loading</h2>;
  if (!userLoading)
    return (
      <Route
        {...rest}
        render={(props) =>
          !user === null ? <Redirect to="/logIn" /> : <Component {...props} />
        }
      />
    );
}
