import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { LngProvider } from "./context/lngContext";
import Home from "./views/Home";
import { useLng } from "./hooks/lng";
import { UserProvider } from "./context/UserContext";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import Post from "./views/Post";
import Posts from "./views/Posts";
import Modal from "./components/common/Modal";
import Dashboard from "./components/AdminPanel/Dashboard";
import LogIn from "./components/LogIn";
import PrivateRoute from "./components/PrivateRoute";

import Conditions from "./views/Conditions";
// A custom theme for this app
export default function App() {
  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  const { lng } = useLng();
  const [showModal, setShowModal] = useState(false);
  const theme = createMuiTheme({
    direction: lng === "ar" ? "rtl" : "ltr",
    palette: {
      primary: {
        main: "#556cd6",
      },
      secondary: {
        main: "#19857b",
      },
      error: {
        main: red.A400,
      },
      background: {
        default: "#fff",
      },
    },
    typography: {
      fontFamily:
        lng === "ar"
          ? ["Cairo", "sans serif"].join(",")
          : ["Poppins", "sans serif"].join(","),
      body1: {
        fontSize: "1.3rem",
      },

      subtitle1: {
        fontSize: "1rem",
      },
    },
  });

  return (
    <>
      <div dir={lng === "ar" ? "rtl" : "ltr"}>
        <UserProvider>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />

            {showModal && <Modal />}
            <StylesProvider jss={jss}>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/post/:id">
                  <Post />
                </Route>
                <Route path="/posts">
                  <Posts />
                </Route>
                <Route path="/logIn">
                  <LogIn />
                </Route>
                <Route path="/terms-and-conditions">
                  <Conditions />
                </Route>
                <PrivateRoute path="/Dashboard" component={Dashboard} />
              </Switch>
            </StylesProvider>
          </ThemeProvider>
        </UserProvider>
      </div>
    </>
  );
}
