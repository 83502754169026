import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import AccountCircle from "@material-ui/icons/AccountCircle";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { useUser } from "../../hooks/user";
import { useLng } from "../../hooks/lng";
import { getContent } from "../../content/getContent";
import { Link } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ProfilMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { lng } = useLng();
  const { user, logoutHandler } = useUser();

  const titles = getContent("logIn", "label", lng);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleExit = () => {
    logoutHandler();
    handleClose();
  };

  return (
    user && (
      <>
        <IconButton onClick={handleMenu} color="inherit">
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <>
            <MenuItem onClick={handleClose}>
              <Link
                to="/Dashboard"
                component={RouterLink}
                color="inherit"
                style={{ textDecoration: "none" }}
              >
                DashBoard
              </Link>
            </MenuItem>
            <MenuItem onClick={() => handleExit()}>{titles.LogOut}</MenuItem>
          </>
        </Menu>
      </>
    )
  );
}
