import {
  createStyles,
  makeStyles,
  Theme,
  Container,
  Grid,
  Hidden,
  Typography,
  Button,
} from "@material-ui/core";
import { Link as ScrollLink } from "react-scroll";
import { Link as RouterLink } from "react-router-dom";
import gplay from "../assets/badges/google_play/en.png";
import appstore from "../assets/badges/app_store/black/en.svg";
import logo from "../assets/img/logo_footer.svg";
import { useLng } from "../hooks/lng";
import { getContent } from "../content/getContent";
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    footer: {
      bottom: 0,

      paddingTop: "3rem",
      paddingBottom: "3rem",
      backgroundColor: "#0B0D17",
      color: "white",
      "& a": {
        color: "inherit",
        textDecoration: "none",
      },
    },
    title: {
      textAlign: "left",
      fontSize: "48px",
      fontStyle: "normal",
      fontWeight: 700,
      lineHeight: "56px",
      letterSpacing: "0em",
    },
    links: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "26px",
      letterSpacing: "0em",
      textAlign: "left",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      alignItems: "Center",
      padding: 0,
      position: "static",
      color: "#FFFFFF",
      "& a": {
        padding: "0% 5% 0% 0%",
        color: "inherit",
        textDecoration: "none",
      },
    },
    copyright: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "24px",
      letterSpacing: "0em",

      color: "#D9DBE1",
    },
    badges: {
      display: "flex",
      flexWrap: "wrap",
    },
    menuButton: {
      size: "18px",
      marginLeft: "0px",
      textTransform: "capitalize",
      color: "white",
    },
  })
);

export default function Footer() {
  const classes = useStyles();
  const { lng } = useLng();
  const buttons = getContent("translation", "buttons", lng);

  const headersData = [
    {
      label: buttons.about,
      id: "about",
    },
    {
      label: buttons.howItWorks,
      id: "how",
    },
    {
      label: buttons.feats,
      id: "feats",
    },
    {
      label: buttons.stats,
      id: "stats",
    },
    {
      label: buttons.conditions,
      id: "conditions",
      externalLink: "/terms-and-conditions",
    },
  ];

  return (
    <section className={classes.footer}>
      <Container>
        <div>
          <Hidden mdUp>
            <h3>{buttons.availableAt}</h3>

            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.tutordz.dev"
            >
              <img src={gplay} style={{ height: 55, marginRight: 10 }}></img>
            </a>
          </Hidden>
        </div>
        <Grid container>
          <Grid item md={3} xs={12}>
            <div className={classes.title}>
              <img src={logo} />
            </div>
          </Grid>
          <Grid item md={6} xs={12}>
            {headersData.map(({ label, id, externalLink }) => {
              return (
                <Button
                  {...{
                    key: label,
                    color: "inherit",
                    className: classes.menuButton,
                  }}
                >
                  {!externalLink ? (
                    <ScrollLink
                      {...{
                        activeClass: label === "home" ? "active" : null,
                        to: id,
                        color: "inherit",
                        style: { textDecoration: "none" },
                        spy: true,
                        smooth: true,
                      }}
                    >
                      {label}
                    </ScrollLink>
                  ) : (
                    <RouterLink to={externalLink}>{label}</RouterLink>
                  )}
                </Button>
              );
            })}
          </Grid>

          <Grid item md={3} xs={12}>
            <Hidden smDown>
              <Typography variant="subtitle1" gutterBottom>
                {buttons.availableAt}
              </Typography>
              <Grid container spacing={2} className={classes.badges}>
                <Grid item>
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.tutordz.dev"
                  >
                    <img src={gplay} style={{ height: 35 }}></img>
                  </a>
                </Grid>
              </Grid>
            </Hidden>
            <Hidden mdUp>
              <p className={classes.copyright}>{buttons.copyright}</p>
            </Hidden>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
}
