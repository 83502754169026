import React, { Fragment, useState } from "react";
import {
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Box,
  Grid,
  Container,
} from "@material-ui/core";

import bg from "../assets/img/statBG.svg";

import { getContent } from "../content/getContent";
import { useLng } from "../hooks/lng";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    heading: {
      position: "relative",
      display: "inline-block",
      margin: "auto",
      marginBottom: "3rem",
      zIndex: 2,
    },
    section: {
      paddingTop: "3rem",
      paddingBottom: "3rem",
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center center",
      overflowX: "hidden",
      //   position: "sticky",
      //   bottom:0,
      //   zIndex: -1,
      color: "#FFFFFF",
    },

    image: {
      height: "100%",
      width: "100%",
    },
  })
);

export default function Stats() {
  const classes = useStyles();
  const { lng } = useLng();
  const stats = getContent("translation", "stats", lng);

  const tileData = [
    { title: stats.tutors, number: 31 },
    { title: stats.wilayas, number: 6 },
    { title: stats.offers, number: 0 },
    { title: stats.subjects, number: 40 },
  ];

  return (
    <Fragment>
      <section className={classes.section} id="stats">
        <Container>
          <Grid
            container
            justify="center"
            alignItems="center"
            direction="column"
          >
            <Grid item xs={12}>
              <Box style={{ textAlign: "center", width: "100%" }}>
                <Typography
                  align="center"
                  variant="h4"
                  component="h2"
                  style={{ fontWeight: 800 }}
                  gutterBottom
                >
                  {stats.title}
                </Typography>
              </Box>
              <Box style={{ textAlign: "center", width: "100%" }}>
                <Typography
                  align="center"
                  variant="subtitle2"
                  component="h2"
                  gutterBottom
                >
                  {stats.text}
                </Typography>
              </Box>
            </Grid>
            <Grid
              container
              justify="center"
              alignItems="center"
              spacing={6}
              style={{ marginTop: "3rem" }}
            >
              {tileData.map((numbers, index) => (
                <Grid item key={index} xs={5} sm={4} md={2}>
                  <Typography
                    align="center"
                    variant="h5"
                    component="h4"
                    gutterBottom
                  >
                    <Box>
                      {numbers.title}
                      <br />
                      <Box style={{ opacity: 0.5, padding: "0% 25%" }}>
                        <hr />
                      </Box>
                      {numbers.number}
                    </Box>
                  </Typography>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </section>
    </Fragment>
  );
}
