import React, { useEffect, useCallback } from "react";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import UsersList from "./UsersList";

import { useUser } from "../../hooks/user";
import axios from "axios";
import { getCommunForWilaya, wilaya } from "../../content/states";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

interface Iwilaya {
  id: string;
  code: string;
  nom: string;
}
interface ICommune {
  nom: string;
  wilaya: string;
  wilaya_id: string;
  id: string;
}
const LIMIT = 10;
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function UsersTab(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index: number) => {
    setValue(index);
  };

  const [activeUsers, setActiveUsers] = React.useState([]);
  const [activeUsersPageCount, setActiveUsersPageCount] = React.useState([]);
  const [pendingUsers, setPendingUsers] = React.useState([]);
  const [pendingUsersPageCount, setPendingUsersPageCount] = React.useState([]);
  const [waitingUsers, setWaitingUsers] = React.useState([]);
  const [waitingUsersPageCount, setWaitingUsersPageCount] = React.useState([]);
  const [disabledUsers, setDisabledUsers] = React.useState([]);
  const [disabledUsersCount, setDisabledUsersCount] = React.useState([]);

  // Filters
  const [roles, setRoles] = React.useState({
    parents: true,
    teachers: true,
    onlyPro: false,
  });

  const [sex, setSex] = React.useState("");
  const [wilayas, setWilayas] = React.useState<Iwilaya[]>([]);
  const [communs, setCommuns] = React.useState<ICommune[]>([]);
  const [selectedWilaya, setSelectedWilaya] = React.useState("");
  const [selectedCommun, setSelectedCommun] = React.useState("");

  //  ...query.searchTerm,
  // subject_key:
  //   query?.phase && query?.year && query?.subject
  //     ? generateKey(query.phase, query.year, query.subject)
  //     : null,
  // is_professional: query.is_professional,
  // group_price: query?.group_price,
  // group_price_subscription: query?.group_price_subscription,
  // online_price: query?.online_price,
  // online_price_subscription: query?.online_price_subscription,
  // individual_price: query?.individual_price,
  // individual_price_subscription: query?.individual_price_subscription,
  // role: query?.role,
  // sex: query?.sex,

  // More filters

  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");

  const [isLoading, setIsLoading] = React.useState(true);
  const { user } = useUser();
  const { REACT_APP_API_URL } = process.env;

  const accessToken = user?.accessToken;

  const fetchPendingUsers = useCallback(
    async (page = 1) => {
      try {
        if (accessToken) {
          const responsePending = await axios.get(
            `${REACT_APP_API_URL}/users`,
            {
              params: {
                active: false,
                waitlist: false,
                include_active_status_changed: false,
                page,
                limit: LIMIT,
                phone_number: phone,
                email: email,
                name: name,
                role:
                  roles.teachers && !roles.parents
                    ? "teacher"
                    : roles.parents && !roles.teachers
                    ? "parent"
                    : "",
                ...(roles.onlyPro ? { is_professional: roles.onlyPro } : {}),

                sex: sex,
                state: selectedWilaya,
                city: selectedCommun,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (responsePending.data) {
            setPendingUsers(responsePending.data.records);
            setPendingUsersPageCount(responsePending.data.total_pages);
          }
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    [
      accessToken,
      REACT_APP_API_URL,
      roles,
      sex,
      name,
      email,
      phone,
      selectedWilaya,
      selectedCommun,
    ]
  );
  const fetchActiveUsers = useCallback(
    async (page = 1) => {
      try {
        if (accessToken) {
          const responseActive = await axios.get(`${REACT_APP_API_URL}/users`, {
            params: {
              active: true,
              page,
              limit: LIMIT,
              phone_number: phone,
              email: email,
              name: name,

              role:
                roles.teachers && !roles.parents
                  ? "teacher"
                  : roles.parents && !roles.teachers
                  ? "parent"
                  : "",
              ...(roles.onlyPro ? { is_professional: roles.onlyPro } : {}),
              sex: sex,
              state: selectedWilaya,
              city: selectedCommun,
            },
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          if (responseActive.data) {
            setActiveUsers(responseActive.data.records);
            setActiveUsersPageCount(responseActive.data.total_pages);
          }
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    [
      accessToken,
      REACT_APP_API_URL,
      roles,
      sex,
      name,
      email,
      phone,
      selectedWilaya,
      selectedCommun,
    ]
  );
  const fetchWaitingUsers = useCallback(
    async (page = 1) => {
      try {
        if (accessToken) {
          const responsePending = await axios.get(
            `${REACT_APP_API_URL}/users`,
            {
              params: {
                waitlist: true,

                page,
                limit: LIMIT,
                phone_number: phone,
                email: email,
                name: name,

                role:
                  roles.teachers && !roles.parents
                    ? "teacher"
                    : roles.parents && !roles.teachers
                    ? "parent"
                    : "",
                ...(roles.onlyPro ? { is_professional: roles.onlyPro } : {}),
                sex: sex,
                state: selectedWilaya,
                city: selectedCommun,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (responsePending.data) {
            setWaitingUsers(responsePending.data.records);
            setWaitingUsersPageCount(responsePending.data.total_pages);
          }
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    [
      accessToken,
      REACT_APP_API_URL,
      roles,
      sex,
      name,
      email,
      phone,
      selectedWilaya,
      selectedCommun,
    ]
  );

  const fetchDisabledUsers = useCallback(
    async (page = 1) => {
      try {
        if (accessToken) {
          const responsePending = await axios.get(
            `${REACT_APP_API_URL}/users`,
            {
              params: {
                active: false,
                include_active_status_changed: true,
                page,
                limit: LIMIT,
                phone_number: phone,
                email: email,
                name: name,

                role:
                  roles.teachers && !roles.parents
                    ? "teacher"
                    : roles.parents && !roles.teachers
                    ? "parent"
                    : "",
                ...(roles.onlyPro ? { is_professional: roles.onlyPro } : {}),
                sex: sex,
                state: selectedWilaya,
                city: selectedCommun,
              },
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );

          if (responsePending.data) {
            setDisabledUsers(responsePending.data.records);
            setDisabledUsersCount(responsePending.data.total_pages);
          }
        }
      } catch (e) {
        console.error("error", e);
      }
    },
    [
      accessToken,
      REACT_APP_API_URL,
      roles,
      sex,
      name,
      email,
      phone,
      selectedWilaya,
      selectedCommun,
    ]
  );

  const fetchAllData = useCallback(() => {
    fetchActiveUsers();
    fetchPendingUsers();
    fetchWaitingUsers();
    fetchDisabledUsers();
    setIsLoading(false);
  }, [
    fetchActiveUsers,
    fetchPendingUsers,
    fetchWaitingUsers,
    fetchDisabledUsers,
    setIsLoading,
  ]);

  useEffect(() => {
    fetchActiveUsers();
    fetchPendingUsers();
    fetchWaitingUsers();
    fetchDisabledUsers();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    setSelectedCommun("");
    const updatedCommuns = getCommunForWilaya(selectedWilaya);
    setCommuns(updatedCommuns);
  }, [selectedWilaya]);

  useEffect(() => {
    setWilayas(wilaya);
  }, []);

  const updateUser = async (id, values, endpoint) => {
    try {
      if (accessToken) {
        const response = await axios.patch(
          `${REACT_APP_API_URL}/users/${id}/${endpoint}`,
          values,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.data) {
          fetchAllData();
        }
      }
    } catch (e) {
      console.error("error", e);
    }
  };

  const handleRoleChange = (event) => {
    setRoles({ ...roles, [event.target.name]: event.target.checked });
  };
  useEffect(() => {
    if (roles.onlyPro) {
      setRoles({ ...roles, parents: false, teachers: true });
    }
  }, [roles.onlyPro, roles.parents, roles.teachers]);
  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Active" {...a11yProps(1)} />
          <Tab label="Blocked" {...a11yProps(2)} />
          <Tab label="Waiting" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <Box m="1rem" display="flex">
        <Box m=".5rem" bgcolor="#EFEFEF" p="2rem">
          <Typography variant="subtitle1">Filter by role</Typography>
          <FormControl component="fieldset">
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={roles.teachers}
                    onChange={handleRoleChange}
                    name="teachers"
                  />
                }
                label="teachers"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={roles.parents}
                    onChange={handleRoleChange}
                    name="parents"
                  />
                }
                label="Parents"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={roles.onlyPro}
                    onChange={handleRoleChange}
                    name="onlyPro"
                  />
                }
                label="Only pro teachers"
              />
            </FormGroup>
          </FormControl>
        </Box>

        <Box m=".5rem" bgcolor="#EFEFEF" p="2rem">
          <Typography variant="subtitle1">Filter by gender</Typography>

          <FormControl style={{ width: "100%" }}>
            <InputLabel id="sex-label">Sex</InputLabel>
            <Select
              labelId="sex-label"
              value={sex}
              onChange={(event) => {
                setSex(event.target.value as string);
              }}
            >
              <MenuItem value={"M"}>Men</MenuItem>
              <MenuItem value={"F"}>Women</MenuItem>
              <MenuItem value={""}>Both</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box flex="1" m=".5rem" bgcolor="#EFEFEF" p="2rem">
          <Typography variant="subtitle1">Filter by location</Typography>

          <FormControl style={{ width: "100%", marginBottom: 20 }}>
            <InputLabel id="state-label">State</InputLabel>
            <Select
              labelId="state-label"
              value={selectedWilaya}
              onChange={(event) => {
                setSelectedWilaya(event.target.value as string);
              }}
            >
              <MenuItem value={""}>- Select wilaya -</MenuItem>

              {wilayas.map((wil) => (
                <MenuItem value={wil.nom}>{wil.nom}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl style={{ width: "100%" }}>
            <InputLabel id="city-label">City</InputLabel>
            <Select
              labelId="city-label"
              value={selectedCommun}
              onChange={(event) => {
                setSelectedCommun(event.target.value as string);
              }}
            >
              <MenuItem value={""}>- Select commune -</MenuItem>

              {communs.map((wil) => (
                <MenuItem value={wil.nom}>{wil.nom}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box flexGrow="1" m=".5rem" bgcolor="#EFEFEF" p="2rem">
        <Typography variant="subtitle1">More filters</Typography>
        <TextField
          onChange={(event) => setName(event.target.value)}
          style={{ margin: 7 }}
          label="Name"
          id="standard-size-small"
          size="small"
        />

        <TextField
          onChange={(event) => setEmail(event.target.value)}
          style={{ margin: 7 }}
          label="Email"
          id="standard-size-small"
          size="small"
        />

        <TextField
          onChange={(event) => setPhone(event.target.value)}
          style={{ margin: 7 }}
          label="Phone"
          id="standard-size-small"
          size="small"
        />
      </Box>

      <Button
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginBottom: 20, marginLeft: 10 }}
        onClick={fetchAllData}
      >
        Filter
      </Button>

      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <UsersList
            fetchUsers={fetchPendingUsers}
            users={pendingUsers}
            usersPageCount={pendingUsersPageCount}
            index={0}
          />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <UsersList
            fetchUsers={fetchActiveUsers}
            users={activeUsers}
            usersPageCount={activeUsersPageCount}
            index={1}
          />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          <UsersList
            fetchUsers={fetchDisabledUsers}
            users={disabledUsers}
            usersPageCount={disabledUsersCount}
            index={2}
          />
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          <UsersList
            fetchUsers={fetchWaitingUsers}
            users={waitingUsers}
            usersPageCount={waitingUsersPageCount}
            index={3}
          />
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
