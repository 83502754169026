import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {useLng} from "../../hooks/lng";
import {getContent} from "../../content/getContent";

const useStyles = makeStyles({
  media: {
    height: 140,
  },
});

export default function MediaCard({ img, title, showSummary = true }) {
  const classes = useStyles();
  const { lng } = useLng();
  const buttons = getContent("translation", "buttons", lng);
  return (
    <Card>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={img}
          title="Contemplative Reptile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          {showSummary && (
            <Typography variant="body2" color="textSecondary" component="p">
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary">
          {buttons.share}
        </Button>
        <Button size="small" color="primary">
          {buttons.learnMore}
        </Button>
      </CardActions>
    </Card>
  );
}
